import { makeArray, randAlphaString } from '@utils/faker-utils';

export class HardwareModelMockFactory {
  static createHardwareModel(): string {
    return randAlphaString();
  }

  static createHardwareModels(numberOfEntries = 3): string[] {
    return makeArray(numberOfEntries, () => this.createHardwareModel());
  }
}
