import useSWR from 'swr';

import { fetcher as originalFetcher } from '@utils/swr-utils';

import { OrganizationDetails } from '@omnis-pulse-types';

import { USER_ORGANIZATION_DETAILS_PATH } from '../paths';

// Create a new fetcher that parses the createdAt attribute
const fetcher = async (url: string): Promise<OrganizationDetails> => {
  const response = await originalFetcher(url);

  // Parse the createdAt attribute to a Date instance
  if (response.createdAt) {
    response.createdAt = new Date(response.createdAt);
  }

  return response;
};

export default function useUserOrganizationDetails() {
  const { data, error, isLoading } = useSWR<OrganizationDetails>(
    USER_ORGANIZATION_DETAILS_PATH,
    fetcher
  );

  return { organizationDetails: data, isLoading, isError: Boolean(error) };
}
