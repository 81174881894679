import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { randNumber, randTextRange, randUuid } from '@ngneat/falso';

import { useUploadContext } from '@context/hmi/UploadContext';

import { Card, SearchInput, Typography } from '@destination/components';

import { HMICustomizationApplyToTable } from './HMICustomizationApplyToTable';

const models = [
  randTextRange({ min: 1, max: 100 }),
  randTextRange({ min: 1, max: 100 }),
  randTextRange({ min: 1, max: 100 }),
  randTextRange({ min: 1, max: 100 }),
  randTextRange({ min: 1, max: 100 })
];
const chargers = Array.from({ length: randNumber({ min: 10, max: 2500 }) }).map(
  () => ({
    id: randUuid(),
    name: `Charger ${randNumber()}`,
    serialNumber: randTextRange({ min: 1, max: 100 }),
    model: models[randNumber({ min: 0, max: models.length - 1 })]
  })
);

export const HMICustomizationApplyTo = () => {
  const { t } = usePortalTranslation('hmi');
  const { file, selected } = useUploadContext();

  const [search, setSearch] = useState('');

  const title = t('labels.apply_to_chargers');
  const searchPlaceholder = t(
    'labels.search_for_a_charger_name_or_serial_number'
  );

  return (
    <Card className="max-h-[626px]">
      <div className="my-2">
        <Typography variant="smallTitle" className={file ? 'mb-4' : 'mb-2'}>
          {title}
        </Typography>
        {file ? (
          <div>
            <div className="mb-4 max-w-[365px]">
              <SearchInput
                placeholder={searchPlaceholder}
                value={search}
                onChange={event => setSearch(event.target.value)}
              />
            </div>
            <Typography className="mx-3 mb-3">
              {t('labels.chargers_amount_selected', { count: selected.size })}
            </Typography>
            <div className="max-h-[400px] overflow-y-auto">
              <HMICustomizationApplyToTable chargers={chargers} />
            </div>
          </div>
        ) : (
          <Typography>
            {t('labels.add_a_file_to_select_the_chargers')}
          </Typography>
        )}
      </div>
    </Card>
  );
};
