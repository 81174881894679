import { useDateFilter } from '@hooks/use-date-filter';
import { useParams } from 'react-router-dom';

import useSiteEventLog from '@api/sites/use-site-event-log';
import { DateTimeFilterButtons } from '@components/buttons/DateTimeFilterButtons';
import { InfiniteScrollTable } from '@components/InfiniteScrollTable';
import { LogsTable } from '@components/table/logs/LogsTable';

import { Card } from '@destination/components';

export const Logs = () => {
  const { id } = useParams() as { id: string };
  const { selectedFilter, fromTimestamp, toTimestamp, handleFilterChange } =
    useDateFilter();

  const params = new URLSearchParams();

  if (fromTimestamp) params.append('from', fromTimestamp.toString());
  if (toTimestamp) params.append('to', toTimestamp.toString());

  const {
    logs,
    isLoading,
    isError,
    setPageSize,
    isLoadingMore,
    hasReachedEnd
  } = useSiteEventLog(id, params);

  return (
    <>
      <DateTimeFilterButtons
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
      />
      <Card className="!shadow-none">
        <InfiniteScrollTable
          dataLength={logs?.length ?? 0}
          onNextPage={() => setPageSize(size => size + 1)}
          isLoadingMore={isLoadingMore}
          hasReachedEnd={hasReachedEnd}
        >
          <LogsTable
            logs={logs}
            isLoading={isLoading}
            isError={isError}
            displayChargerIdentification
          />
        </InfiniteScrollTable>
      </Card>
    </>
  );
};
