import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

import { Payload, Tick } from '@models/Charts';

interface NearestDataPointOptions {
  y: number;
  dataPoints: Payload[];
}

interface IYAxisTickContext {
  clearCollection: () => void;
  updateCollection: (tick: Tick) => void;
  getNearestDataPoint: (options: NearestDataPointOptions) => Payload[] | null;
}

const YAxisTickContext = createContext<IYAxisTickContext | null>(null);

interface IYAxisTickProvider {
  children: ReactNode;
}

export const YAxisTickProvider = ({ children }: IYAxisTickProvider) => {
  const [collection, setCollection] = useState<{
    min: Tick;
    max: Tick;
  } | null>(null);

  const clearCollection = useCallback(() => {
    setCollection(null);
  }, []);

  const updateCollection = useCallback(
    (tick: Tick) => {
      if (!collection) {
        setCollection({ min: tick, max: tick });
        return;
      }

      if (tick.value < collection.min.value) {
        setCollection({ ...collection, min: tick });
        return;
      }

      if (tick.value > collection.max.value) {
        setCollection({ ...collection, max: tick });
      }
    },
    [collection, setCollection]
  );

  const getNearestDataPoint = useCallback(
    ({ y, dataPoints }: NearestDataPointOptions) => {
      if (collection === null) {
        return null;
      }

      const { min, max } = collection;

      const slope = (min.value - max.value) / (min.y - max.y);
      const intercept = min.value - slope * min.y;
      const cursorPosValue = slope * y + intercept;

      // Calculate the y coordinate of each data point based on the scale of the left Y-axis
      dataPoints.map(dataPoint => {
        dataPoint.y = (dataPoint.value - intercept) / slope;
      });

      let nearest = [dataPoints[0]];

      dataPoints.forEach((dataPoint: Payload, index) => {
        let dataPointValue = dataPoint.value;

        if (dataPoint.dataKey === 'soc') {
          // Calculate the SoC data point value based on the scale of the left Y-axis
          dataPointValue = (max.value / 100) * dataPoint.value;
        }

        const elementDistance = Math.abs(dataPointValue - cursorPosValue);
        const nearestDistance = Math.abs(nearest[0].value - cursorPosValue);

        if (elementDistance < nearestDistance) {
          nearest = [dataPoint];
        } else if (index !== 0 && elementDistance === nearestDistance) {
          nearest.push(dataPoint);
        }
      });

      return nearest;
    },
    [collection]
  );

  const contextValue = useMemo(
    () => ({ clearCollection, updateCollection, getNearestDataPoint }),
    [clearCollection, updateCollection, getNearestDataPoint]
  );

  return (
    <YAxisTickContext.Provider value={contextValue}>
      {children}
    </YAxisTickContext.Provider>
  );
};

export const useYAxisTickContext = () => {
  const context = useContext(YAxisTickContext);

  if (!context) {
    throw new Error(
      'useYAxisTickContext must be used within a YAxisTickProvider'
    );
  }

  return context;
};
