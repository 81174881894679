import { usePortalTranslation } from '@hooks/use-portal-translation';

import DeleteIcon from '@icons/delete_16.svg';
import ErrorIcon from '@icons/error_16.svg';
import FolderIcon from '@icons/folder_24.svg';
import { formatBytes } from '@utils/file-utils';

import { IconButton, Typography } from '@destination/components';

export interface IHMICustomizationFileInputCurrentFile {
  name: string;
  size: number;
  isError: boolean;
  disabled: boolean;
  onDelete: () => void;
}

export const HMICustomizationFileInputCurrentFile = ({
  name,
  size,
  isError,
  disabled,
  onDelete
}: IHMICustomizationFileInputCurrentFile) => {
  const { t } = usePortalTranslation('hmi');
  return (
    <>
      <div className="mt-5 flex justify-between">
        <div className="flex items-center gap-2">
          <FolderIcon className={disabled ? 'text-[#BABABA]' : ''} />
          <Typography className={disabled ? 'text-[#BABABA]' : ''}>
            {name} ({formatBytes(size)})
          </Typography>
        </div>
        <IconButton
          data-testid="delete-icon"
          disabled={disabled}
          onClick={onDelete}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      {isError && (
        <div className="ml-8 flex gap-1 text-[#F03040]">
          <ErrorIcon />
          <Typography className="mt-[1px] font-bold" variant="smallText">
            {t('labels.error_invalid_json_file')}
          </Typography>
        </div>
      )}
    </>
  );
};
