import useSWR from 'swr';

import { CHARGERS_BY_GATEWAY_BY_ID_PATH } from '@api/paths';
import { IDataResponse } from '@models/Responses';

import { Charger } from '@omnis-pulse-types';

const useGatewayChargers = (gatewayId: string) => {
  const url = CHARGERS_BY_GATEWAY_BY_ID_PATH(gatewayId);

  const { data, error, isLoading, mutate } =
    useSWR<IDataResponse<Charger>>(url);

  return {
    chargers: data?.data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
};

export default useGatewayChargers;
