import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useSearchFilter } from '@hooks/use-search-filter';
import { useParams } from 'react-router-dom';

import useSiteChargers from '@api/sites/use-site-chargers';
import { ChargerOverviewRow } from '@components/charger/overview/ChargerOverviewRow';
import ChargerIcon from '@icons/charger_96.svg';

import {
  DataError,
  Grid,
  NothingConfigured,
  SearchInput,
  TableLoading,
  Typography
} from '@destination/components';

export const ChargersOverview = () => {
  const { id } = useParams() as { id: string };
  const { searchValue, handleSearch } = useSearchFilter();
  const { chargers, isLoading, isError, mutate } = useSiteChargers(
    id,
    searchValue.length
      ? new URLSearchParams({ search: searchValue })
      : undefined
  );

  const { t } = usePortalTranslation();

  const renderPage = (content: React.ReactNode) => (
    <div data-testid="chargers-overview">
      <Typography variant="sectionTitle">
        {t('labels.entity_overview', {
          entity: t('literals.charger_other')
        })}
      </Typography>
      <div className="mb-6 mt-4 w-full max-w-md">
        <SearchInput
          data-testid="search-chargers-input"
          placeholder={t('labels.search_placeholder', {
            entity: [
              t('labels.entity_name', { entity: t('literals.charger') }),
              t('literals.serial_number')
            ]
          })}
          value={searchValue}
          onChange={handleSearch}
        />
      </div>
      {content}
    </div>
  );

  if (chargers && chargers.length === 0 && searchValue.length !== 0) {
    return renderPage(
      <div className="mt-6">
        <NothingConfigured
          title={t('labels.search_no_found', {
            searchTerm: searchValue
          })}
          icon={<ChargerIcon />}
        />
      </div>
    );
  }

  return renderPage(
    <>
      <Grid className="grid-cols-[64px_minmax(215px,auto)_auto_minmax(auto,180px)_minmax(auto,68px)_minmax(110px,auto)_minmax(auto,60px)_minmax(auto,115px)_minmax(auto,115px)_72px] grid-rows-[1fr] gap-y-3">
        <Header />
        {isLoading === false && isError === false && chargers && (
          <>
            {chargers.map(charger => (
              <ChargerOverviewRow
                key={charger.id}
                charger={charger}
                highlighted={searchValue}
              />
            ))}
          </>
        )}
      </Grid>
      {isLoading && <TableLoading isDarker className="mt-3" />}
      {isLoading === false && isError && (
        <div className="mt-4">
          <DataError onRefresh={() => mutate(undefined, true)} />
        </div>
      )}
    </>
  );
};

const Header = () => {
  const { t, t_generic } = usePortalTranslation('chargers');

  return (
    <>
      <Grid.HeadCell>{''}</Grid.HeadCell>
      <Grid.HeadCell>
        {t_generic('labels.entity_name', {
          entity: t_generic('literals.charger')
        })}
      </Grid.HeadCell>
      <Grid.HeadCell>
        {t_generic('labels.entity_status', {
          entity: t_generic('literals.connection')
        })}
      </Grid.HeadCell>
      <Grid.HeadCell>{t_generic('literals.evse_other')}</Grid.HeadCell>
      <Grid.HeadCell>{t('literals.outlet_other')}</Grid.HeadCell>
      <Grid.HeadCell>{t_generic('literals.power')}</Grid.HeadCell>
      <Grid.HeadCell>{t('literals.soc')}</Grid.HeadCell>
      <Grid.HeadCell>
        {t_generic('labels.activity_start', {
          activity: t('literals.session')
        })}
      </Grid.HeadCell>
      <Grid.HeadCell>{t_generic('literals.duration')}</Grid.HeadCell>
      <Grid.HeadCell>{''}</Grid.HeadCell>
    </>
  );
};
