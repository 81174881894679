import { usePortalTranslation } from '@hooks/use-portal-translation';
import useSWR from 'swr';

import { DOCUMENTS_TEMPLATES_PATH } from '@api/paths';
import { fetcher } from '@utils/swr-utils';

import { useAlert } from '@destination/components';

import { HmiDocument } from '@omnis-pulse-types';

interface IUseDocumentsTemplates {
  documentType: HmiDocument['documentType'];
  isDownloadStarted: boolean;
}

export default function useDocumentsTemplates({
  documentType = 'InstanceGraph',
  isDownloadStarted
}: IUseDocumentsTemplates) {
  const { t } = usePortalTranslation();
  const { notify } = useAlert();

  const params = new URLSearchParams();
  params.append('type', documentType);

  const url = `${DOCUMENTS_TEMPLATES_PATH}?${params.toString()}`;
  const { data, error, isLoading, mutate } = useSWR(
    isDownloadStarted ? url : null,
    fetcher,
    {
      onError: () => {
        notify({
          variant: 'error',
          header: t('errors.failed_to_download', {
            entity: t('literals.template')
          })
        });
      }
    }
  );

  return {
    template: data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
