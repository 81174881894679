import { useContext } from 'react';

import { SiteLoadManagementContext } from '@context/SiteLoadManagementContext';

export type Context = ReturnType<typeof useSiteLoadManagementContext>;

export const useSiteLoadManagementContext = () => {
  const context = useContext(SiteLoadManagementContext);

  if (!context)
    throw new Error(
      'useSiteLoadManagementContext must be used within a SiteLoadManagementProvider'
    );

  return context;
};
