import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DetailLine } from '@components/DetailsLine';
import { getCountryName } from '@utils/utils';

import { Card, DataError, LoadingSkeleton } from '@destination/components';

import { OrganizationDetails } from '@omnis-pulse-types';

export interface IOrganizationDetailCard {
  organization?: OrganizationDetails;
  isLoading?: boolean;
  isError?: boolean;
  'data-testid'?: string;
}
export const OrganizationDetailCard = ({
  organization,
  isLoading,
  isError,
  'data-testid': dataTestId
}: IOrganizationDetailCard) => {
  const { t } = usePortalTranslation();

  const formatDate = (date: Date) => {
    const formatter = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    });

    return formatter.format(date).replaceAll('/', '.');
  };

  return (
    <Card className="max-w-[528px] p-4" data-testid={dataTestId}>
      {isLoading && <LoadingSkeleton height={86} />}

      {isError && (
        <div className="flex h-[118px] items-center">
          <DataError />
        </div>
      )}

      {!isLoading && !isError && organization && (
        <>
          {organization.countryIso && (
            <>
              <DetailLine
                label={t('literals.country', { titleize: true })}
                className="mb-4"
              >
                <span className="text-base font-bold">
                  {getCountryName('en', organization.countryIso)}
                </span>
              </DetailLine>
              <DetailLine
                label={t('literals.country_iso', { titleize: true })}
                className="mb-4"
              >
                <span className="text-base font-bold">
                  {organization.countryIso}
                </span>
              </DetailLine>
            </>
          )}
          <DetailLine label={t('literals.created_on', { titleize: true })}>
            <span className="text-base font-bold">
              {organization.createdAt
                ? formatDate(new Date(organization.createdAt))
                : ''}
            </span>
          </DetailLine>
        </>
      )}
    </Card>
  );
};
