import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@destination/components';

export interface ICreateSiteButtons {
  isCreating: boolean;
  disabledCreateButton: boolean;
  onCreateButtonClick: () => void;
}

export const CreateSiteButtons = ({
  isCreating,
  disabledCreateButton,
  onCreateButtonClick
}: ICreateSiteButtons) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.create'
  });

  return (
    <div className="mt-auto flex justify-center gap-6 pb-40 pt-12">
      <Link to="/sites">
        <Button
          variant="ghost"
          disabled={isCreating}
          data-testid="cancel-button"
        >
          {t('buttons.cancel')}
        </Button>
      </Link>
      <Button
        isLoading={isCreating}
        variant="primary"
        disabled={disabledCreateButton}
        onClick={onCreateButtonClick}
        data-testid="create-button"
      >
        {t('buttons.create')}
      </Button>
    </div>
  );
};
