import { ComponentType } from 'react';

import {
  ReactPlugin,
  withAITracking
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let reactPlugin: ReactPlugin;
let appInsights;

const initialize = (instrumentationKey: string) => {
  reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      enableAutoRouteTracking: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      extensions: [reactPlugin as any],
      disableTelemetry: instrumentationKey === 'development'
    }
  });
  appInsights.loadAppInsights();
};

export default (Component: ComponentType<unknown>) =>
  withAITracking(reactPlugin, Component);
export { appInsights, initialize, reactPlugin };
