import { randNumber, randPastDate } from '@ngneat/falso';

import { enumValueFaker, makeArray } from '@utils/faker-utils';

import { EvseStatus, EvseStatusEnum } from '@omnis-pulse-types';

import { ChargerSocketStatusMockFactory } from './chargerSocketStatusMockFactory';

export class ChargerEvseStatusMockFactory {
  static createMockChargerEvseStatus(data?: Partial<EvseStatus>): EvseStatus {
    return {
      timestamp: randPastDate().toISOString(),
      status: enumValueFaker(EvseStatusEnum),
      id: randNumber(),
      sockets:
        ChargerSocketStatusMockFactory.createMockChargerSocketStatuses(2),
      ...data
    };
  }

  static createMockChargerEvseStatuses(
    numberOfChargers: number,
    data?: Partial<EvseStatus>
  ): EvseStatus[] {
    return makeArray(numberOfChargers, () =>
      this.createMockChargerEvseStatus(data)
    );
  }
}
