import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Link, useParams } from 'react-router-dom';

import useSiteChargers from '@api/sites/use-site-chargers';
import useSiteGateways from '@api/sites/use-site-gateways';
import AllConnectedIcon from '@icons/online_16.svg';
import NotConnectedIcon from '@icons/red_error_16.svg';
import { titleize } from '@utils/i18n-utils';

import {
  Card,
  DataError,
  LoadingSkeleton,
  Typography
} from '@destination/components';

import { ConnectionStatusEnum } from '@omnis-pulse-types';

export const GatewayCard = () => {
  const { id } = useParams();

  const { gateways, isLoading, isError, mutate } = useSiteGateways(id);
  const { chargers } = useSiteChargers(id);

  if (isLoading) {
    return (
      <LoadingSkeleton
        className="w-[235px] rounded-[10px] !bg-[#E6E6E6]"
        height={115}
      />
    );
  }

  if (isError)
    return (
      <div className="flex h-[115px] w-[235px] items-center justify-center">
        <DataError onRefresh={() => mutate(undefined, true)} />
      </div>
    );

  if (!gateways || gateways.length === 0) return null;

  const isOffline =
    gateways[0].connectionStatus.status !== ConnectionStatusEnum.CONNECTED;
  const notConnectedChargers =
    chargers?.reduce((acc, { connectionStatus: { status } }) => {
      if (status !== ConnectionStatusEnum.CONNECTED) return ++acc;
      return acc;
    }, 0) ?? 0;

  return (
    <Link to="devices/gateways" data-testid="gateways-link">
      <Card className="h-[115px] w-[235px] !pl-3 hover:cursor-pointer hover:drop-shadow-hover">
        <Typography variant="smallTitle" className="mb-1.5">
          Terra Gateway Pro
        </Typography>
        {isOffline && <NotConnected />}
        {!isOffline && notConnectedChargers > 0 && (
          <NotConnected notConnectedChargers={notConnectedChargers} />
        )}
        {!isOffline && notConnectedChargers === 0 && <AllConnected />}
      </Card>
    </Link>
  );
};

interface INotConnected {
  notConnectedChargers?: number;
}

const NotConnected = ({ notConnectedChargers }: INotConnected) => {
  const { t, t_generic } = usePortalTranslation('gateways');

  if (notConnectedChargers && notConnectedChargers > 0) {
    return (
      <Typography
        variant="smallText"
        className="flex items-center gap-1 font-bold"
      >
        <NotConnectedIcon data-testid="not-connected-icon" />
        {t('labels.devices_not_connected', { count: notConnectedChargers })}
      </Typography>
    );
  }

  return (
    <Typography
      variant="smallText"
      className="flex items-center gap-1 font-bold"
    >
      <NotConnectedIcon data-testid="not-connected-icon" />{' '}
      {titleize(t_generic('states.error'))}
    </Typography>
  );
};

const AllConnected = () => {
  const { t } = usePortalTranslation();

  return (
    <Typography variant="smallText" className="flex items-center gap-1">
      <AllConnectedIcon data-testid="all-connected-icon" />{' '}
      {titleize(t('states.online'))}
    </Typography>
  );
};
