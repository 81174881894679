import React, { Suspense } from 'react';

import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { SWRConfig, SWRConfiguration } from 'swr';

import { AbilityProvider } from '@context/AbilityContext';

import {
  AlertProvider,
  DialogProvider,
  Sidebar
} from '@destination/components';

import { loginRequest, msalInstance } from './authConfig';
import { router } from './routes/router';

import '../node_modules/@destination/components/dist/index.css';
import './i18n';
import './index.css';

import { GeneralFallback } from '@components/GeneralFallback';
import { UserContextProvider } from '@context/UserContext';
import { mswWorker } from '@test/mockHttpWorker';
import { fetcher } from '@utils/swr-utils';

import { AppInsightsProvider } from './AppInsightsProvider';

export const config = await fetch('/config.json').then(response =>
  response.json()
);

//i18next's t method can now return a null value which needs to be prevented.
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const swrConfig: SWRConfiguration = {
  shouldRetryOnError: false,
  fetcher
};

const isLocal = process.env.NODE_ENV === 'development';

const appInsightsKey = !isLocal
  ? config['AI_instrumentationKey']
  : 'development';

if (isLocal && import.meta.env.MODE === 'msw') {
  await mswWorker.start();
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ErrorBoundary FallbackComponent={GeneralFallback}>
        <UserContextProvider>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
          >
            <SWRConfig value={swrConfig}>
              <HelmetProvider>
                <AppInsightsProvider instrumentationKey={appInsightsKey}>
                  <DialogProvider>
                    <AlertProvider>
                      <Sidebar.Provider>
                        <Suspense>
                          <AbilityProvider>
                            <RouterProvider router={router} />
                          </AbilityProvider>
                        </Suspense>
                      </Sidebar.Provider>
                    </AlertProvider>
                  </DialogProvider>
                </AppInsightsProvider>
              </HelmetProvider>
            </SWRConfig>
          </MsalAuthenticationTemplate>
        </UserContextProvider>
      </ErrorBoundary>
    </MsalProvider>
  </React.StrictMode>
);
