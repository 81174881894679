import { createContext, ReactNode, useMemo } from 'react';

import {
  AbilityBuilder,
  AbilityClass,
  PureAbility,
  Subject,
  SubjectRawRule
} from '@casl/ability';
import { PackRule, unpackRules } from '@casl/ability/extra';
import { createPrismaAbility, PrismaQuery } from '@casl/prisma';
import { createContextualCan } from '@casl/react';

import useUserDetails from '@api/user-account/use-user-details';
import { Action } from '@constants/Permissions';

import { PermissionResourceType } from '@omnis-pulse-types';

export type Ability = PureAbility<[Action, Subject], PrismaQuery>;
export const Ability = PureAbility as AbilityClass<Ability>;
export const AbilityContext = createContext<Ability>(new Ability());
export const Can = createContextualCan(AbilityContext.Consumer);

interface IAbilityProvider {
  children: ReactNode;
}

export const AbilityProvider = ({ children }: IAbilityProvider) => {
  const { userDetails, isLoading } = useUserDetails();

  const builder = new AbilityBuilder<Ability>(createPrismaAbility);

  const abilityMemo = useMemo(() => new Ability(), []);

  const ability = builder.build();

  if (userDetails?.permissions) {
    ability.update(
      unpackRules(
        userDetails.permissions as PackRule<
          SubjectRawRule<Action, PermissionResourceType, never>
        >[]
      )
    );
  }

  return (
    <AbilityContext.Provider
      value={!isLoading && ability ? ability : abilityMemo}
    >
      {children}
    </AbilityContext.Provider>
  );
};
