import { usePortalTranslation } from '@hooks/use-portal-translation';

import useChargerFinishedSessions from '@api/chargers/use-charger-finished-sessions';

import { DataError, Typography } from '@destination/components';

import { ChargerSessionTable } from '../ChargerSessionTable';

export const FinishedSessions = ({ chargerId }: { chargerId: string }) => {
  const { t } = usePortalTranslation('chargers');

  const { sessions, isError, isLoading, mutate } =
    useChargerFinishedSessions(chargerId);

  if (isError) {
    return (
      <div>
        <Typography variant="smallTitle" className="mb-4">
          {t('literals.finished_sessions')}
        </Typography>
        <DataError onRefresh={mutate} />
      </div>
    );
  }

  return (
    <div>
      <Typography variant="smallTitle" className="mb-4">
        {t('literals.finished_sessions')}
      </Typography>
      <ChargerSessionTable sessions={sessions ?? []} isLoading={isLoading} />
    </div>
  );
};
