import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import HouseIcon from '@icons/house_48.svg';
import { getCountryName } from '@utils/utils';

import { Grid, HighlightedText, TableLoading } from '@destination/components';

import { Site } from '@omnis-pulse-types';

interface ISitesTable {
  sites: Site[];
  isLoading: boolean;
  searchQuery: string;
}

export const SitesTable = ({ sites, isLoading, searchQuery }: ISitesTable) => {
  return (
    <>
      <Grid className="mt-6 max-w-full grid-cols-[64px_auto_auto_minmax(25%,1fr)] grid-rows-[1fr] gap-y-3">
        <Header />
        {isLoading === false && (
          <Content sites={sites} searchQuery={searchQuery} />
        )}
      </Grid>
      {isLoading && <TableLoading isDarker className="mt-3" />}
    </>
  );
};

const Header = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.sites_table.header'
  });
  return (
    <>
      <Grid.HeadCell>{''}</Grid.HeadCell>
      <Grid.HeadCell>{t('site_name')}</Grid.HeadCell>
      <Grid.HeadCell>{t('country')}</Grid.HeadCell>
      <Grid.HeadCell>{t('address')}</Grid.HeadCell>
    </>
  );
};

const Content = ({
  sites,
  searchQuery
}: {
  sites: Site[];
  searchQuery: string;
}) => {
  const navigate = useNavigate();

  const handleNavigate = (site: Site) => {
    navigate(`/sites/${site.id}`);
  };

  return (
    <>
      {sites.map(
        site =>
          site && (
            <Grid.TableRow
              key={site.id}
              variant="card"
              onClick={() => handleNavigate(site)}
            >
              <Grid.DataCell className="content-center">
                <div className="h-12 w-12">
                  <HouseIcon />
                </div>
              </Grid.DataCell>
              <Grid.DataCell className="content-center break-all">
                <div className="font-bold">
                  <HighlightedText
                    text={site.label}
                    highlighted={searchQuery}
                  />
                </div>
              </Grid.DataCell>
              <Grid.DataCell className="content-center">
                {getCountryName('en', site.address?.countryCode)}
              </Grid.DataCell>
              <Grid.DataCell className="content-center">
                <HighlightedText
                  text={site.address?.addressLine ?? ''}
                  highlighted={searchQuery}
                />
              </Grid.DataCell>
            </Grid.TableRow>
          )
      )}
    </>
  );
};
