import { useMemo } from 'react';

import { getTime } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Line } from 'recharts';

import useSiteTelemetryCurrentDelivered from '@api/sites/use-site-telemetry-current-delivered';

import { PhaseCurrent } from '@omnis-pulse-types';

import { EvsePhaseCurrentChart } from './EvsePhaseCurrentChart';

export const EvsePhaseCurrentWithGridLimitChart = ({
  data,
  startTime,
  endTime,
  width,
  height
}: {
  data: PhaseCurrent[];
  startTime: string;
  endTime: string;
  height?: string;
  width?: string;
}) => {
  const { id = '' } = useParams();

  const { data: siteTelemetry } = useSiteTelemetryCurrentDelivered(
    id,
    startTime,
    endTime
  );

  const { t } = useTranslation(undefined, {
    keyPrefix: 'charger.evse.phase_current_graph.legend'
  });

  const gridLimitLine = [
    { dataKey: 'gridLimit', name: t('grid_limit'), stroke: '#00ACEC' }
  ].map((line, index) => (
    <Line
      data={siteTelemetry}
      key={index + 1}
      {...line}
      unit="A"
      type="monotone"
      isAnimationActive={false}
      dot={false}
      activeDot={false}
      connectNulls={true}
    />
  ));

  const mapped = useMemo(() => {
    return data.map(d => ({ ...d, timestamp: getTime(d.timestamp) }));
  }, [data]);

  return (
    <EvsePhaseCurrentChart
      data={mapped}
      additionalLines={gridLimitLine}
      width={width}
      height={height}
    />
  );
};
