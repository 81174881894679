import { ComponentProps } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useSiteLoadManagementContext } from '@hooks/use-site-load-management-context';

import type { Payload } from '@api/sites/use-update-site-load-management';

import { RadioGroup, Typography } from '@destination/components';

import { ChargingStrategy as ChargingStrategyEnum } from '@omnis-pulse-types';

type Options = ComponentProps<typeof RadioGroup>['options'];

export interface IChargingStrategy {
  chargingStrategy: ChargingStrategyEnum;
}

// Set a custom order for the strategies
const strategies = [
  ChargingStrategyEnum.EQUAL_POWER,
  ChargingStrategyEnum.EQUAL_SHARE,
  ChargingStrategyEnum.FIFO
];

export const ChargingStrategy = () => {
  const { t, t_generic } = usePortalTranslation('loadManagement');

  const { data, update, isUpdating } = useSiteLoadManagementContext();

  const options: Options = strategies.map(strategy => ({
    label: t(`strategy.${strategy.toLowerCase()}`, { titleize: true }),
    description: t(`strategy.${strategy.toLowerCase()}_description`, {
      titleize: true
    }),
    value: strategy
  }));

  const handleChange = async (value: string | number) => {
    const payload: Payload = {
      chargingStrategy: value as ChargingStrategyEnum
    };

    await update(payload);
  };

  const header = t_generic('labels.entity_strategy', {
    entity: t_generic('literals.charging'),
    titleize: true
  });

  return (
    <div className="mb-3 ml-3">
      <Typography variant="smallTitle" className="mb-4">
        {header}
      </Typography>
      <RadioGroup
        value={data?.chargingStrategy ?? null}
        options={options}
        disabled={isUpdating}
        onChange={handleChange}
      />
    </div>
  );
};
