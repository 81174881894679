import {
  rand,
  randBetweenDate,
  randNumber,
  randPastDate,
  randUuid
} from '@ngneat/falso';
import { endOfDay } from 'date-fns';

import { enumValueFaker, makeArray } from '@utils/faker-utils';

import {
  AuthorizationSource,
  ChargerActiveSession,
  ChargerFinishedSession
} from '@omnis-pulse-types';

export class ChargerSessionMockFactory {
  static createActiveChargerSession(
    data?: Partial<ChargerActiveSession>
  ): ChargerActiveSession {
    return {
      id: randUuid(),
      connectorId: randNumber({ min: 1, max: 4 }),
      authorizationSource: enumValueFaker(AuthorizationSource),
      startTime: randPastDate().toISOString(),
      energy: randNumber({ min: 3, max: 600 }),
      power: randNumber({ min: 1000, max: 9999 }),
      phases: rand([1, 3]),
      soc: randNumber({ min: 0, max: 100 }),
      ...data
    };
  }

  static createFinishedChargerSession(
    data?: Partial<ChargerFinishedSession>
  ): ChargerFinishedSession {
    const startDate = randPastDate();
    return {
      id: randUuid(),
      connectorId: randNumber({ min: 1, max: 4 }),
      authorizationSource: enumValueFaker(AuthorizationSource),
      startTime: startDate.toISOString(),
      endTime: randBetweenDate({
        from: startDate,
        to: endOfDay(new Date(startDate))
      }).toISOString(),
      energy: randNumber({ min: 3000, max: 60000 }),
      phases: rand([1, 3]),
      ...data
    };
  }

  static createActiveChargerSessions(
    numberOfSessions: number = randNumber({ min: 1, max: 15 })
  ): ChargerActiveSession[] {
    return makeArray(numberOfSessions, () => this.createActiveChargerSession());
  }

  static createFinishedChargerSessions(
    numberOfSessions: number = randNumber({ min: 1, max: 15 })
  ): ChargerFinishedSession[] {
    return makeArray(numberOfSessions, () =>
      this.createFinishedChargerSession()
    );
  }
}
