import { useOutletContext } from 'react-router-dom';

import { GridMeterCommunicationSettings } from '@components/grid-meters/GridMeterCommunicationSettings';
import { GridMeterConfiguration } from '@components/grid-meters/GridMeterConfiguration';

import { GridMeter as GridMeterModel } from '@omnis-pulse-types';

export const GridMeter = () => {
  const gridMeter = useOutletContext<GridMeterModel>();

  return (
    <>
      <GridMeterConfiguration
        type={gridMeter.type}
        variant={gridMeter.variant}
      />
      <GridMeterCommunicationSettings gridMeter={gridMeter} />
    </>
  );
};
