import { useOutletContext } from 'react-router-dom';

import { ConnectedChargersTable } from '@components/gateways/ConnectedChargersTable';
import { GatewayEventLogs } from '@components/gateways/GatewayEventLogs';
import { GatewayProperties } from '@components/gateways/GatewayProperties';

import { Gateway as GatewayModel } from '@omnis-pulse-types';

export const Gateway = () => {
  const gateway = useOutletContext<GatewayModel>();

  return (
    <>
      <GatewayProperties gateway={gateway} />
      <ConnectedChargersTable gatewayId={gateway.id} />
      <GatewayEventLogs gatewayId={gateway.id} />
    </>
  );
};
