import { ReactNode } from 'react';

import { getCountryName } from '@utils/utils';

import { LoadingSkeleton, Typography } from '@destination/components';

import { Site } from '@omnis-pulse-types';

interface ISiteNameAndAddress {
  site?: Site;
  isLoading: boolean;
}

export const SiteNameAndAddress = ({
  site,
  isLoading
}: ISiteNameAndAddress) => {
  let content: ReactNode;

  if (isLoading) {
    content = (
      <>
        <LoadingSkeleton
          height={30.48}
          className="mb-1 w-[250px] !bg-[#E6E6E6]"
        />
        <LoadingSkeleton
          height={17.77}
          className="w-full min-w-[250px] max-w-md !bg-[#E6E6E6]"
        />
      </>
    );
  }

  if (site) {
    let addressLine = '';
    const { address, label } = site;
    if (address) {
      const countryName = getCountryName('en-US', address.countryCode);

      addressLine = countryName;

      if (address.addressLine) {
        addressLine = `${address.addressLine}, ${countryName}`;
      }
    }
    content = (
      <>
        <Typography variant="pageTitle" className="mb-1 truncate text-ellipsis">
          {label}
        </Typography>
        <Typography variant="description">{addressLine}</Typography>
      </>
    );
  }

  return <header>{content}</header>;
};
