import { useLocation } from 'react-router-dom';

import { BreadcrumbComponent } from '@components/breadcrumbs/BreadcrumbComponent';

export const HMIBreadcrumbs = () => {
  const { pathname } = useLocation();

  return (
    <BreadcrumbComponent
      basePath="/hmi-settings"
      isLoading={false}
      pathname={pathname}
    />
  );
};
