import {
  randCompanyName,
  randFirstName,
  randLastName,
  randUuid
} from '@ngneat/falso';

import { serviceManagerPermissions } from '@fixtures/permissionsFixtures';

import { UserAccountDetails } from '@omnis-pulse-types';

export class UserAccountDetailsFactory {
  static createUserAccountDetails(
    data?: Partial<UserAccountDetails>
  ): UserAccountDetails {
    return {
      permissions: serviceManagerPermissions,
      organization: {
        id: randUuid(),
        name: randCompanyName()
      },
      userId: randUuid(),
      firstName: randFirstName(),
      lastName: randLastName(),
      ...data
    };
  }
}
