import { NavLink } from 'react-router-dom';

import { Typography } from '@destination/components';

export interface IBreadcrumbLink {
  label: string;
  path: string;
}

export const BreadcrumbLink = ({ label, path }: IBreadcrumbLink) => {
  return (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? 'w-full truncate font-bold [&>p]:truncate'
          : 'w-full whitespace-nowrap'
      }
      end
      to={path}
    >
      <Typography variant="smallText">{label}</Typography>
    </NavLink>
  );
};
