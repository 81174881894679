import { useDecodedToken } from '@hooks/use-decoded-token';
import { usePortalTranslation } from '@hooks/use-portal-translation';

import { EditAccountButton } from '@components/buttons/EditAccountButton';
import { TabLabel } from '@components/TabLabel';

import {
  Card,
  Input,
  LoadingSkeleton,
  Tooltip,
  Typography
} from '@destination/components';

export const Account = () => {
  const { t } = usePortalTranslation();

  const { isLoading, decodedToken } = useDecodedToken();

  const title = t('literals.account', { titleize: true });
  const sectionTitle = t('literals.general', { titleize: true });

  return (
    <div>
      <TabLabel label={title} />
      <div className="mb-6 flex justify-between">
        <Typography variant="pageTitle" className="capitalize">
          {title}
        </Typography>
        <EditAccountButton />
      </div>
      <Card className="mb-12 max-w-[374px] pb-5">
        <Typography variant="smallTitle" className="py-4 pt-3">
          {sectionTitle}
        </Typography>
        <div className="flex flex-col gap-6">
          {isLoading || !decodedToken ? (
            <Skeletons />
          ) : (
            <Content name={decodedToken.name} email={decodedToken.email} />
          )}
        </div>
      </Card>
    </div>
  );
};

const Skeletons = () => {
  return (
    <>
      <div>
        <LoadingSkeleton height={15} className="mb-1 ml-3 w-1/4" />
        <LoadingSkeleton height={40} />
      </div>
      <div>
        <LoadingSkeleton height={15} className="mb-1 ml-3 w-1/4" />
        <LoadingSkeleton height={40} />
      </div>
    </>
  );
};

const Content = ({ name, email }: { name: string; email: string }) => {
  const { t, t_generic } = usePortalTranslation('account');

  const labelName =
    t_generic('literals.name', { titleize: true }) +
    ' ' +
    t_generic('literals.surname', { titleize: true });
  const labelEmail = t_generic('literals.email_address', {
    titleize: true
  });
  const tooltip = t('tooltips.all_account_changes');

  return (
    <>
      <div
        className="contents [&>div]:mt-[19px] [&_div]:cursor-default"
        data-testid="name"
      >
        <Tooltip message={tooltip}>
          <Input id="name" label={labelName} value={name} disabled />
        </Tooltip>
      </div>
      <div
        className="contents [&>div]:mt-[19px] [&_div]:cursor-default"
        data-testid="email"
      >
        <Tooltip message={tooltip}>
          <Input id="email" label={labelEmail} value={email} disabled />
        </Tooltip>
      </div>
    </>
  );
};
