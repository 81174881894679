import { ChangeEvent, useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

export const useSearchFilter = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const newSearchQuery = searchParams.get('search');
    setSearchValue(newSearchQuery ?? '');
  }, [searchParams]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const currentParams = new URLSearchParams(window.location.search);

    if (inputValue) {
      currentParams.set('search', inputValue);
    } else {
      currentParams.delete('search');
    }

    setSearchParams(currentParams);
  };

  return { searchValue, handleSearch };
};
