import { createContext, ReactNode, useContext, useEffect } from 'react';

import { initialize, reactPlugin } from './AppInsightsService';

const AppInsightsContext = createContext(reactPlugin);

const AppInsightsProvider = ({
  instrumentationKey,
  children
}: {
  instrumentationKey?: string;
  children: ReactNode;
}) => {
  useEffect(() => {
    if (instrumentationKey) {
      initialize(instrumentationKey);
    } else {
      throw new Error('Instrumentation key not provided');
    }
  }, [instrumentationKey]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};

const useAppInsightsContext = () => useContext(AppInsightsContext);

export { AppInsightsContext, AppInsightsProvider, useAppInsightsContext };
