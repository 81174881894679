import { useEffect, useState } from 'react';

export const useZoomChart = () => {
  const [left, setLeft] = useState<number>();
  const [right, setRight] = useState<number>();

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setLeft(undefined);
        setRight(undefined);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleZoom = (refAreaLeft: number, refAreaRight: number) => {
    let left = refAreaLeft;
    let right = refAreaRight;

    if (refAreaLeft > refAreaRight) {
      left = refAreaRight;
      right = refAreaLeft;
    }

    setLeft(left);
    setRight(right);
  };

  const handleResetZoom = () => {
    setLeft(undefined);
    setRight(undefined);
  };

  return { left, right, handleZoom, handleResetZoom };
};
