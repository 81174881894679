import { GridMeterSerialCommunicationSettings } from '@components/grid-meters/GridMeterSerialCommunicationSettings';
import { GridMeterTcpCommunicationSettings } from '@components/grid-meters/GridMeterTcpCommunicationSettings';

import {
  GridMeter,
  SerialGridMeter,
  TcpGridMeter,
  Variant
} from '@omnis-pulse-types';

export const GridMeterCommunicationSettings = ({
  gridMeter
}: {
  gridMeter: GridMeter;
}) => {
  return (
    <>
      {isSerialGridMeter(gridMeter) && (
        <GridMeterSerialCommunicationSettings settings={gridMeter} />
      )}
      {isTCPGridMeter(gridMeter) && (
        <GridMeterTcpCommunicationSettings settings={gridMeter} />
      )}
    </>
  );
};

function isSerialGridMeter(gridMeter: GridMeter): gridMeter is SerialGridMeter {
  return gridMeter.variant === Variant.SERIAL;
}

function isTCPGridMeter(gridMeter: GridMeter): gridMeter is TcpGridMeter {
  return gridMeter.variant === Variant.TCP;
}
