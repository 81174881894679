import { rand, randNumber } from '@ngneat/falso';

import {
  ActiveLoadManagementMode,
  ChargingStrategy,
  SiteLoadManagement
} from '@omnis-pulse-types';

export class LoadManagementMockFactory {
  static createSiteLoadManagement(
    data?: Partial<SiteLoadManagement>
  ): SiteLoadManagement {
    const randMode = rand(Object.values(ActiveLoadManagementMode));
    const randChargeStrategy = rand(Object.values(ChargingStrategy));
    const randGridConnectionLimits = randNumber({
      min: 16,
      max: 600,
      length: 2
    });
    const randSiteAuxiliaryCurrent = randNumber({
      min: 4,
      max: randGridConnectionLimits[0]
    });
    const randFallbackChargingBudget = randNumber({
      min: 4,
      max: randGridConnectionLimits[1]
    });

    return {
      activeMode: randMode,
      chargingStrategy: randChargeStrategy,
      staticLoadManagement: {
        gridConnectionLimit: randGridConnectionLimits[0],
        siteAuxiliaryCurrent: randSiteAuxiliaryCurrent,
        chargingBudgetLimit:
          randGridConnectionLimits[0] - randSiteAuxiliaryCurrent
      },
      dynamicLoadManagement: {
        gridConnectionLimit: randGridConnectionLimits[1],
        fallbackChargingBudgetLimit: randFallbackChargingBudget
      },
      ...data
    };
  }
}
