import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import useChargerEvseTelemetry from '@api/chargers/use-charger-evse-telemetry';
import { EvsePhaseCurrentWithGridLimitChart } from '@components/sites/site/insights/EvsePhaseCurrentWithGridLimitChart';
import { EvsePhaseCurrentWithGridLimitLegend } from '@components/sites/site/insights/EvsePhaseCurrentWithGridLimitLegend';
import { YAxisTickProvider } from '@context/YAxisTickContext';

import { Drawer, LoadingSkeleton, Typography } from '@destination/components';

import { ChargerActiveSession, EvseStatus } from '@omnis-pulse-types';

import { EvseSessionDetails } from './EvseSessionDetails';

export interface IEvseSessionDrawer {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  chargerId: string;
  evse: EvseStatus;
  session: ChargerActiveSession;
}

export const EvseSessionDrawer = ({
  isOpen,
  title,
  onClose,
  chargerId,
  evse,
  session
}: IEvseSessionDrawer) => {
  const [endTime] = useState(new Date().toISOString()); // Directly set endTime
  const { t } = useTranslation(undefined, {
    keyPrefix:
      'sites.site.site_overview.chargers_overview_table.charger_session_drawer'
  });

  const { data, isLoading } = useChargerEvseTelemetry(
    chargerId,
    session.startTime,
    endTime
  );

  const phaseCurrentData = data?.find(
    telemetry => telemetry.id === evse.id
  )?.currentConsumption;

  return (
    <div onClick={event => event.stopPropagation()}>
      <Drawer title={title} isOpen={isOpen} onClose={onClose}>
        <Typography variant="description">{t('session_details')}</Typography>
        <div className="mt-4">
          {isLoading && <LoadingSkeleton height={103} />}
          {phaseCurrentData && (
            <>
              <YAxisTickProvider>
                <EvsePhaseCurrentWithGridLimitChart
                  data={phaseCurrentData}
                  startTime={session.startTime}
                  endTime={endTime}
                  height={'140px'}
                />
              </YAxisTickProvider>
              <div className="mb-8 flex-initial">
                <EvsePhaseCurrentWithGridLimitLegend />
              </div>
            </>
          )}
          <EvseSessionDetails evse={evse} session={session} />
          {/* Use the new component */}
        </div>
      </Drawer>
    </div>
  );
};
