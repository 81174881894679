import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { IOption } from '@destination/components';

export const useFilter = (key: string, options: IOption[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedOptions = useMemo(() => {
    const newFilterOption = searchParams.get(key);

    let newSelectedOptions: IOption[] = [];

    if (newFilterOption) {
      const chunks = newFilterOption.split(',');
      newSelectedOptions = options.filter(({ value }) =>
        chunks.includes(value.toString())
      );
    }

    return newSelectedOptions;
  }, [key, options, searchParams]);

  function handleFilter(filterOptions: IOption[]) {
    const params = new URLSearchParams(window.location.search);

    if (filterOptions.length > 0) {
      const values = filterOptions.map(option => option.value).join(',');
      params.set(key, values);
    } else {
      params.delete(key);
    }

    setSearchParams(params);
  }

  return { selectedOptions, handleFilter };
};
