import { useEffect } from 'react';

import {
  useAppInsightsContext,
  useTrackEvent
} from '@microsoft/applicationinsights-react-js';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError } from 'react-router-dom';

import SadIcon from '@icons/sad_face_96.svg';

import { Button, Typography } from '@destination/components';

export const RouteErrorFallback = () => {
  const appInsights = useAppInsightsContext();
  const error = useRouteError();
  const { t } = useTranslation(undefined, { keyPrefix: 'general' });
  const trackError = useTrackEvent(appInsights, 'RouteError', error);
  const navigate = useNavigate();

  useEffect(() => {
    trackError(error);
  }, [error, trackError]);

  return (
    <div className="flex h-full w-full flex-col justify-center text-center">
      <div className="mb-8 flex justify-center" data-testid="sad-icon">
        <SadIcon />
      </div>
      <div className="flex flex-col justify-center text-center">
        <Typography variant="smallTitle" className="mb-6">
          {t('something_went_wrong.title')}
        </Typography>
        <Typography variant="description">
          {t('something_went_wrong.description')}
        </Typography>
        <div className="mt-6 flex justify-center">
          <Button variant="primary" onClick={() => navigate(-1)}>
            <Typography variant="smallText">
              {t('something_went_wrong.go_back')}
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};
