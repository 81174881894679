import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import LockIcon from '@icons/lock_96.svg';
import SignOutIcon from '@icons/sign_out_16.svg';

import { Button, Typography } from '@destination/components';

export const UserAccountUnknown = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'general' });
  const { instance } = useMsal();

  const handleLogout = async () => {
    await instance.logout();
  };
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="w-full text-center">
        <div className="mb-8 flex justify-center" data-testid="lock-icon">
          <LockIcon />
        </div>
        <div className="mb-8 flex justify-center text-center">
          <Typography variant="sectionTitle" className="w-[211px]">
            {t('you_do_not_have_access')}
          </Typography>
        </div>
        <div className="flex justify-center text-lg">
          <Button
            variant="accent"
            onClick={handleLogout}
            icon={<SignOutIcon />}
          >
            <Typography variant="smallTitle">{t('sign_out')}</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};
