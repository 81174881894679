import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useParams } from 'react-router-dom';

import useSiteDevices from '@api/sites/use-site-devices';

import { Card, Tab } from '@destination/components';

export const Devices = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site.devices'
  });
  const { id = '' } = useParams();

  const basePath = `/sites/${id}/devices`;

  const { devices } = useSiteDevices(id);

  const tabs = [];

  if (devices && devices.chargers.length > 0) {
    tabs.push({ label: 'tab.chargers', path: '/chargers' });
  }

  if (devices && devices.gridMeters.length > 0) {
    tabs.push({ label: 'tab.grid_meter', path: '/grid-meters' });
  }

  if (devices && devices.gateways.length > 0) {
    tabs.push({ label: 'tab.terra_gateway_pro', path: '/gateways' });
  }

  return (
    <>
      <nav className="tw-inline-flex gap-4">
        {tabs.map(tab => (
          <NavLink
            key={tab.label}
            to={`${basePath}${tab.path}`}
            end={
              tab.path !== '/chargers' &&
              tab.path !== '/grid-meters' &&
              tab.path !== '/gateways'
            }
          >
            {({ isActive }) => (
              <Tab
                data-testid={`tab-${tab.label}`}
                isActive={isActive}
                isSubTab={true}
              >
                {t(tab.label)}
              </Tab>
            )}
          </NavLink>
        ))}
      </nav>
      <Card className="flex grow flex-col !rounded-tl-none !shadow-none">
        <Outlet />
      </Card>
    </>
  );
};
