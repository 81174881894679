import useSWR from 'swr';

import { UserAccountDetails } from '@omnis-pulse-types';

import { USER_DETAILS_PATH } from '../paths';

export default function useUserDetails() {
  const { data, error, isLoading } =
    useSWR<UserAccountDetails>(USER_DETAILS_PATH);

  return { userDetails: data, isLoading, isError: Boolean(error) };
}
