import { useLegendToggle } from '@hooks/use-legend-toggle';
import { useZoomChart } from '@hooks/use-zoom-chart';
import { getTime } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Legend, Line, Tooltip, XAxis, YAxis } from 'recharts';

import useSiteGridMeterTelemetry from '@api/sites/use-site-grid-meter-telemetry';
import { ComposedGraph } from '@components/charts/ComposedGraph';
import { legendFormatter } from '@components/charts/Legend';
import ChartIcon from '@icons/chart_96.svg';
import { generateXAxisTicks } from '@utils/chart-utils';
import { formatDate, timeFormat } from '@utils/date-utils';

import {
  Card,
  DataError,
  LoadingSkeleton,
  Typography
} from '@destination/components';

import { RenderTooltip } from '../insights/GraphTooltip';

export const SiteOverview = () => {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site.site_overview.site_overview'
  });
  const { data, isLoading, isError, mutate } = useSiteGridMeterTelemetry(id);
  const {
    graphProps,
    handleClick,
    handleLegendMouseEnter,
    handleLegendMouseLeave
  } = useLegendToggle(['currentL1', 'currentL2', 'currentL3', 'gridLimit']);
  const { left, right, handleZoom, handleResetZoom } = useZoomChart();

  const renderGraph = () => {
    const seriesOfGridLimits: { timestamp: number; gridLimit: number }[] =
      data?.maxCurrent
        ?.map(element => {
          if (element.timestamp) {
            return {
              gridLimit: element.value,
              timestamp: getTime(new Date(element.timestamp))
            };
          }
          return undefined;
        })
        .filter(
          (entry): entry is { timestamp: number; gridLimit: number } =>
            entry !== undefined
        ) || [];

    const seriesOfConsumptionValues =
      data?.current
        ?.map(element => {
          if (element.timestamp) {
            return {
              ...element,
              timestamp: getTime(new Date(element.timestamp))
            };
          }
        })
        .filter(entry => entry !== undefined) || [];

    return (
      <ComposedGraph onZoom={handleZoom} onResetZoom={handleResetZoom}>
        <Tooltip content={<RenderTooltip />} />
        <XAxis
          dataKey="timestamp"
          tickLine={false}
          tick={{ fill: '#9F9F9F', fontSize: 8 }}
          stroke="#ebebeb"
          interval="preserveStartEnd"
          tickFormatter={tick => formatDate(tick, timeFormat)}
          scale="time"
          type="number"
          domain={left && right ? [left, right] : ['auto', 'auto']}
          ticks={generateXAxisTicks()}
        />
        <YAxis
          unit=" A"
          orientation="right"
          tickLine={false}
          tick={{ fill: '#9F9F9F', fontSize: 8 }}
          stroke="#ebebeb"
          type="number"
          width={45}
        />
        <Legend
          onMouseOver={handleLegendMouseEnter}
          onMouseOut={handleLegendMouseLeave}
          iconType="circle"
          iconSize={6}
          align="left"
          formatter={value => legendFormatter(value, handleClick, t)}
        />
        <Line
          type="linear"
          dataKey="currentL1"
          stroke="black"
          dot={false}
          connectNulls={true}
          data={seriesOfConsumptionValues}
          hide={graphProps['currentL1'] === false}
          fillOpacity={Number(
            graphProps.hover === 'currentL1' || !graphProps.hover ? 1 : 0.2
          )}
        />
        <Line
          type="linear"
          dataKey="currentL2"
          stroke="#2A35FF"
          dot={false}
          connectNulls={true}
          data={seriesOfConsumptionValues}
          hide={graphProps['currentL2'] === false}
          fillOpacity={Number(
            graphProps.hover === 'currentL2' || !graphProps.hover ? 1 : 0.2
          )}
        />
        <Line
          type="linear"
          dataKey="currentL3"
          stroke="#E64997"
          dot={false}
          connectNulls={true}
          data={seriesOfConsumptionValues}
          hide={graphProps['currentL3'] === false}
          fillOpacity={Number(
            graphProps.hover === 'currentL3' || !graphProps.hover ? 1 : 0.2
          )}
        />
        <Line
          type="step"
          dataKey="gridLimit"
          stroke="#00ACEC"
          dot={false}
          connectNulls={true}
          data={seriesOfGridLimits}
          hide={graphProps['gridLimit'] === false}
          fillOpacity={Number(
            graphProps.hover === 'gridLimit' || !graphProps.hover ? 1 : 0.2
          )}
        />
      </ComposedGraph>
    );
  };

  const renderDataError = () => (
    <div className="flex h-full w-full items-center">
      <DataError onRefresh={() => mutate(undefined, true)} />
    </div>
  );

  const renderLoadingSkeleton = () => (
    <LoadingSkeleton
      className="!h-full rounded-[10px] !bg-[#E6E6E6]"
      height={0}
    />
  );

  const renderNoMeasurements = () => (
    <Card className="flex h-full flex-col items-center justify-center">
      <ChartIcon />
      <Typography variant="smallText" className="w-48 pt-6 text-center">
        {t('no_measurements')}
      </Typography>
    </Card>
  );

  const renderContent = () => {
    if (isError) {
      return renderDataError();
    }
    if (isLoading) {
      return renderLoadingSkeleton();
    }
    if (
      (data?.current || []).length === 0 &&
      (data?.maxCurrent || []).length === 0
    ) {
      return renderNoMeasurements();
    }

    return <Card className="h-full">{renderGraph()}</Card>;
  };

  return (
    <div
      className="flex h-full w-full flex-col"
      data-testid="energy-delivered-graph"
    >
      <div className="mb-3 flex flex-col gap-1">
        <Typography variant="sectionTitle">{t('title')}</Typography>
        <Typography variant="description">{t('description')}</Typography>
      </div>
      <div className="w-full flex-1">{renderContent()}</div>
    </div>
  );
};
