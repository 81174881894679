import { useSearchFilter } from '@hooks/use-search-filter';

import { SearchInput, Typography } from '@destination/components';

export interface IPageHeader {
  title: string;
  isSearchVisible?: boolean;
  searchPlaceholder?: string;
}

export const PageHeader = ({
  title,
  isSearchVisible = true,
  searchPlaceholder
}: IPageHeader) => {
  const { searchValue, handleSearch } = useSearchFilter();

  return (
    <div className="mb-6 flex min-h-[40px] items-center justify-between">
      <Typography variant="pageTitle" className="capitalize">
        {title}
      </Typography>
      {isSearchVisible && searchPlaceholder !== undefined && (
        <div className="w-full max-w-md">
          <SearchInput
            placeholder={searchPlaceholder}
            value={searchValue}
            onChange={handleSearch}
          />
        </div>
      )}
    </div>
  );
};
