import { NavLink } from 'react-router-dom';

import {
  MobileNavigation as MobileNav,
  Sidebar
} from '@destination/components';

export interface INavigationItem {
  id: string;
  icon: React.ReactNode;
  label: string;
  url: string;
}

export const Navigation = ({ items }: { items: INavigationItem[] }) => {
  return (
    <nav className="flex h-full flex-col justify-between">
      {items.map(({ id, icon, label, url }) => (
        <NavLink key={url} to={url} data-testid={`navigation-${id}`}>
          {({ isActive }) => (
            <Sidebar.MenuItem icon={icon} label={label} isActive={isActive} />
          )}
        </NavLink>
      ))}
    </nav>
  );
};

export const MobileNavigation = ({ items }: { items: INavigationItem[] }) => {
  return (
    <>
      {items.map(({ id, icon, label, url }) => (
        <NavLink key={url} to={url} data-testid={`navigation-${id}`}>
          {({ isActive }) => (
            <MobileNav.MenuItem icon={icon} label={label} isActive={isActive} />
          )}
        </NavLink>
      ))}
    </>
  );
};
