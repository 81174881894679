import { useEffect } from 'react';

import { useYAxisTickContext } from '@context/YAxisTickContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomYAxisTick = (props: any) => {
  const { updateCollection } = useYAxisTickContext();

  const { x, y, payload, tickFormatter, orientation } = props;

  useEffect(() => {
    if (orientation === 'left') {
      updateCollection({ y, value: payload.value });
    }
  }, [payload.value, y, updateCollection, orientation]);

  return (
    <g>
      <text
        fontSize="12"
        fontFamily="ABBvoice"
        transform={
          orientation === 'right' ? 'translate(8, 0)' : 'translate(-8, 0)'
        }
        x={x}
        y={y}
        textAnchor={orientation === 'right' ? undefined : 'end'}
        fill="#9F9F9F"
      >
        <tspan x={x} dy={payload.value === 0 ? '0em' : '0.355em'}>
          {tickFormatter(payload.value)}
        </tspan>
      </text>
    </g>
  );
};
