import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { SITE_BY_ID_PATH, SITE_PATH } from '@api/paths';
import updateSite from '@api/sites/update-site';
import { countryCodes } from '@constants/countryCodes';
import { isNotEmpty } from '@utils/input-utils';
import { getCountryName } from '@utils/utils';

import {
  Dialog,
  Input,
  IOption,
  Select,
  useAlert,
  useInput
} from '@destination/components';

import { Site } from '@omnis-pulse-types';

export interface IEditSiteForm {
  showDialog: boolean;
  site: Site;
  onClose: () => void;
}

const countryOptions: IOption[] = countryCodes.map((countryCode): IOption => {
  const countryName = getCountryName('en-US', countryCode);
  return { label: countryName ?? 'unknown', value: countryCode };
});

export const EditSiteForm = ({ site, showDialog, onClose }: IEditSiteForm) => {
  // Computed values
  const initialCountryOption =
    countryOptions.find(({ value }) => value === site.address?.countryCode) ??
    null;

  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<IOption | null>(
    initialCountryOption
  );

  const { id } = useParams() as { id: string };

  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site_header.edit_form'
  });

  const { notify } = useAlert();

  const {
    value: nameValue,
    isValid: nameIsValid,
    valueChangeHandler: nameChangeHandler,
    reset: nameReset
  } = useInput(isNotEmpty, site ? site.label : '');

  const {
    value: addressValue,
    valueChangeHandler: addressChangeHandler,
    reset: addressReset
  } = useInput(() => true, site.address?.addressLine ?? '');

  const handleClose = () => {
    nameReset();
    setSelectedCountry(initialCountryOption);
    addressReset();

    onClose();
  };

  const handleConfirm = async () => {
    setIsUpdating(true);

    const { error } = await updateSite(id, {
      label: nameValue,
      address: addressValue,
      countryCode: selectedCountry?.value as string
    });

    if (error) {
      notify({ header: t('not_saved'), variant: 'error' });

      nameReset();
      setSelectedCountry(initialCountryOption);
      addressReset();
    } else {
      notify({ header: t('saved'), variant: 'success' });

      await mutate(
        key => typeof key === 'string' && key.startsWith(`${SITE_PATH}?`),
        undefined,
        { revalidate: true }
      );
      await mutate(SITE_BY_ID_PATH(id));

      onClose();
    }

    setIsUpdating(false);
  };

  const editSiteForm = (
    <form className="mb-2 mt-[35px] flex flex-col gap-[43px]">
      <Input
        id="name"
        label={t('name.label')}
        placeholder={t('name.placeholder')}
        value={nameValue}
        error={!nameIsValid}
        helperText={!nameIsValid ? t('name.helper_text') : undefined}
        onChange={event => nameChangeHandler(event.target.value)}
        data-testid="name-input"
      />
      <Select
        label={t('country.label')}
        enableSearch={true}
        searchPlaceholder={t('country.search_placeholder')}
        placeholder={t('country.placeholder')}
        options={countryOptions}
        selected={selectedCountry}
        helperText={!selectedCountry ? t('country.helper_text') : undefined}
        onChange={option => setSelectedCountry(option)}
        data-testid="country-input"
      />
      <Input
        id="address"
        data-testid="address-input"
        label={t('address.label')}
        placeholder={t('address.placeholder')}
        value={addressValue}
        onChange={event => addressChangeHandler(event.target.value)}
      />
    </form>
  );

  return (
    <Dialog
      data-testid="edit-site"
      header={t('header')}
      isOpen={showDialog}
      isConfirmDisabled={!nameIsValid}
      isLoading={isUpdating}
      confirmButton={t('confirm_button')}
      cancelButton={t('cancel_button')}
      onConfirm={handleConfirm}
      onCancel={handleClose}
      onClose={handleClose}
      content={editSiteForm}
    />
  );
};
