import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useGatewayEventLogs from '@api/gateways/use-gateway-event-log';
import { NavigationButton } from '@components/buttons/NavigationButton';
import { LogsTable } from '@components/table/logs/LogsTable';

import { Typography } from '@destination/components';

interface IGatewayEventLogs {
  gatewayId: string;
}

export const GatewayEventLogs = ({ gatewayId }: IGatewayEventLogs) => {
  const { id } = useParams();

  const { t } = usePortalTranslation();

  const { eventLogs, isLoading, isError } = useGatewayEventLogs(gatewayId);

  const title = t('labels.entity_logs', {
    entity: t('literals.event'),
    titleize: true
  });
  const buttonLabel = t('labels.see_all_entities', {
    entity: t('literals.event')
  });

  return (
    <>
      <Typography variant="sectionTitle" className="mb-4 mt-12">
        {title}
      </Typography>
      <LogsTable
        logs={eventLogs ?? []}
        isLoading={isLoading}
        isError={isError}
      />
      <NavigationButton
        className="mb-[37px] ml-auto mt-2"
        label={buttonLabel}
        to={`/sites/${id}/logs`}
      />
    </>
  );
};
