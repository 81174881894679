import { usePortalTranslation } from '@hooks/use-portal-translation';
import useSWRMutation from 'swr/mutation';

import { getToken } from '@api/api';
import { SITE_BY_ID_PATH } from '@api/paths';
import { axiosInstance } from '@utils/swr-utils';

import { useAlert } from '@destination/components';

import type { UpdateSiteLoadManagement } from '@omnis-pulse-types';

export type Payload = UpdateSiteLoadManagement;

async function updateSiteLoadManagement(
  url: string,
  { arg }: { arg: Payload }
) {
  const token = await getToken();
  return axiosInstance.patch(url, arg, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export default function useUpdateSiteLoadManagement(siteId?: string) {
  const { t } = usePortalTranslation();

  const { notify } = useAlert();

  const successMsg = t('labels.save_settings_success', {
    entity: t('literals.load_management'),
    titleize: true
  });
  const errorMsg = t('errors.failed_to_save_settings', {
    entity: t('literals.load_management'),
    titleize: true
  });

  const { trigger, isMutating } = useSWRMutation(
    siteId ? SITE_BY_ID_PATH(siteId) + '/load-management' : null,
    updateSiteLoadManagement,
    {
      rollbackOnError: true,
      revalidate: false,
      onSuccess: () => {
        notify({ variant: 'success', header: successMsg });
      },
      onError: () => {
        notify({ variant: 'error', header: errorMsg });
      }
    }
  );

  return { trigger, isUpdating: isMutating };
}
