import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import ErrorIcon from '@icons/error_16.svg';
import RefreshIcon from '@icons/refresh_16.svg';

import { IconButton, Typography } from '@destination/components';

export interface IDetailLine {
  label?: string;
  className?: string;
  isError?: boolean;
  onRefresh?: () => void;
  icon?: ReactNode;
  children: ReactNode;
  'data-testid'?: string;
}

export const DetailLine = ({
  label,
  icon,
  className = '',
  isError,
  onRefresh,
  children,
  'data-testid': dataTestId
}: IDetailLine) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'general'
  });

  return (
    <div
      className={`flex items-center gap-4 ${className}`}
      data-testid={dataTestId}
    >
      <span className="flex w-[200px] gap-2">
        {icon && <span className="h-4 w-4">{icon}</span>}
        {label && (
          <Typography data-testid={`label-${label}`}>{label}</Typography>
        )}
      </span>
      {isError ? (
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-1">
            <ErrorIcon className="text-[#BABABA]" />
            <Typography variant="smallTitle">{t('data_error')}</Typography>
          </div>
          {onRefresh && (
            <IconButton data-testid="refresh-icon-button" onClick={onRefresh}>
              <RefreshIcon />
            </IconButton>
          )}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};
