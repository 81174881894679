import { randNumber, randPastDate, randSemver, randUuid } from '@ngneat/falso';

import { enumValueFaker, makeArray, randAlphaString } from '@utils/faker-utils';

import {
  Charger,
  ChargerDetails,
  ConnectionStatusEnum,
  IoTConnectionStatus
} from '@omnis-pulse-types';

import { ChargerEvseStatusMockFactory } from './chargerEvseStatusMockFactory';

export class ChargerMockFactory {
  static createCharger(data?: Partial<Charger>): Charger {
    return {
      id: randUuid(),
      name: `Charger ${randNumber()}`,
      serialNumber: randUuid(),
      status: enumValueFaker(IoTConnectionStatus),
      evseStatuses:
        ChargerEvseStatusMockFactory.createMockChargerEvseStatuses(1),
      connectionStatus: {
        status: enumValueFaker(ConnectionStatusEnum),
        timestamp: randPastDate().toLocaleString()
      },
      ...data
    };
  }

  static createChargerDetails(data?: Partial<ChargerDetails>): ChargerDetails {
    return {
      id: randUuid(),
      name: randAlphaString(),
      serialNumber: randUuid(),
      maxCurrent: randNumber({ min: 20, max: 45 }),
      maxPower: randNumber({ min: 160, max: 220 }),
      productType: randAlphaString(),
      firmwareVersion: randSemver(),
      numberOfEvses: randNumber({ min: 2, max: 6 }),
      status: enumValueFaker(IoTConnectionStatus),
      evseStatuses:
        ChargerEvseStatusMockFactory.createMockChargerEvseStatuses(2),
      connectionStatus: {
        status: enumValueFaker(ConnectionStatusEnum),
        timestamp: randPastDate().toLocaleString()
      },
      ...data
    };
  }

  static createChargers(numOfCharger = 3, data?: Partial<Charger>) {
    return makeArray(numOfCharger, () => this.createCharger(data));
  }
}
