import useSWR from 'swr';

import { GATEWAY_BY_ID_PATH } from '@api/paths';

import { Gateway } from '@omnis-pulse-types';

export default function useGateway(gatewayId?: string) {
  const { data, error, isLoading, mutate } = useSWR<Gateway>(
    gatewayId ? GATEWAY_BY_ID_PATH(gatewayId) : null
  );

  return { gateway: data, isLoading, isError: Boolean(error), mutate };
}
