import { ComponentProps } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useSiteLoadManagementContext } from '@hooks/use-site-load-management-context';

import type { Payload } from '@api/sites/use-update-site-load-management';

import { RadioGroup, Typography } from '@destination/components';

import { ActiveLoadManagementMode } from '@omnis-pulse-types';

import { DynamicLoadManagement } from './DynamicLoadManagement';
import { StaticLoadManagement } from './StaticLoadManagement';

type Options = ComponentProps<typeof RadioGroup>['options'];

const loadManagementModes = [
  ActiveLoadManagementMode.STATIC,
  ActiveLoadManagementMode.DYNAMIC
];

export const LoadManagementMode = () => {
  const { t, t_generic } = usePortalTranslation('loadManagement');

  const { data, update, isUpdating } = useSiteLoadManagementContext();

  const options: Options = loadManagementModes.map(mode => ({
    label: t('mode.' + mode.toLowerCase(), { titleize: true }),
    description:
      mode === ActiveLoadManagementMode.STATIC ? (
        <StaticLoadManagement />
      ) : (
        <DynamicLoadManagement />
      ),
    value: mode
  }));

  const handleChange = async (value: string | number) => {
    const payload: Payload = {
      activeMode: value as ActiveLoadManagementMode
    };

    await update(payload);
  };

  return (
    <div className="ml-3">
      <Typography variant="smallTitle" className="mb-4">
        {t_generic('literals.load_management', { titleize: true })}
      </Typography>
      <RadioGroup
        value={data?.activeMode ?? null}
        options={options}
        disabled={isUpdating}
        onChange={handleChange}
      />
    </div>
  );
};
