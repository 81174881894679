import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';

import { formatDate, timeFormat } from '@utils/date-utils';

export const RenderTooltip = ({
  active,
  payload,
  label
}: TooltipProps<number, string>) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site.insights.graph'
  });
  if (active && payload) {
    return (
      <div className="align-start inline-flex flex-col gap-2 rounded bg-white p-2 drop-shadow-[0_0_15px_rgba(0,0,0,0.2)]">
        <TooltipLine
          label={t('timestamp')}
          value={formatDate(label, timeFormat)}
        />
        {payload.map(element => {
          if (element.payload.timestamp === label) {
            return (
              <TooltipLine
                key={`${element.name}-${element.value}`}
                value={
                  element.value !== undefined
                    ? element.value.toFixed(2)?.toString()
                    : ''
                }
                label={element.name ? t(element.name) : ''}
              />
            );
          }
        })}
      </div>
    );
  }

  return null;
};

const TooltipLine = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className="align-start flex justify-between gap-[17px]">
      <p className="text-xs">{label}</p>
      <p className="text-xs font-bold">{value}</p>
    </div>
  );
};
