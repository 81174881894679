import { useTranslation } from 'react-i18next';

import useUpdateUserNotificationSettings from '@api/user-account/update-user-notification-settings';

import {
  AutomaticSaveCheckbox,
  AutomaticSaveSwitch,
  Card,
  Checkbox,
  Tooltip,
  Typography,
  useDialog
} from '@destination/components';

import {
  CommunicationTypeEnum,
  UserNotificationSettingsType
} from '@omnis-pulse-types';

interface INotificationBoxProps {
  type: UserNotificationSettingsType;
}

interface CommunicationType {
  name: CommunicationTypeEnum;
  selected: boolean;
}

export const NotificationTypeBox = ({ type }: INotificationBoxProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'notifications' });

  const { trigger } = useDialog();
  const { update: triggerNotificationUpdate } =
    useUpdateUserNotificationSettings(type);

  const isLastCheckedCheckBox = (option: CommunicationType) => {
    const allUncheckedExceptCurrent = type.communicationTypes
      .filter(communication => communication.name !== option.name)
      .every(communication => !communication.selected);
    return option.selected && allUncheckedExceptCurrent;
  };

  const handleSwitch = async (value: boolean) => {
    let updatedCommunicationTypes = type.communicationTypes;

    const allUnchecked = type.communicationTypes.every(
      communication => !communication.selected
    );

    if (value && allUnchecked) {
      updatedCommunicationTypes = type.communicationTypes.map(
        communication => ({
          ...communication,
          selected: true
        })
      );
    }

    await triggerNotificationUpdate({
      ...type,
      enabled: value,
      communicationTypes: updatedCommunicationTypes
    });
  };

  const handleSaveCommunicationType = async (
    communicationType: CommunicationType,
    value: boolean
  ) => {
    const updatedCommunicationTypes = type.communicationTypes.map(
      communication =>
        communication.name === communicationType.name
          ? { ...communication, selected: value }
          : communication
    );
    let updatedEnabled = type.enabled;
    if (!value && isLastCheckedCheckBox(communicationType)) {
      updatedEnabled = false;
    }

    await triggerNotificationUpdate({
      ...type,
      enabled: updatedEnabled,
      communicationTypes: updatedCommunicationTypes
    });
  };

  const handleLastCheckboxClick = (event: React.MouseEvent) => {
    event.preventDefault();
    trigger({
      header: t('last_checked_dialog.header'),
      content: t('last_checked_dialog.content'),
      cancelButton: t('last_checked_dialog.cancel'),
      variant: 'warning',
      onCancel: () => {}
    });
  };

  const NotificationSwitch = (
    <AutomaticSaveSwitch
      activeLabel={t('notify_me')}
      inactiveLabel={t('dont_notify_me')}
      initialCheckedValue={type.enabled}
      onSave={handleSwitch}
      disabled={type.alwaysEnabled}
    />
  );

  return (
    <Card className="mb-12 max-w-[512px] pb-5">
      <div className="flex items-center">
        <Typography
          variant="smallTitle"
          className="min-w-[300px] max-w-[300px] break-words pr-10"
        >
          {type.name}
        </Typography>
        {type.alwaysEnabled ? (
          <Tooltip message={t('alwaysEnabled_tooltip')}>
            {NotificationSwitch}
          </Tooltip>
        ) : (
          NotificationSwitch
        )}
      </div>
      <div className="flex flex-col gap-4 pb-2 pt-[27px]">
        {type.communicationTypes.map(option => {
          const isLastChecked = isLastCheckedCheckBox(option);
          return type.alwaysEnabled && isLastChecked ? (
            <Checkbox
              key={`${type.name}-${option.name}`}
              label={t(`communication_type.${option.name.toLowerCase()}`)}
              onClick={event => handleLastCheckboxClick(event)}
              defaultChecked={option.selected}
              disabled={!type.enabled}
            />
          ) : (
            <AutomaticSaveCheckbox
              key={`${type.name}-${option.name}`}
              label={t(`communication_type.${option.name.toLowerCase()}`)}
              initialCheckedValue={option.selected}
              onSave={(newValue: boolean) =>
                handleSaveCommunicationType(option, newValue)
              }
              disabled={!type.enabled}
            />
          );
        })}
      </div>
    </Card>
  );
};
