import { usePortalTranslation } from '@hooks/use-portal-translation';

import type { Payload } from '@api/sites/use-update-site-load-management';
import { DetailLine } from '@components/DetailsLine';
import InfoIcon from '@icons/info_16.svg';
import { isValidPositiveNumber, max } from '@utils/input-utils';

import { AutomaticSaveInput, Tooltip } from '@destination/components';

export interface ISiteAuxiliaryCurrent {
  value: number | null;
  maxValue: number;
  isDisabled?: boolean;
  onSave: (payload: Payload) => Promise<void>;
}

export const SiteAuxiliaryCurrent = ({
  value,
  maxValue,
  isDisabled,
  onSave
}: ISiteAuxiliaryCurrent) => {
  const { t, t_generic } = usePortalTranslation('loadManagement');

  let className = 'items-baseline [&_strong]:text-[14px] [&>div]:w-[189px]';
  if (isDisabled) className += ' text-[#BABABA]';

  const infoIcon = (
    <Tooltip message="More info coming soon...">
      <InfoIcon data-testid="info-icon" className="path-fill-[#BABABA]" />
    </Tooltip>
  );

  const handleSave = async (value: string) => {
    const payload: Payload = {
      staticLoadManagement: { siteAuxiliaryCurrent: Number(value) }
    };

    await onSave(payload);
  };

  const validateInput = (value: string) =>
    isValidPositiveNumber(value) &&
    max(maxValue)(value) &&
    /^\d+(\.\d)?$/.test(value);

  const label = t('literals.site_auxiliary_current', { titleize: true });
  const helperText = t('labels.max_value', {
    titleize: true,
    maxValue,
    unit: 'A'
  });
  const invalidHelperText = t_generic('errors.invalid_input', {
    titleize: true
  });
  const errorHelperText = t_generic('errors.failed_to_save', {
    titleize: true,
    entity: t('literals.site_auxiliary_current')
  });

  return (
    <DetailLine icon={infoIcon} label={label} className={className}>
      <AutomaticSaveInput
        id="site-auxiliary-current"
        data-testid="site-auxiliary-current"
        initialValue={value ? value.toString() : undefined}
        disabled={isDisabled}
        validator={validateInput}
        helperText={helperText}
        invalidHelperText={invalidHelperText}
        errorHelperText={errorHelperText}
        onSave={handleSave}
        suffixText={<strong data-testid="unit">A</strong>}
      />
    </DetailLine>
  );
};
