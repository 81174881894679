import useSWR from 'swr';

import { GATEWAY_BY_ID_PATH } from '@api/paths';

import { PaginatedEventLogRecord } from '@omnis-pulse-types';

export default function useGatewayEventLogs(gatewayId?: string) {
  const { data, isLoading, error } = useSWR<PaginatedEventLogRecord>(
    gatewayId
      ? `${GATEWAY_BY_ID_PATH(gatewayId)}/events?pageOffset=0&pageSize=5`
      : null
  );

  return { eventLogs: data?.data, isLoading, isError: Boolean(error) };
}
