import { randChanceBoolean, randNumber } from '@ngneat/falso';
import { eachHourOfInterval, startOfToday } from 'date-fns';

import { SiteChargerCurrentDeliveredTelemetry } from '@omnis-pulse-types';

export class SiteCurrentDeliveredTelemetryMockFactory {
  static createSiteCurrentDeliveredTelemetry(): SiteChargerCurrentDeliveredTelemetry[] {
    const timeSeries = eachHourOfInterval({
      start: startOfToday(),
      end: new Date()
    });

    let gridLimit = randNumber({ min: 60, max: 120 });

    let residualLoadL1 = randNumber({ min: 0, max: 40 });
    let residualLoadL2 = randNumber({ min: 0, max: 40 });
    let residualLoadL3 = randNumber({ min: 0, max: 40 });

    let currentSumL1 = randNumber({ min: residualLoadL1, max: gridLimit });
    let currentSumL2 = randNumber({ min: residualLoadL2, max: gridLimit });
    let currentSumL3 = randNumber({ min: residualLoadL3, max: gridLimit });

    const chargersConsumptionL1 = randNumber({ min: 0, max: 40 });
    const chargersConsumptionL2 = randNumber({ min: 0, max: 40 });
    const chargersConsumptionL3 = randNumber({ min: 0, max: 40 });

    const currentDeliveredTelemetries: SiteChargerCurrentDeliveredTelemetry[] =
      [];

    timeSeries.forEach(date => {
      if (randChanceBoolean({ chanceTrue: 0.1 })) {
        gridLimit = randNumber({ min: 60, max: 120 });
      }

      if (randChanceBoolean({ chanceTrue: 0.25 })) {
        residualLoadL1 = randNumber({ min: 0, max: 40 });
        currentSumL1 = randNumber({ min: residualLoadL1, max: gridLimit });
      }
      if (randChanceBoolean({ chanceTrue: 0.25 })) {
        residualLoadL2 = randNumber({ min: 0, max: 40 });
        currentSumL2 = randNumber({ min: residualLoadL2, max: gridLimit });
      }
      if (randChanceBoolean({ chanceTrue: 0.25 })) {
        residualLoadL3 = randNumber({ min: 0, max: 40 });
        currentSumL3 = randNumber({ min: residualLoadL3, max: gridLimit });
      }

      if (randChanceBoolean({ chanceTrue: 0.25 })) {
        currentSumL1 = randNumber({ min: residualLoadL1, max: gridLimit });
      }
      if (randChanceBoolean({ chanceTrue: 0.25 })) {
        currentSumL2 = randNumber({ min: residualLoadL2, max: gridLimit });
      }
      if (randChanceBoolean({ chanceTrue: 0.25 })) {
        currentSumL3 = randNumber({ min: residualLoadL3, max: gridLimit });
      }

      currentDeliveredTelemetries.push({
        timestamp: date.toISOString(),
        gridLimit,
        currentSum: {
          l1: currentSumL1,
          l2: currentSumL2,
          l3: currentSumL3
        },
        residualLoad: {
          l1: residualLoadL1,
          l2: residualLoadL2,
          l3: residualLoadL3
        },
        chargersConsumption: {
          l1: chargersConsumptionL1,
          l2: chargersConsumptionL2,
          l3: chargersConsumptionL3
        }
      });
    });

    return currentDeliveredTelemetries;
  }
}
