import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import useCharger from '@api/chargers/use-charger';
import { CouldNotLoadData } from '@components/ui/CouldNotLoadData';

import {
  IOption,
  LoadingSkeleton,
  Select,
  Typography
} from '@destination/components';

import { Device } from '@omnis-pulse-types';

import { ChargerStatus } from './ChargerStatus';

export interface IChargerDetails {
  chargers: Device[];
}

export const ChargerDetails = ({ chargers }: IChargerDetails) => {
  const { chargerId } = useParams();
  const navigate = useNavigate();

  const { t } = usePortalTranslation();

  const { charger, isLoading, isError, mutate } = useCharger(chargerId);

  const options: IOption[] = chargers
    .filter(charger => charger.id && charger.name)
    .map(charger => ({
      value: String(charger.id),
      label: String(charger.name)
    }));

  let content = (
    <Typography variant="sectionTitle">
      {t('literals.charger', { titleize: true })}
    </Typography>
  );

  if (options.length > 1) {
    const selectedOption = options.find(
      option => String(option.value) === chargerId
    );

    content = (
      <Select
        options={options}
        selected={selectedOption ?? options[0]}
        onChange={option => navigate(`${option.value}`)}
      />
    );
  }

  return (
    <>
      <div className="mb-[17px] mt-2 flex flex-col justify-between gap-6 md:flex-row [&>div:first-child]:w-[250px]">
        {content}
        {!isError && charger && <ChargerStatus charger={charger} />}
      </div>
      {isLoading && <LoadingSkeleton className="rounded-[10px]" height={431} />}
      {isError && <CouldNotLoadData className="m-auto" mutate={mutate} />}
      {charger && <Outlet context={charger} />}
    </>
  );
};
