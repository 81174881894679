import useSWRInfinite from 'swr/infinite';

import { SITE_PATH } from '@api/paths';
import { defaultPageSize, hasReachedEnd } from '@utils/infinite-loading-utils';

const useSites = (searchParams?: URLSearchParams, isPaginatedFetch = true) => {
  const params = new URLSearchParams();
  if (isPaginatedFetch) {
    params.append('pageSize', defaultPageSize);
  }

  if (searchParams?.toString().length) {
    searchParams.forEach((value, key) => params.append(key, value));
  }

  const {
    data: responses,
    error,
    isLoading,
    size: pageSize,
    setSize: setPageSize,
    mutate
  } = useSWRInfinite(
    index => {
      if (params.get('pageOffset')) {
        params.set('pageOffset', index.toString());
      } else {
        params.append('pageOffset', index.toString());
      }
      return `${SITE_PATH}?${params.toString()}`;
    },
    { revalidateFirstPage: false }
  );

  return {
    sites: (responses ?? []).map(response => response.data).flat(),
    isLoading,
    isLoadingMore: responses ? responses.length < pageSize : false,
    hasReachedEnd: responses
      ? hasReachedEnd(responses, Number(defaultPageSize))
      : false,
    pageSize,
    setPageSize,
    isError: Boolean(error),
    mutate
  };
};

export default useSites;
