import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useSiteChargers from '@api/sites/use-site-chargers';
import { donutChartConfig } from '@utils/chart-utils';

import {
  ConnectionStatusEnum,
  EvseStatusEnum,
  SocketStatusEnum
} from '@omnis-pulse-types';

import { StatusOverview } from './StatusOverview';

export const ChargersStateOverview = () => {
  const { id } = useParams();

  const { t, t_generic } = usePortalTranslation('chargers');

  const { chargers, isLoading } = useSiteChargers(id);

  let totalChargers = 0;
  let offlineChargers = 0;
  let inErrorChargers = 0;

  let totalOutlets = 0;
  let chargingOutlets = 0;
  let availableOutlets = 0;
  let inErrorOutlets = 0;
  let offlineOutlets = 0;

  if (chargers) {
    totalChargers = chargers.length;

    offlineChargers = chargers.filter(
      charger =>
        charger.connectionStatus.status === ConnectionStatusEnum.NOT_CONNECTED
    ).length;

    inErrorChargers = chargers.filter(charger =>
      charger.evseStatuses.some(
        evseStatus => evseStatus.status === EvseStatusEnum.ERROR
      )
    ).length;

    chargers.forEach(charger => {
      charger.evseStatuses.forEach(evseStatus => {
        totalOutlets += evseStatus.sockets.length;
        if (
          charger.connectionStatus.status === ConnectionStatusEnum.NOT_CONNECTED
        ) {
          offlineOutlets += evseStatus.sockets.length;
        } else {
          chargingOutlets += evseStatus.sockets.filter(
            socketStatus => socketStatus.status === SocketStatusEnum.CHARGING
          ).length;

          availableOutlets += evseStatus.sockets.filter(
            socketStatus => socketStatus.status === SocketStatusEnum.AVAILABLE
          ).length;

          inErrorOutlets += evseStatus.sockets.filter(
            socketStatus => socketStatus.status === SocketStatusEnum.FAULT
          ).length;
        }
      });
    });
  }

  return (
    <div className="flex gap-6">
      <StatusOverview
        dataTestId="charger-state-donut-chart"
        isLoading={isLoading}
        title={t_generic('labels.entity_state', {
          entity: t_generic('literals.charger_other')
        })}
        description={t('amount_found', { count: totalChargers })}
        donutCharts={[
          {
            ...donutChartConfig(
              totalChargers,
              inErrorChargers,
              '#F03040',
              t_generic('states.error')
            ),
            'data-testid': 'charger-state-error'
          },
          {
            ...donutChartConfig(
              totalChargers,
              offlineChargers,
              '#868686',
              t_generic('states.offline')
            ),
            'data-testid': 'charger-state-offline'
          }
        ]}
      />
      <StatusOverview
        dataTestId="charger-state-donut-chart"
        isLoading={isLoading}
        title={t_generic('labels.entity_state', {
          entity: t('literals.outlet_other')
        })}
        description={t('amount_outlets_online', {
          online: totalOutlets - offlineOutlets,
          count: totalOutlets
        })}
        donutCharts={[
          {
            ...donutChartConfig(
              totalOutlets,
              inErrorOutlets,
              '#F03040',
              t_generic('states.error')
            ),
            'data-testid': 'outlet-state-error'
          },
          {
            ...donutChartConfig(
              totalOutlets,
              chargingOutlets,
              '#0052FF',
              t_generic('states.charging')
            ),
            'data-testid': 'outlet-state-charging'
          },
          {
            ...donutChartConfig(
              totalOutlets,
              availableOutlets,
              '#0CA919',
              t_generic('states.available')
            ),
            'data-testid': 'outlet-state-available'
          }
        ]}
      />
    </div>
  );
};
