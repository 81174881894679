import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useSiteDevices from '@api/sites/use-site-devices';
import { ChargersOverview } from '@components/charger/overview/ChargersOverview';
import { ChargersStateOverview } from '@components/sites/site/overview/ChargersStateOverview';
import { GatewayCard } from '@components/sites/site/overview/GatewayCard';
import { GridMeterCard } from '@components/sites/site/overview/GridMeterCard';
import { SiteOverview } from '@components/sites/site/overview/SiteOverview';
import ChargerIcon from '@icons/charger_96.svg';

import {
  DataError,
  NothingConfigured,
  Typography
} from '@destination/components';

export const Overview = () => {
  const { id } = useParams();

  const { t, t_generic } = usePortalTranslation('sites');

  const { devices, isLoading, isError, mutate } = useSiteDevices(id);

  if (isLoading) return null;

  if (isError) {
    const handleRefresh = () => mutate(undefined, true);
    return (
      <div className="flex h-full w-full items-center">
        <DataError variant="primary" onRefresh={handleRefresh} />
      </div>
    );
  }

  const hasCharger = devices && devices.chargers.length > 0;
  const hasGridMeter = devices && devices.gridMeters.length > 0;
  const hasGateway = devices && devices.gateways.length > 0;

  const hasNoDevices = !hasCharger && !hasGridMeter && !hasGateway;

  // If there are no devices, show a message to the user
  if (hasNoDevices) {
    return (
      <NothingConfigured
        title={t_generic('labels.no_configured', {
          entity: t_generic('literals.device_other')
        })}
        className="mt-32"
        icon={<ChargerIcon />}
      >
        <Typography className="max-w-[263px] text-center" variant="description">
          {t('once_configured.devices')}
        </Typography>
      </NothingConfigured>
    );
  }

  return (
    <>
      <div className="mb-12 flex h-[360px] gap-6">
        <div className="flex min-w-[494px] flex-col justify-between">
          {hasCharger && <ChargersStateOverview />}
          <div>
            {(hasGridMeter || hasGateway) && (
              <Typography variant="sectionTitle" className="pb-4 capitalize">
                {t_generic('literals.device_other')}
              </Typography>
            )}
            <div className="flex gap-6">
              {hasGridMeter && <GridMeterCard />}
              {hasGateway && <GatewayCard />}
            </div>
          </div>
        </div>
        {hasGridMeter && <SiteOverview />}
      </div>
      {hasCharger && <ChargersOverview />}
    </>
  );
};
