import { ReactNode, useState } from 'react';

import {
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer
} from 'recharts';

interface IComposedGraph {
  children: ReactNode;
  onZoom: (refAreaLeft: number, refAreaRight: number) => void;
  onResetZoom: () => void;
}

export const ComposedGraph = ({
  children,
  onZoom,
  onResetZoom
}: IComposedGraph) => {
  const [refAreaLeft, setRefAreaLeft] = useState<string>();
  const [refAreaRight, setRefAreaRight] = useState<string>();

  const handleMouseUp = () => {
    if (
      refAreaLeft === refAreaRight ||
      refAreaLeft === undefined ||
      refAreaRight === undefined
    ) {
      onResetZoom();
      setRefAreaLeft(undefined);
      setRefAreaRight(undefined);
      return;
    }

    onZoom(Number(refAreaLeft), Number(refAreaRight));

    setRefAreaLeft(undefined);
    setRefAreaRight(undefined);
  };

  return (
    <ResponsiveContainer height="100%" width="100%">
      <ComposedChart
        className="select-none"
        onMouseDown={event => setRefAreaLeft(event.activeLabel)}
        onMouseMove={event => refAreaLeft && setRefAreaRight(event.activeLabel)}
        onMouseUp={handleMouseUp}
      >
        <CartesianGrid strokeDasharray="4 4" stroke="#ebebeb" />
        {children}
        {refAreaLeft && refAreaRight ? (
          <ReferenceArea
            x1={refAreaLeft}
            x2={refAreaRight}
            strokeOpacity={0.3}
          />
        ) : null}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
