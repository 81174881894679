import { useOmnisRedirectUrl } from '@hooks/use-omnis-redirect-url';
import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Link } from 'react-router-dom';

import { Button, Tooltip, Typography } from '@destination/components';

export const OmnisRedirectButton = () => {
  const { t, t_generic } = usePortalTranslation('accessManagement');

  const actions = t('manage_access_tooltip_actions', {
    returnObjects: true
  });

  const { data: redirectUrl } = useOmnisRedirectUrl();

  const tooltipMessage = (
    <div className="max-w-[267px] break-words">
      <Typography variant="smallTitle" className="mb-1">
        {t('tooltips.redirect_to_omnis_portal')}
      </Typography>
      <div className="whitespace-pre-line text-sm leading-[140%]">
        {t('tooltips.all_actions')}
        <ul className="my-1 flex list-disc flex-col gap-1 pl-4">
          {Array.isArray(actions) &&
            actions.map(action => (
              <li key={`tooltip-action-${action}`}>{action}</li>
            ))}
        </ul>
        {t('tooltips.conducted_in_omnis_portal')}
      </div>
    </div>
  );

  return (
    <Tooltip message={tooltipMessage} placement="bottom-left">
      <Link to={redirectUrl ?? ''} target="_blank" rel="noopener noreferrer">
        <Button
          variant="primary"
          className="!px-3 !py-2"
          data-testid="manage-access-button"
        >
          {t_generic('buttons.manage', { entity: t('access'), titleize: true })}
        </Button>
      </Link>
    </Tooltip>
  );
};
