import { useState } from 'react';

import { startOfToday, startOfYear, subDays } from 'date-fns';

export type dateFilterOption =
  | 'today'
  | 'last7days'
  | 'last30days'
  | 'year'
  | 'allTime';

export function useDateFilter() {
  const now = new Date();

  const [selectedFilter, setSelectedFilter] =
    useState<dateFilterOption>('today');
  const [fromTimestamp, setFromTimestamp] = useState<undefined | string>(
    startOfToday().toISOString()
  );
  const [toTimestamp, setToTimestamp] = useState<undefined | string>(
    now.toISOString()
  );

  const handleFilterChange = (filter: dateFilterOption) => {
    setSelectedFilter(filter);

    switch (filter) {
      case 'today':
        setFromTimestamp(startOfToday().toISOString());
        setToTimestamp(now.toISOString());
        break;
      case 'last7days':
        setFromTimestamp(subDays(now, 7).toISOString()); // 7 days ago
        setToTimestamp(now.toISOString());
        break;
      case 'last30days':
        setFromTimestamp(subDays(now, 30).toISOString()); // 30 days ago
        setToTimestamp(now.toISOString());
        break;
      case 'year':
        setFromTimestamp(startOfYear(now).toISOString());
        setToTimestamp(now.toISOString());
        break;
      case 'allTime':
        setFromTimestamp(undefined);
        setToTimestamp(undefined);
        break;
      default:
        break;
    }
  };

  return {
    selectedFilter,
    fromTimestamp,
    toTimestamp,
    handleFilterChange
  };
}
