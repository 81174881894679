import { useInsightsContext } from '@context/InsightsContext';

import { DatePicker, TimeRangePicker } from '@destination/components';

type TimeRange = [Date, Date] | [undefined, undefined];

export interface IDateTimeFilterButtons {
  isLoading: boolean;
}

export const DateTimeFilterButtons = ({
  isLoading
}: IDateTimeFilterButtons) => {
  const {
    date,
    timeRange,
    timeRangePickerKey,
    setDate,
    setTimeRange,
    setTimeRangePickerKey
  } = useInsightsContext();

  const handleChangeTimeRange = (range: TimeRange, isQuickAction?: boolean) => {
    if (isQuickAction) {
      setDate(new Date());
    }

    setTimeRange(range);
  };

  const handleChangeDate = (newDate: Date | undefined) => {
    setDate(newDate ?? new Date());
    setTimeRangePickerKey(previousValue => previousValue + 1);
  };

  return (
    <div className="mb-6 flex gap-3">
      <DatePicker
        key={date.getTime()}
        initialDate={date}
        isDisabled={isLoading}
        onChange={handleChangeDate}
      />
      <TimeRangePicker
        key={timeRangePickerKey}
        initialTimeRange={timeRange}
        isDisabled={isLoading}
        onChange={handleChangeTimeRange}
      />
    </div>
  );
};
