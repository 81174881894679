import { Label, Pie, PieChart } from 'recharts';
import { PolarViewBox } from 'recharts/types/util/types';

import { IChartDataItem } from '@models/Charts';

import { Typography } from '@destination/components';

export interface IDonutChart {
  data: IChartDataItem[];
  restValue: number;
  title: string;
  'data-testid'?: string;
}

const DonutChart = ({
  data,
  restValue,
  title,
  'data-testid': dataTestId
}: IDonutChart) => {
  const label = data[0].value.toString();
  data = [{ value: restValue, fill: '#ebebeb' }, ...data];
  return (
    <div>
      <PieChart width={60} height={60}>
        <Pie
          data={data}
          innerRadius={24}
          outerRadius={29}
          dataKey="value"
          startAngle={-280}
          paddingAngle={-10}
          cornerRadius={40}
          fill={'#ebebeb'}
          blendStroke={true}
        >
          <Label
            position="center"
            content={props => (
              <CustomizedLabel
                data-testid={dataTestId ?? title}
                viewBox={props.viewBox as PolarViewBox}
                label={label}
              />
            )}
          ></Label>
        </Pie>
      </PieChart>
      <Typography className="mt-2 text-center first-letter:uppercase">
        {title}
      </Typography>
    </div>
  );
};

const CustomizedLabel = ({
  viewBox,
  label,
  'data-testid': dataTestId
}: {
  label: string;
  viewBox: PolarViewBox | undefined;
  'data-testid': string;
}) => {
  return (
    <text textAnchor="middle" x={viewBox?.cx} y={(viewBox?.cy ?? 0) + 8}>
      <tspan data-testid={dataTestId} fontSize={24}>
        {label}
      </tspan>
    </text>
  );
};

export default DonutChart;
