import { useTranslation } from 'react-i18next';

import { DetailLine } from '@components/DetailsLine';
import { authSourceMapper } from '@utils/auth-source-mapper';
import {
  dateFormat,
  formatDate,
  formatDurationInHoursAndMinutes,
  timeFormat
} from '@utils/date-utils';
import { getSocketTypeIcons } from '@utils/socket-utils';

import {
  ChargerActiveSession,
  EvseStatus,
  SocketTypeEnum
} from '@omnis-pulse-types';

interface EvseSessionDetailsProps {
  evse: EvseStatus;
  session: ChargerActiveSession;
}

export const EvseSessionDetails = ({
  evse,
  session
}: EvseSessionDetailsProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix:
      'sites.site.site_overview.chargers_overview_table.charger_session_drawer'
  });

  const activeSocket = evse.sockets.find(
    socket => socket.id == session.connectorId
  );

  return (
    <div className="mt-4">
      <DetailLine label={t('evse')} className="mb-4">
        <span className="flex text-base font-bold">
          {activeSocket &&
            (activeSocket.type === SocketTypeEnum.UNUSED
              ? SocketTypeEnum.UNKNOWN
              : activeSocket.type)}
          {activeSocket && getSocketTypeIcons(activeSocket.type)}
        </span>
      </DetailLine>
      <DetailLine label={t('authentication')} className="mb-4">
        <span className="text-base font-bold">
          {authSourceMapper(session.authorizationSource)}
        </span>
      </DetailLine>
      <DetailLine label={t('start_date')} className="mb-4">
        <span className="text-base font-bold">
          {formatDate(session.startTime, dateFormat)}
        </span>
      </DetailLine>
      <DetailLine label={t('time')} className="mb-4">
        <span className="text-base font-bold">
          {formatDate(session.startTime, timeFormat)}-...
        </span>
      </DetailLine>
      <DetailLine label={t('duration')} className="mb-4">
        <span className="text-base font-bold">
          {formatDurationInHoursAndMinutes(
            Date.now(),
            new Date(session.startTime).getTime()
          )}
        </span>
      </DetailLine>
      <DetailLine label={t('energy_delivered')} className="mb-4">
        <span className="text-base font-bold">{`${session.energy} kWh`}</span>
      </DetailLine>
      <DetailLine label={t('power_delivered')} className="mb-4">
        <span className="text-base font-bold">{`${session.power} kW`}</span>
      </DetailLine>
    </div>
  );
};
