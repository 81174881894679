import { OrganizationDetails } from '@omnis-pulse-types';

export enum Action {
  READ = 'read',
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete',
  INVITE = 'invite',
  VERIFY = 'verify',
  VIEW = 'view'
}

export enum ResourceType {
  SITE = 'Site',
  INSTALLED_CHARGER = 'InstalledCharger',
  CLAIMED_CHARGER = 'ClaimedCharger',
  USER = 'User',
  CHARGER = 'Charger'
}

export type Resource = OrganizationDetails;
