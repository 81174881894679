import { rand, randFullAddress, randNumber, randUuid } from '@ngneat/falso';

import { countryCodes } from '@constants/countryCodes';
import { makeArray } from '@utils/faker-utils';

import { Site } from '@omnis-pulse-types';

export class SiteMockFactory {
  static createSite(data?: Partial<Site>): Site {
    //due to browser implementation of Intl.displayNames we can't currently test with Macao because it has different names depending on the browser
    const allCountryCodesExceptMO = countryCodes.filter(
      countryCode =>
        countryCode !== 'MO' && countryCode !== 'HK' && countryCode !== 'PS'
    );
    return {
      id: randUuid(),
      label: `Site ${randNumber()}`,
      address: {
        addressLine: randFullAddress(),
        countryCode: rand(allCountryCodesExceptMO)
      },
      organizationId: randUuid(),
      ...data
    };
  }

  static createSites(numberOfSites: number, data?: Partial<Site>) {
    //due to browser implementation of Intl.displayNames we can't currently test with Macao because it has different names depending on the browser
    const stillAvailableCountryCodes = countryCodes.filter(
      countryCode =>
        countryCode !== 'MO' && countryCode !== 'HK' && countryCode !== 'PS'
    );
    return makeArray(numberOfSites, () => {
      const uniqueCountryCode = this.generateUniqueCountryCode(
        stillAvailableCountryCodes
      );
      return this.createSite({
        address: {
          addressLine: randFullAddress(),
          countryCode: uniqueCountryCode
        },
        ...data
      });
    });
  }
  private static generateUniqueCountryCode(
    stillAvailableCountryCodes: string[]
  ): string {
    const randomIndex = randNumber({
      min: 0,
      max: stillAvailableCountryCodes.length - 1
    });
    const countryCode = stillAvailableCountryCodes[randomIndex];
    stillAvailableCountryCodes.splice(randomIndex, 1);
    return countryCode;
  }
}
