import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import createSite from '@api/sites/create-site';
import useSites from '@api/sites/use-sites';
import { DetailLine } from '@components/DetailsLine';
import { countryCodes } from '@constants/countryCodes';
import { isNotEmpty } from '@utils/input-utils';
import { getCountryName } from '@utils/utils';

import {
  Card,
  Input,
  IOption,
  Select,
  Typography,
  useAlert,
  useInput
} from '@destination/components';
import { IUseAlert } from '@destination/components/dist/hooks/useAlert/useAlert';

import { CreateSiteButtons } from './CreateSiteButtons';

const COUNTRY_OPTIONS: IOption[] = countryCodes.map((countryCode): IOption => {
  const countryName = getCountryName('en-US', countryCode);
  return { label: countryName ?? 'unknown', value: countryCode };
});

export const CreateSiteForm = () => {
  const [selectedCountry, setSelectedCountry] = useState<IOption | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation(undefined, { keyPrefix: 'sites.create' });

  const { mutate } = useSites();
  const { notify } = useAlert();
  const {
    value: nameValue,
    isValid: nameIsValid,
    isTouched: nameIsTouched,
    valueChangeHandler: nameValueChangeHandler
  } = useInput(isNotEmpty);

  const { value: addressValue, valueChangeHandler: addressValueChangeHandler } =
    useInput();

  const handleCreateSite = async () => {
    setIsCreating(true);

    const { error } = await createSite({
      label: nameValue,
      countryCode: selectedCountry?.value as string,
      address: addressValue
    });

    const notification: IUseAlert = {
      header: error ? t('not_created') : t('created'),
      variant: error ? 'error' : 'success',
      'data-testid': error ? 'fail-message' : 'success-message'
    };

    notify(notification);

    if (!error) {
      await mutate();
      navigate('/sites');
    }

    setIsCreating(false);
  };

  const isFormValid = nameIsTouched && nameIsValid && selectedCountry !== null;

  return (
    <>
      <Card
        data-testid="create-form"
        className="max-w-[620px] [&>div:nth-child(even)]:rounded-[4px] [&>div:nth-child(even)]:bg-[#FAFAFA]"
      >
        <Typography variant="smallTitle" className="mb-4">
          {t('form_title')}
        </Typography>
        <DetailLine
          label={`${t('form_label.name')}*`}
          className="py-2 pl-3 pr-[18px]"
        >
          <Input
            id="name"
            data-testid="name-input"
            value={nameValue}
            error={!nameIsValid}
            disabled={isCreating}
            onChange={event => nameValueChangeHandler(event.target.value)}
          />
        </DetailLine>
        <DetailLine
          label={`${t('form_label.country')}*`}
          className="py-2 pl-3 pr-[18px]"
        >
          <Select
            data-testid="country-input"
            enableSearch
            options={COUNTRY_OPTIONS}
            selected={selectedCountry}
            disabled={isCreating}
            onChange={option => setSelectedCountry(option)}
          />
        </DetailLine>
        <DetailLine
          label={t('form_label.address')}
          className="py-2 pl-3 pr-[18px]"
        >
          <Input
            id="address"
            data-testid="address-input"
            value={addressValue}
            disabled={isCreating}
            onChange={event => addressValueChangeHandler(event.target.value)}
          />
        </DetailLine>
      </Card>
      <CreateSiteButtons
        isCreating={isCreating}
        disabledCreateButton={!isFormValid}
        onCreateButtonClick={handleCreateSite}
      />
    </>
  );
};
