import { useTranslation } from 'react-i18next';

import { LoadManagementSettings } from '@components/sites/site/load-management/LoadManagementSettings';
import { SiteLoadManagementProvider } from '@context/SiteLoadManagementContext';
import { titleize } from '@utils/i18n-utils';

import { Typography } from '@destination/components';

export const LoadManagement = () => {
  const { t } = useTranslation('generic');

  const title = titleize(
    t('labels.entity_settings', {
      entity: t('literals.load_management')
    })
  );

  return (
    <section>
      <Typography variant="sectionTitle" className="mb-4">
        {title}
      </Typography>
      <SiteLoadManagementProvider>
        <LoadManagementSettings />
      </SiteLoadManagementProvider>
    </section>
  );
};
