import { useOutletContext } from 'react-router-dom';

import { ChargerEventLog } from '@components/chargers/ChargerEventLogs';
import { ChargerInformation } from '@components/chargers/ChargerInformation';
import { ChargerSessionItem } from '@components/chargers/ChargerSessionItem';

import { ChargerDetails } from '@omnis-pulse-types';

export const Charger = () => {
  const charger = useOutletContext<ChargerDetails>();

  return (
    <>
      <ChargerSessionItem chargerId={charger.id} />
      <ChargerInformation charger={charger} />
      <ChargerEventLog chargerId={charger.id} />
    </>
  );
};
