import { useDateFilter } from '@hooks/use-date-filter';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useCharger from '@api/chargers/use-charger';
import useChargerEventLog from '@api/chargers/use-charger-event-log';
import { DateTimeFilterButtons } from '@components/buttons/DateTimeFilterButtons';
import { InfiniteScrollTable } from '@components/InfiniteScrollTable';
import { TabLabel } from '@components/TabLabel';
import { LogsTable } from '@components/table/logs/LogsTable';

import { Card, LoadingSkeleton, Typography } from '@destination/components';

export const Logs = () => {
  const { chargerId = '' } = useParams() as { chargerId: string };
  const { selectedFilter, fromTimestamp, toTimestamp, handleFilterChange } =
    useDateFilter();

  const params = new URLSearchParams();

  if (fromTimestamp) params.append('from', fromTimestamp.toString());
  if (toTimestamp) params.append('to', toTimestamp.toString());

  const { charger, isLoading: isLoadingCharger } = useCharger(chargerId);
  const {
    logs,
    isLoading: isLoadingEvents,
    isError,
    setPageSize,
    isLoadingMore,
    hasReachedEnd
  } = useChargerEventLog(chargerId, true, params);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site.devices.charger.logs'
  });

  return (
    <>
      {charger && <TabLabel label={t('title', { charger: charger?.name })} />}
      <div className="mb-8">
        {isLoadingCharger ? (
          <LoadingSkeleton
            className="w-full max-w-sm !bg-[#E6E6E6]"
            height={30.4}
          />
        ) : (
          <Typography variant="pageTitle">
            {t('title', { charger: charger?.name })}
          </Typography>
        )}
      </div>
      <DateTimeFilterButtons
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
      />
      <Card>
        <InfiniteScrollTable
          dataLength={logs?.length ?? 0}
          onNextPage={() => setPageSize(size => size + 1)}
          isLoadingMore={isLoadingMore}
          hasReachedEnd={hasReachedEnd}
        >
          <LogsTable
            logs={logs}
            isLoading={isLoadingEvents}
            isError={isError}
          />
        </InfiniteScrollTable>
      </Card>
    </>
  );
};
