import { usePortalTranslation } from '@hooks/use-portal-translation';

import { HMICustomizationDrawer } from '@components/hmi/drawer/HMICustomizationDrawer';
import { HMICustomization } from '@components/hmi/HMICustomization';

export const Customer = () => {
  const { t } = usePortalTranslation('hmi');

  const title = t('customizations.customer.title');
  const description = t('customizations.customer.description');

  return (
    <>
      <HMICustomization
        documentType="CustomerPreferences"
        title={title}
        description={description}
        uploadLink="upload"
        data-testid="customer-settings"
      />
      <HMICustomizationDrawer closeDrawerLink="/hmi-settings/charger-customization/customer" />
    </>
  );
};
