export const isNotEmpty = (value: string) => value.trim() !== '';

export const isDifferent = (value: string, initial: string) =>
  value !== initial;

export const minLength = (min: number) => (value: string) =>
  value.trim().length >= min;

export const notNegative = (value: string) => Number(value) >= 0;
export const greaterThanZero = (value: string) => Number(value) > 0;

export const min = (min: number) => (value: string) => min <= Number(value);
export const max = (max: number) => (value: string) => Number(value) <= max;

export function isValidDateRange(startDate: Date, endDate: Date): boolean {
  return startDate < endDate;
}

export function isValidPositiveNumber(value: string): boolean {
  return notNegative(value) && isNotEmpty(value);
}
