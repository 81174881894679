import { usePortalTranslation } from '@hooks/use-portal-translation';
import { KeyedMutator } from 'swr';

import useChargerActiveSessions from '@api/chargers/use-charger-active-session';
import useChargerFinishedSessions from '@api/chargers/use-charger-finished-sessions';
import { NavigationButton } from '@components/buttons/NavigationButton';
import { ChargerSessionTable } from '@components/charger/ChargerSessionTable';

import { DataError, Typography } from '@destination/components';

import {
  ChargerActiveSession,
  ChargerFinishedSession,
  PaginatedChargerFinishedSession
} from '@omnis-pulse-types';

const SessionHeader = ({ titleKey }: { titleKey: string }) => {
  const { t } = usePortalTranslation('chargers');
  return (
    <Typography variant="sectionTitle" className="mb-5 mt-[15px]">
      {t('literals.' + titleKey, { titleize: true })}
    </Typography>
  );
};

type sessions = ChargerActiveSession[] | ChargerFinishedSession[];
type mutator =
  | KeyedMutator<ChargerActiveSession[]>
  | KeyedMutator<PaginatedChargerFinishedSession>;

interface ISessionContent {
  sessions: sessions;
  isError: boolean;
  isLoading: boolean;
  mutate: mutator;
  titleKey: string;
}

const SessionContent = ({
  sessions,
  isError,
  isLoading,
  mutate,
  titleKey
}: ISessionContent) => {
  if (isError) {
    return (
      <>
        <SessionHeader titleKey={titleKey} />
        <DataError onRefresh={mutate} />
      </>
    );
  }

  return (
    <>
      <SessionHeader titleKey={titleKey} />
      <ChargerSessionTable
        sessions={sessions ?? []}
        isLoading={isLoading}
        isSingleLoadingRow={true}
      />
      <SeeAllSessionLink />
    </>
  );
};

export const ChargerSessionItem = ({ chargerId }: { chargerId: string }) => {
  const { sessions, isLoading, isError, mutate } =
    useChargerActiveSessions(chargerId);

  if (sessions && sessions.length === 0) {
    return <LastFinishedChargerSession chargerId={chargerId} />;
  }

  return (
    <SessionContent
      sessions={sessions ?? []}
      isError={isError}
      isLoading={isLoading}
      mutate={mutate}
      titleKey="ongoing_sessions"
    />
  );
};

const LastFinishedChargerSession = ({ chargerId }: { chargerId: string }) => {
  const { sessions, isError, isLoading, mutate } = useChargerFinishedSessions(
    chargerId,
    undefined,
    1
  );

  return (
    <SessionContent
      sessions={sessions ?? []}
      isError={isError}
      isLoading={isLoading}
      mutate={mutate}
      titleKey="last_session"
    />
  );
};

const SeeAllSessionLink = () => {
  const { t } = usePortalTranslation();

  return (
    <div className="mt-3 flex w-full justify-end">
      <NavigationButton
        label={t('buttons.see_all', {
          entity: t('literals.session_other'),
          titleize: true
        })}
        to={'session-history'}
      />
    </div>
  );
};
