export const defaultPageSize = '10';

export const hasReachedEnd = (
  responses: { data: { data: [] | object }[]; pageOffset: number }[],
  pageSize: number
) => {
  if (!responses || responses.length === 0) {
    return true;
  }

  const lastResponse = responses[responses.length - 1];
  if (!lastResponse?.data || !Array.isArray(lastResponse.data)) {
    return true;
  }

  return lastResponse.data.length < pageSize;
};
