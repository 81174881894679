import { useMemo } from 'react';

import useSWR from 'swr';

import { CHARGERS_BY_SITE_ID_PATH } from '@api/paths';
import { IDataResponse } from '@models/Responses';

import { Charger } from '@omnis-pulse-types';

const useSiteChargers = (siteId?: string, searchParams?: URLSearchParams) => {
  const { data, error, isLoading, mutate } = useSWR<IDataResponse<Charger>>(
    siteId ? CHARGERS_BY_SITE_ID_PATH(siteId) : null
  );

  const filteredChargers = useMemo(() => {
    const params = new URLSearchParams(searchParams);

    return data?.data?.filter(charger => {
      const searchParam = params.get('search');

      if (searchParam) {
        return (
          charger.name.toLowerCase().includes(searchParam.toLowerCase()) ||
          charger.serialNumber.toLowerCase().includes(searchParam.toLowerCase())
        );
      }
      return true;
    });
  }, [data?.data, searchParams]);

  return {
    chargers: filteredChargers,
    isLoading,
    isError: Boolean(error),
    mutate
  };
};

export default useSiteChargers;
