import { dateFilterOption } from '@hooks/use-date-filter';
import { usePortalTranslation } from '@hooks/use-portal-translation';

import { FilterToggleButton } from '@destination/components';

interface IDateTimeFilterButtons {
  selectedFilter: dateFilterOption;
  onFilterChange: (filter: dateFilterOption) => void;
}

export const DateTimeFilterButtons = ({
  selectedFilter,
  onFilterChange
}: IDateTimeFilterButtons) => {
  const { t } = usePortalTranslation();
  const filterOptions: { label: string; value: dateFilterOption }[] = [
    { label: t('date_time.today', { titleize: true }), value: 'today' },
    {
      label: t('date_time.last_x_days', { count: 7, titleize: true }),
      value: 'last7days'
    },
    {
      label: t('date_time.last_x_days', { count: 30, titleize: true }),
      value: 'last30days'
    },
    { label: t('date_time.year', { titleize: true }), value: 'year' },
    { label: t('date_time.all_time', { titleize: true }), value: 'allTime' }
  ];

  return (
    <div data-testid="date-time-filter-buttons" className="mb-6 flex gap-3">
      {filterOptions.map(option => (
        <FilterToggleButton
          data-testid={option.value}
          key={option.value}
          isActive={selectedFilter === option.value}
          onClick={() => onFilterChange(option.value)}
        >
          {option.label}
        </FilterToggleButton>
      ))}
    </div>
  );
};
