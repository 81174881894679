import { defaultPageSize } from '@utils/infinite-loading-utils';

export const buildSearchParams = (
  searchParams?: URLSearchParams,
  isPaginatedFetch = true,
  limit = defaultPageSize
) => {
  const params = new URLSearchParams();
  if (isPaginatedFetch) {
    params.append('pageSize', limit);
  }

  if (searchParams?.toString().length) {
    searchParams.forEach((value, key) => params.set(key, value));
  }

  return params;
};
