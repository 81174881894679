import { useRef } from 'react';

import useSWR from 'swr';

import { SITE_BY_ID_PATH } from '@api/paths';
import { filterDataByDateRange, isRangeWithinRange } from '@utils/date-utils';

import { SiteChargerCurrentDeliveredTelemetry } from '@omnis-pulse-types';

const useSiteTelemetryCurrentDelivered = (
  siteId: string,
  from: string,
  to: string
) => {
  const dataRef = useRef<SiteChargerCurrentDeliveredTelemetry[]>();
  const fromRef = useRef<string>();
  const toRef = useRef<string>();

  let url = null;

  // Filter out the data points that are not within the new range
  if (
    dataRef.current &&
    fromRef.current &&
    toRef.current &&
    isRangeWithinRange(
      { start: from, end: to },
      { start: fromRef.current, end: toRef.current }
    )
  ) {
    dataRef.current = filterDataByDateRange(dataRef.current, from, to);
    fromRef.current = from;
    toRef.current = to;
  } else {
    url = `${SITE_BY_ID_PATH(siteId)}/telemetry/current-delivered?from=${from}&to=${to}`;
  }

  const { data, error, isLoading, mutate } =
    useSWR<SiteChargerCurrentDeliveredTelemetry[]>(url);

  if (data) {
    dataRef.current = data;

    if (fromRef.current !== from || toRef.current !== to) {
      fromRef.current = from;
      toRef.current = to;
    }
  }

  return {
    data: dataRef.current,
    isLoading,
    isError: Boolean(error),
    mutate
  };
};

export default useSiteTelemetryCurrentDelivered;
