export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const validateJSONFile = async (file: File) => {
  return new Promise<boolean>(resolve => {
    const reader = new FileReader();

    reader.onload = () => {
      try {
        JSON.parse(reader.result as string);
        resolve(true);
      } catch (error) {
        resolve(false);
      }
    };

    reader.readAsText(file);
  });
};
