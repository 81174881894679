import { useDecodedToken } from '@hooks/use-decoded-token';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '@components/general/PageHeader';
import { TabLabel } from '@components/TabLabel';

import {
  Card,
  LoadingSkeleton,
  Select,
  Typography
} from '@destination/components';

export const Settings = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'settings' });
  const { isLoading, decodedToken } = useDecodedToken();

  return (
    <div>
      <TabLabel label={t('page_title')} />
      <PageHeader title={t('page_title')} />
      <Card className="mb-12 max-w-[528px] pb-5">
        <Typography variant="smallTitle" className="mb-8 pt-2">
          {t('language.title')}
        </Typography>
        {isLoading && <LoadingSkeleton height={17.77} />}
        {!isLoading && decodedToken && (
          <Select
            label={t('language.input_label')}
            data-testid="language"
            options={[
              {
                value: decodedToken?.language,
                label: decodedToken?.language
              }
            ]}
            selected={{
              value: decodedToken.language,
              label: decodedToken.language
            }}
            disabled={true}
            onChange={function (): void {
              throw new Error('Function not implemented.');
            }}
          />
        )}
      </Card>
    </div>
  );
};
