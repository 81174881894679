import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useSearchFilter } from '@hooks/use-search-filter';
import { Link } from 'react-router-dom';

import useSites from '@api/sites/use-sites';
import { PageHeader } from '@components/general/PageHeader';
import { InfiniteScrollTable } from '@components/InfiniteScrollTable';
import { SitesTable } from '@components/sites/SitesTable';
import { TabLabel } from '@components/TabLabel';
import ChargersIcon from '@icons/chargers_96.svg';
import PlusIcon from '@icons/plus_16.svg';

import { Button, DataError, NothingConfigured } from '@destination/components';

export const Sites = () => {
  const { t } = usePortalTranslation();

  const { searchValue } = useSearchFilter();

  const {
    sites,
    isLoading,
    isLoadingMore,
    hasReachedEnd,
    setPageSize,
    mutate,
    isError
  } = useSites(
    searchValue ? new URLSearchParams({ search: searchValue }) : undefined
  );

  const isSearching = searchValue.length > 0;
  const thereAreSites = Boolean(Array.isArray(sites) && sites.length > 0);

  let content = (
    <InfiniteScrollTable
      dataLength={sites?.length ?? 0}
      onNextPage={() => setPageSize(size => size + 1)}
      isLoadingMore={isLoadingMore}
      hasReachedEnd={hasReachedEnd}
    >
      <SitesTable
        sites={sites}
        isLoading={isLoading}
        searchQuery={searchValue}
      />
    </InfiniteScrollTable>
  );

  if (!isLoading && !thereAreSites && !isSearching) {
    content = (
      <div className="mt-32">
        <NothingConfigured
          title={t('labels.no_configured', {
            entity: t('literals.site_other')
          })}
          icon={
            <div className="h-24 w-24">
              <ChargersIcon />
            </div>
          }
        />
      </div>
    );
  }

  if (!isLoading && !thereAreSites && isSearching) {
    content = (
      <div className="mt-32">
        <NothingConfigured
          title={t('labels.search_no_found', {
            searchTerm: searchValue
          })}
          icon={
            <div className="h-24 w-24">
              <ChargersIcon />
            </div>
          }
        />
      </div>
    );
  }

  if (isError) {
    content = (
      <div className="mt-32">
        <DataError
          variant="primary"
          onRefresh={() => mutate(undefined, true)}
        />
      </div>
    );
  }

  return (
    <>
      <TabLabel label={t('literals.site_other', { titleize: true })} />
      <PageHeader
        title={t('literals.site_other', { titleize: true })}
        isSearchVisible={(!isError && thereAreSites) || isSearching}
        searchPlaceholder={t('labels.search_placeholder', {
          entity: [t('literals.site'), t('literals.address')]
        })}
      />
      <Link to="/sites/create" className="ml-auto">
        <Button
          className="flex gap-1"
          variant="primary"
          data-testid="create-site-button"
        >
          <PlusIcon />
          <span className="px-0.5">
            {t('buttons.create', {
              entity: t('literals.site'),
              titleize: true
            })}
          </span>
        </Button>
      </Link>
      {content}
    </>
  );
};
