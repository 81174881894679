import { rand, randAlpha, randNumber } from '@ngneat/falso';

export const makeArray = <Type>(
  length: number,
  generator: (overrides?: Partial<Type>, i?: number) => Type
): Type[] =>
  Array.from<Type, Type>({ length }, (overrides, i) => generator(overrides, i));

export const shuffleArray = (array: string[]) => {
  return array.sort(() => Math.random() - 0.5);
};

export const enumValueFaker = <T>(o: Record<string, T>) =>
  rand<T>(Object.values(o));

export const randAlphaString = () =>
  randAlpha({
    length: randNumber({ min: 1, max: 255 })
  }).join('');

export const getRandomUniqueEntriesFromArray = (
  number: number,
  array: string[]
) => {
  const randomEntries: string[] = [];

  while (randomEntries.length < number) {
    const randomEntry = rand(array);

    if (!randomEntries.includes(randomEntry)) {
      randomEntries.push(randomEntry);
    }
  }

  return randomEntries;
};
