import { useSiteLoadManagementContext } from '@hooks/use-site-load-management-context';

import { Card, DataError } from '@destination/components';

import { ChargingStrategy } from './ChargingStrategy';
import { LoadManagementMode } from './LoadManagementMode';

export const LoadManagementSettings = () => {
  const { isLoading, isError, mutate } = useSiteLoadManagementContext();

  if (isError) {
    const handleRefresh = () => mutate(undefined, true);
    return (
      <div className="mt-8">
        <DataError onRefresh={handleRefresh} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div data-testid="loading-skeleton" className="flex flex-col gap-4">
        <div className="h-10 w-[620px] animate-pulse bg-[#ECECEC]" />
        <div className="h-10 w-[480px] animate-pulse bg-[#F1F1F1]" />
        <div className="h-10 w-[528px] animate-pulse bg-[#E6E6E6]" />
        <div className="h-10 w-[620px] animate-pulse bg-[#ECECEC]" />
        <div className="h-10 w-[480px] animate-pulse bg-[#F1F1F1]" />
      </div>
    );
  }

  return (
    <Card
      data-testid="load-management-settings"
      className="flex w-[620px] flex-col gap-[42px]"
    >
      <LoadManagementMode />
      <ChargingStrategy />
    </Card>
  );
};
