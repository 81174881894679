import { usePortalTranslation } from '@hooks/use-portal-translation';
import { KeyedMutator } from 'swr';

import { DataError } from '@destination/components';

// helper type to extract the props from a component
type PropsFrom<TComponent> =
  TComponent extends React.FC<infer Props> ? Props : never;

export interface ICouldNotLoadData<T> extends PropsFrom<typeof DataError> {
  mutate?: KeyedMutator<T>;
}

export const CouldNotLoadData = <U,>({
  mutate,
  ...props
}: ICouldNotLoadData<U>) => {
  const { t } = usePortalTranslation();

  const title = t('labels.entity_could_not_load', {
    entity: t('literals.data'),
    titleize: true
  });

  const message = t('labels.refresh_page', { titleize: true });

  const handleRefresh = mutate ? () => mutate(undefined, true) : undefined;

  return (
    <DataError
      title={title}
      message={message}
      onRefresh={handleRefresh}
      {...props}
    />
  );
};
