import { PackedRule } from '@omnis-pulse-types';

export const adminPermissions = ['manage', 'all'];

export const serviceManagerPermissions: PackedRule[] = [
  ['create', 'Site'],
  [
    'update,read',
    'Site',
    {
      organizationId: '3173b321-5a4e-4b38-9e2a-6432cbf4f820'
    }
  ],
  ['create,read', 'InstalledCharger'],
  [
    'read,update',
    'InstalledCharger',
    {
      site: {
        is: {
          organizationId: '3173b321-5a4e-4b38-9e2a-6432cbf4f820'
        }
      }
    }
  ],
  [
    'read',
    'Organization',
    {
      id: '3173b321-5a4e-4b38-9e2a-6432cbf4f820'
    }
  ],

  [
    'read,update',
    'User',
    {
      organizationId: '3173b321-5a4e-4b38-9e2a-6432cbf4f820'
    }
  ]
];

export const serviceEngineerPermissions: PackedRule[] = [
  ['create', 'Site'],
  [
    'read,update',
    'Site',
    {
      organizationId: '536F33D1-9910-46E7-A492-128E5E3FA3A4'
    }
  ],
  ['create,read', 'InstalledCharger'],
  [
    'read,update',
    'InstalledCharger',
    {
      site: {
        is: {
          organizationId: '536F33D1-9910-46E7-A492-128E5E3FA3A4'
        }
      }
    }
  ],
  [
    'read,update',
    'Charger',
    {
      installedCharger: {
        is: {
          site: {
            is: {
              organizationId: '536F33D1-9910-46E7-A492-128E5E3FA3A4'
            }
          }
        }
      }
    }
  ],
  [
    'read',
    'Organization',
    {
      id: '536F33D1-9910-46E7-A492-128E5E3FA3A4'
    }
  ]
];

export const superAdminPermissions: PackedRule[] = [['manage', 'all']];
