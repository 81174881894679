import { useState } from 'react';

import { useParams } from 'react-router-dom';

import useSiteDevices from '@api/sites/use-site-devices';
import ArrowIcon from '@icons/arrow_16.svg';

import { Card, IconButton, Typography } from '@destination/components';

import { Device } from '@omnis-pulse-types';

import { ChargerCurrentDelivered } from './ChargerCurrentDelivered';

export const Chargers = () => {
  const { id = '' } = useParams();

  const { devices } = useSiteDevices(id);

  return (
    <div className="flex flex-col gap-2">
      {(devices?.chargers ?? []).map(charger => (
        <ExpandableCharger key={charger.id} charger={charger} />
      ))}
    </div>
  );
};

const ExpandableCharger = ({ charger }: { charger: Device }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div key={charger.id}>
      <div className="inline-flex h-[18px] items-center gap-6">
        <Typography
          variant="smallTitle"
          className="cursor-pointer"
          onClick={() => setIsExpanded(previousValue => !previousValue)}
        >
          {charger.name}
        </Typography>
        <IconButton
          data-testid={`${charger.id}-icon-button`}
          onClick={() => setIsExpanded(previousValue => !previousValue)}
        >
          <ArrowIcon
            className={`transition-all duration-300 ${isExpanded ? '' : 'rotate-180'}`}
          />
        </IconButton>
      </div>
      <Card
        className={`${isExpanded ? 'mb-10' : 'h-0 !p-0'} mt-4 overflow-visible`}
      >
        {isExpanded && charger.id && (
          <div data-testid={`${charger.id}-content`} className="flex flex-col">
            <ChargerCurrentDelivered chargerId={charger.id} />
          </div>
        )}
      </Card>
    </div>
  );
};
