import { randJSON, randPastDate, randWord } from '@ngneat/falso';

import { enumValueFaker, makeArray } from '@utils/faker-utils';

import { EventLevel, EventLogRecord } from '@omnis-pulse-types';

export class EventLogMockFactory {
  static createEventLogRecord(data?: Partial<EventLogRecord>): EventLogRecord {
    return {
      timestamp: randPastDate().toISOString(),
      code: randWord(),
      source: randWord(),
      message: randJSON().toString(),
      level: enumValueFaker(EventLevel),
      ...data
    };
  }

  static createEventLog(numOfEvents = 3, data?: Partial<EventLogRecord>) {
    return makeArray(numOfEvents, () => this.createEventLogRecord(data));
  }
}
