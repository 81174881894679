import { usePortalTranslation } from '@hooks/use-portal-translation';

import useChargerEventLog from '@api/chargers/use-charger-event-log';
import { NavigationButton } from '@components/buttons/NavigationButton';
import { LogsTable } from '@components/table/logs/LogsTable';

import { Typography } from '@destination/components';

export const ChargerEventLog = ({ chargerId }: { chargerId: string }) => {
  const { t } = usePortalTranslation();
  const params = new URLSearchParams();

  const { logs, isLoading, isError } = useChargerEventLog(
    chargerId,
    true,
    params,
    5
  );

  return (
    <>
      <Typography variant="sectionTitle" className="mb-4">
        {t('labels.entity_logs', {
          entity: t('literals.event_other'),
          titleize: true
        })}
      </Typography>
      <LogsTable logs={logs} isLoading={isLoading} isError={isError} />
      <div className="mb-12 mt-2 flex justify-end">
        <NavigationButton
          label={t('buttons.see_all', {
            entity: t('labels.entity_logs', {
              entity: t('literals.event_other')
            }),
            titleize: true
          })}
          to="logs"
        />
      </div>
    </>
  );
};
