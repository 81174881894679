import { usePortalTranslation } from '@hooks/use-portal-translation';

import useChargerActiveSessions from '@api/chargers/use-charger-active-session';
import { DeviceStatus } from '@components/devices/DeviceStatus';
import {
  getConnectionStatusIcon,
  getEvseStatusIcon
} from '@utils/charger-utils';
import { dateTimeFormat, formatDate } from '@utils/date-utils';

import {
  Charger,
  ChargerActiveSession,
  ConnectionStatusEnum,
  EvseStatusEnum,
  EvseStatus as EvseStatusModel,
  SocketTypeEnum
} from '@omnis-pulse-types';

export interface IChargerStatus {
  charger: Charger;
}

interface IEvseStatus {
  evseStatus: EvseStatusModel;
  session?: ChargerActiveSession;
  socketType?: SocketTypeEnum;
}

export const ChargerStatus = ({ charger }: IChargerStatus) => {
  const { t } = usePortalTranslation();

  const { sessions } = useChargerActiveSessions(charger.id);

  const { evseStatuses } = charger;
  const { status, timestamp } = charger.connectionStatus;
  const connectionStatus = 'states.' + status.toLowerCase();
  const formattedTimestamp = formatDate(timestamp, dateTimeFormat);

  const isConnected = status === ConnectionStatusEnum.CONNECTED;

  // map evse statuses to evse status data, including session and socket type
  const evseStatusData = generateEvseStatusData(evseStatuses, sessions);

  return (
    <div className="flex gap-2">
      <DeviceStatus
        data-testid="charger-connection-status"
        className="border-r-[1px] border-[#EBEBEB]"
        label={t('labels.entity_status', {
          entity: t('literals.connection'),
          titleize: true
        })}
        value={t(connectionStatus, { titleize: true })}
        icon={getConnectionStatusIcon(charger.connectionStatus.status)}
        tooltip={`${t('literals.last_update', { titleize: true })} ${formattedTimestamp}`}
      />
      {isConnected &&
        evseStatusData.map(({ evseStatus, session, socketType }, idx) => {
          // add right border if not last element
          const className =
            evseStatusData.length - 1 !== idx
              ? 'border-r-[1px] border-[#EBEBEB]'
              : '';

          // base evse status
          let label = t('literals.evse').toUpperCase() + ' ' + (idx + 1);
          let value = t('states.' + evseStatus.status.toLowerCase());

          // add socket type if available
          if (socketType) label += ` ${socketType}`;

          // add session power if available and charging
          if (session && evseStatus.status === EvseStatusEnum.CHARGING) {
            const power = (session.power / 1000).toFixed(1);
            value += ` ${power}kw`;
          }

          return (
            <DeviceStatus
              key={evseStatus.id}
              className={className}
              data-testid={`evse-status-${evseStatus.id}`}
              label={label}
              value={value}
              icon={getEvseStatusIcon(evseStatus.status)}
              tooltip={`${t('literals.last_update')} ${formatDate(evseStatus.timestamp, dateTimeFormat)}`}
            />
          );
        })}
    </div>
  );
};

function generateEvseStatusData(
  evseStatuses: EvseStatusModel[],
  sessions: ChargerActiveSession[] | undefined
) {
  return evseStatuses.reduce<IEvseStatus[]>((acc, evseStatus) => {
    const session = sessions?.find(({ connectorId }) =>
      evseStatus.sockets.some(socket => socket.id === connectorId)
    );

    const socketType =
      session &&
      evseStatus.sockets.find(({ id }) => id === session.connectorId)?.type;

    acc.push({ evseStatus, session, socketType });

    return acc;
  }, []);
}
