import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useSearchFilter } from '@hooks/use-search-filter';
import { useSearchParams } from 'react-router-dom';

import useOrganizationUsers from '@api/user-account/use-organization-users';
import { InfiniteScrollTable } from '@components/InfiniteScrollTable';
import UsersIcon from '@icons/drivers_96.svg';

import {
  Card,
  NothingConfigured,
  SearchInput,
  useSort
} from '@destination/components';

import { UsersFilterButtons } from './UsersFilterButtons';
import { UsersTable } from './UsersTable';

export const OrganizationUsers = () => {
  const [searchParams] = useSearchParams();
  const { t } = usePortalTranslation();

  const { searchValue, handleSearch } = useSearchFilter();
  const { activeSort, sortDirection, handleSort } = useSort();

  const {
    users,
    hasReachedEnd,
    setPageSize,
    isLoading,
    isLoadingMore,
    isError,
    mutate
  } = useOrganizationUsers(searchParams, activeSort, sortDirection);

  const handleReload = async () => {
    await mutate();
  };

  const isSearching = searchValue.length > 0;
  const isFilterActive = searchParams.size > 0;
  const thereAreUsers = Boolean(Array.isArray(users) && users.length > 0);

  const renderPage = (content: React.ReactNode) => (
    <>
      <div className="mb-6 w-full max-w-[342px]">
        <SearchInput
          placeholder={t('labels.search_placeholder', {
            entity: t('literals.user')
          })}
          value={searchValue}
          onChange={handleSearch}
          data-testid="search-input-users"
        />
      </div>
      <div className="mb-6">
        <UsersFilterButtons />
      </div>
      <Card className="!shadow-none">{content}</Card>
    </>
  );

  if (
    !isError &&
    !isLoading &&
    !thereAreUsers &&
    (isSearching || isFilterActive)
  ) {
    return renderPage(
      <NothingConfigured
        icon={<UsersIcon />}
        title={
          isSearching
            ? t('labels.search_no_found', { searchTerm: searchValue })
            : t('labels.filter_no_found')
        }
      />
    );
  }

  return renderPage(
    <InfiniteScrollTable
      dataLength={users?.length ?? 0}
      onNextPage={() => setPageSize(size => size + 1)}
      isLoadingMore={isLoadingMore}
      hasReachedEnd={hasReachedEnd}
    >
      <UsersTable
        users={users}
        isLoading={isLoading}
        isError={isError}
        searchValue={searchValue}
        activeSort={activeSort}
        sortDirection={sortDirection}
        onSort={handleSort}
        onUpdate={handleReload}
      />
    </InfiniteScrollTable>
  );
};
