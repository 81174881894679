import { useRef } from 'react';

import useSWR from 'swr';

import { CHARGER_BY_ID_PATH } from '@api/paths';
import { filterDataByDateRange, isRangeWithinRange } from '@utils/date-utils';

import { ChargerEvseCurrentTelemetry } from '@omnis-pulse-types';

const useChargerEvseTelemetry = (
  chargerId: string,
  from: string,
  to: string
) => {
  const dataRef = useRef<ChargerEvseCurrentTelemetry[]>();
  const fromRef = useRef<string>();
  const toRef = useRef<string>();

  let url = null;

  // Filter out the data points that are not within the new range
  if (
    dataRef.current &&
    fromRef.current &&
    toRef.current &&
    isRangeWithinRange(
      { start: from, end: to },
      { start: fromRef.current, end: toRef.current }
    )
  ) {
    dataRef.current = dataRef.current.map(evse => ({
      ...evse,
      currentConsumption: filterDataByDateRange(
        evse.currentConsumption,
        from,
        to
      )
    }));
    fromRef.current = from;
    toRef.current = to;
  } else {
    url = `${CHARGER_BY_ID_PATH(chargerId)}/telemetry/evse-current?from=${from}&to=${to}`;
  }

  const { data, isLoading, error, mutate } =
    useSWR<ChargerEvseCurrentTelemetry[]>(url);

  if (data) {
    dataRef.current = data;

    if (fromRef.current !== from || toRef.current !== to) {
      fromRef.current = from;
      toRef.current = to;
    }
  }

  return { data: dataRef.current, isLoading, isError: Boolean(error), mutate };
};

export default useChargerEvseTelemetry;
