import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Outlet } from 'react-router-dom';

import { TabLabel } from '@components/TabLabel';

import { Tab, Typography } from '@destination/components';

export const HMISettings = () => {
  const { t, t_generic } = usePortalTranslation('hmi');

  const title = t_generic('labels.entity_settings', {
    entity: 'HMI'
  });
  const description = t('labels.page_description');
  const ChargerCustomizationTab = t_generic('labels.entity_customization', {
    entity: t_generic('literals.charger', { titleize: true })
  });

  return (
    <>
      <TabLabel label={title} />
      <Typography className="mb-3" variant="pageTitle">
        {title}
      </Typography>
      <Typography className="mb-6">{description}</Typography>
      <div className="mb-4 inline-flex">
        <Tab isActive={true}>{ChargerCustomizationTab}</Tab>
      </div>
      <section>
        <Outlet />
      </section>
    </>
  );
};
