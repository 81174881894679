import { useState } from 'react';

export interface IGraphProperties {
  [key: string]: boolean | string | null;
}

export const useLegendToggle = (labels: string[]) => {
  const [graphProps, setGraphProps] = useState<IGraphProperties>({
    ...labels.reduce((props: IGraphProperties, label: string) => {
      props[label] = true;
      return props;
    }, {}),
    hover: null
  });

  const handleClick = (dataKey: string) => {
    setGraphProps({
      ...graphProps,
      [dataKey]: !graphProps[dataKey],
      hover: null
    });
  };

  const handleLegendMouseEnter = (event: { dataKey: string }) => {
    if (graphProps[event.dataKey]) {
      setGraphProps({ ...graphProps, hover: event.dataKey });
    }
  };

  const handleLegendMouseLeave = () => {
    setGraphProps({ ...graphProps, hover: null });
  };

  return {
    graphProps,
    handleClick,
    handleLegendMouseEnter,
    handleLegendMouseLeave
  };
};
