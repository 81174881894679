import { createContext, useCallback, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr';

import useSiteLoadManagement from '@api/sites/use-site-load-management';
import type { Payload } from '@api/sites/use-update-site-load-management';
import useUpdateSiteLoadManagement from '@api/sites/use-update-site-load-management';

import { SiteLoadManagement } from '@omnis-pulse-types';

interface ISiteLoadManagementContext {
  data: SiteLoadManagement | undefined;
  isLoading: boolean;
  isUpdating: boolean;
  isError: boolean;
  mutate: KeyedMutator<SiteLoadManagement>;
  update: (payload: Payload) => Promise<void>;
}

interface ISiteLoadManagementProvider {
  children: React.ReactNode;
}

export const SiteLoadManagementContext =
  createContext<ISiteLoadManagementContext | null>(null);

export const SiteLoadManagementProvider = ({
  children
}: ISiteLoadManagementProvider) => {
  const { id: siteId } = useParams();

  const { data, isLoading, isError, mutate } = useSiteLoadManagement(siteId);
  const { isUpdating, trigger } = useUpdateSiteLoadManagement(siteId);

  const update = useCallback(
    async function update(payload: Payload) {
      const optimisticData = createOptimisticData(data, payload);

      await trigger(payload, {
        optimisticData,
        populateCache(_, currentData) {
          return createOptimisticData(currentData, payload);
        }
      });
    },
    [data, trigger]
  );

  const value = useMemo(
    () => ({ data, isLoading, isUpdating, isError, update, mutate }),
    [data, isError, isLoading, isUpdating, update, mutate]
  );

  return (
    <SiteLoadManagementContext.Provider value={value}>
      {children}
    </SiteLoadManagementContext.Provider>
  );
};

function createOptimisticData(
  data: SiteLoadManagement | undefined,
  payload: Payload
): SiteLoadManagement {
  if (!data) throw new Error('Data is not available');

  if (payload.activeMode) {
    return { ...data, activeMode: payload.activeMode };
  }

  if (payload.chargingStrategy) {
    return { ...data, chargingStrategy: payload.chargingStrategy };
  }

  if (
    payload.staticLoadManagement?.gridConnectionLimit &&
    data.staticLoadManagement.siteAuxiliaryCurrent
  ) {
    const chargingBudgetLimit =
      payload.staticLoadManagement.gridConnectionLimit -
      data.staticLoadManagement.siteAuxiliaryCurrent;

    const staticLoadManagement: SiteLoadManagement['staticLoadManagement'] = {
      ...data.staticLoadManagement,
      gridConnectionLimit: payload.staticLoadManagement.gridConnectionLimit,
      chargingBudgetLimit: Number(chargingBudgetLimit.toFixed(1))
    };

    return { ...data, staticLoadManagement };
  }

  if (
    data.staticLoadManagement.gridConnectionLimit &&
    (payload.staticLoadManagement?.siteAuxiliaryCurrent ||
      payload.staticLoadManagement?.siteAuxiliaryCurrent === 0)
  ) {
    const chargingBudgetLimit =
      data.staticLoadManagement.gridConnectionLimit -
      payload.staticLoadManagement.siteAuxiliaryCurrent;

    const staticLoadManagement: SiteLoadManagement['staticLoadManagement'] = {
      ...data.staticLoadManagement,
      siteAuxiliaryCurrent: payload.staticLoadManagement.siteAuxiliaryCurrent,
      chargingBudgetLimit: Number(chargingBudgetLimit.toFixed(1))
    };

    return { ...data, staticLoadManagement };
  }

  if (payload.dynamicLoadManagement?.gridConnectionLimit) {
    const dynamicLoadManagement = {
      ...data.dynamicLoadManagement,
      gridConnectionLimit: payload.dynamicLoadManagement.gridConnectionLimit
    };

    return { ...data, dynamicLoadManagement };
  }

  if (payload.dynamicLoadManagement?.fallbackChargingBudgetLimit) {
    const dynamicLoadManagement = {
      ...data.dynamicLoadManagement,
      fallbackChargingBudgetLimit:
        payload.dynamicLoadManagement.fallbackChargingBudgetLimit
    };

    return { ...data, dynamicLoadManagement };
  }

  return { ...data };
}
