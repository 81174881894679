import useSWR from 'swr';

import { SITE_BY_ID_PATH } from '@api/paths';

import { CurrentTelemetry } from '@omnis-pulse-types';

export function useSiteGridMeterTelemetry(
  siteId: string,
  searchParams?: URLSearchParams
) {
  const params = new URLSearchParams(searchParams);

  let url = SITE_BY_ID_PATH(siteId) + '/grid-meter/telemetry/current';
  if (params.toString().length) {
    url = `${url}?${params.toString()}`;
  }
  const { data, isLoading, error, mutate } = useSWR<CurrentTelemetry>(url);

  return { data, isLoading, isError: Boolean(error), mutate };
}

export default useSiteGridMeterTelemetry;
