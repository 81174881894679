import Axios from 'axios';

import { getToken } from '@api/api';

Axios.defaults.baseURL = window.location.origin + '/api';
Axios.defaults.withCredentials = true;

export const axiosInstance = Axios.create({
  baseURL: window.location.origin + '/api',
  withCredentials: true
});

export const fetcher = async (url: string) => {
  const token = await getToken();
  const res = await Axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return res.data;
};

export const modifier = async (
  url: string,
  method: 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  payload: object = {},
  usesFormData = false
) => {
  const token = await getToken();
  const headers: { [key: string]: string } = {
    Authorization: `Bearer ${token}`
  };
  let body;

  if (usesFormData) {
    headers['Content-Type'] = 'multipart/form-data';
    body = new FormData();
    for (const [attribute, value] of Object.entries(payload)) {
      if (Array.isArray(value)) {
        for (const item of value) {
          body.append(attribute, item);
        }
      } else {
        body.append(attribute, value);
      }
    }
  } else {
    body = payload;
  }

  try {
    switch (method) {
      case 'POST': {
        const { data } = await Axios.post(url, body, {
          method,
          headers
        });
        return { error: undefined, data };
      }
      case 'PUT': {
        const { data } = await Axios.put(url, body, {
          method,
          headers
        });
        return { error: undefined, data };
      }
      case 'PATCH': {
        const { data } = await Axios.patch(url, body, {
          method,
          headers
        });
        return { error: undefined, data };
      }
      case 'DELETE': {
        const { data } = await Axios.delete(url, { method, headers });
        return { error: undefined, data };
      }
    }
  } catch (error) {
    return { error, data: undefined };
  }
};
