import { useEffect } from 'react';

import { defaultPageSize } from '@utils/infinite-loading-utils';

function useFixMissingScroll(
  hasMoreItems: boolean,
  dataLength: number,
  isLoadingMore: boolean,
  onNextPage: () => void
) {
  useEffect(() => {
    const mainElement = document.getElementById('main-content');

    /**
     * Conditions checked to trigger the `onNextPage` function.
     * The conditions include:
     * - `onNextPage` is defined
     * - `dataLength` is greater than 0 (Meaning the initial data has been loaded)
     * - `dataLength` is divisible by `defaultPageSize` (If the data length is not divisible by the default page size, it means that the last page has been reached)
     * - `isLoadingMore` is false
     * - `mainElement` is defined
     * - `mainElement` scroll height is less than or equal to client height
     * - `hasMoreItems` is true
     * If all conditions are met, the `onNextPage` function is called.
     */
    if (
      onNextPage &&
      dataLength > 0 &&
      dataLength % Number(defaultPageSize) === 0 &&
      isLoadingMore === false &&
      mainElement &&
      mainElement.scrollHeight <= mainElement.clientHeight &&
      hasMoreItems
    ) {
      onNextPage();
    }
  }, [hasMoreItems, dataLength, onNextPage, isLoadingMore]);
}

export default useFixMissingScroll;
