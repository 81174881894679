import {
  rand,
  randFileName,
  randNumber,
  randPastDate,
  randSemver,
  randUuid
} from '@ngneat/falso';

import { makeArray } from '@utils/faker-utils';

import { HmiDocument } from '@omnis-pulse-types';

export class DocumentMockFactory {
  static createDocument(data?: Partial<HmiDocument>): HmiDocument {
    const filename = randFileName();

    return {
      documentId: randUuid(),
      name: filename.split('.')[0],
      filename,
      version: randSemver(),
      createdAt: randPastDate().toISOString(),
      documentType: rand([
        'InstanceGraph',
        'CustomerPreferences',
        'PropertyOverrides'
      ]),
      chargers: makeArray(randNumber({ min: 1, max: 100 }), () => randUuid()),
      ...data
    };
  }

  static createDocuments(
    numberOfDocuments: number,
    data?: Partial<HmiDocument>
  ) {
    return makeArray(numberOfDocuments, () => this.createDocument(data));
  }
}
