import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DetailLine } from '@components/DetailsLine';

import { Typography } from '@destination/components';

import { SerialGridMeter } from '@omnis-pulse-types';

export const GridMeterSerialCommunicationSettings = ({
  settings
}: {
  settings: SerialGridMeter;
}) => {
  const { t, t_generic } = usePortalTranslation('gridMeters');
  return (
    <>
      <Typography variant="sectionTitle" className="mb-4 pt-2">
        {t_generic('labels.entity_settings', {
          entity: t_generic('literals.communication'),
          titleize: true
        })}
      </Typography>
      <div className="mb-4 flex flex-col [&>div:nth-child(even)]:bg-[#FAFAFA]">
        {settings.serialProperties &&
          Object.entries(settings.serialProperties).map(
            ([key, value]) =>
              value !== undefined &&
              value !== '' &&
              value !== null && (
                <DetailLine
                  key={`grid-meter-settings-line-${key}`}
                  label={t('literals.' + key, { titleize: true })}
                  className="flex min-h-[50px] max-w-[588px] items-start px-3"
                  data-testid={`grid-meter-settings-line-${key}`}
                >
                  <Typography variant="smallTitle">
                    {value.toString()}
                  </Typography>
                </DetailLine>
              )
          )}
      </div>
    </>
  );
};
