import { useTranslation } from 'react-i18next';

import { LegendItem } from '@components/charts/LegendItem';

export const EvsePhaseCurrentWithGridLimitLegend = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'charger.evse.phase_current_graph.legend'
  });

  return (
    <div className="ml-[45px]">
      <div className="mb-2 flex gap-6">
        <LegendItem color="bg-[#04a5e2]" label={t('grid_limit')} />
        <LegendItem color="bg-[#E64997]" label={t('state_of_charge')} />

        <LegendItem
          color="bg-black"
          label={`${t('consumption')} - ${t('phase')} 1`}
        />
      </div>
      <div className="flex gap-6">
        <LegendItem
          color="bg-[#77A3FC]"
          label={`${t('consumption')} - ${t('phase')} 2`}
        />
        <LegendItem
          color="bg-[#FF9F8C]"
          label={`${t('consumption')} - ${t('phase')} 3`}
        />
      </div>
    </div>
  );
};
