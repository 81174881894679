import { randBetweenDate, randNumber } from '@ngneat/falso';
import { endOfDay, startOfDay } from 'date-fns';

import {
  ConnectorCurrentTelemetry,
  CurrentTelemetry,
  MaxCurrentTelemetry
} from '@omnis-pulse-types';

export class SiteCurrentTelemetryMockFactory {
  static createCurrentTelemetry(
    data?: Partial<CurrentTelemetry>,
    startDate?: string | Date,
    endDate?: string | Date,
    numberOfTimestamps: number = randNumber({ min: 1, max: 15 }),
    numberOfPhases = 3
  ): CurrentTelemetry {
    const from = startDate ?? startOfDay(new Date());
    const to = endDate ?? endOfDay(new Date());
    const timestamps = randBetweenDate({
      from,
      to,
      length: numberOfTimestamps
    }).map(date => date.toISOString());

    return {
      maxCurrent: timestamps.map(timestamp => this.createGridLimit(timestamp)),
      current: timestamps.map(timestamp =>
        numberOfPhases === 3
          ? this.createCurrent(timestamp)
          : this.createOnePhaseCurrent(timestamp)
      ),
      ...data
    };
  }

  static createGridLimit(
    timestamp: string,
    data?: Partial<ConnectorCurrentTelemetry>
  ): MaxCurrentTelemetry {
    return {
      timestamp: timestamp,
      value: randNumber({ min: 1, max: 80 }),
      ...data
    };
  }

  static createCurrent(
    timestamp: string,
    data?: Partial<ConnectorCurrentTelemetry>
  ): ConnectorCurrentTelemetry {
    return {
      timestamp: timestamp,
      currentL1: randNumber({ min: 1, max: 80 }),
      currentL2: randNumber({ min: 1, max: 80 }),
      currentL3: randNumber({ min: 1, max: 80 }),
      ...data
    };
  }

  static createOnePhaseCurrent(
    timestamp: string,
    data?: Partial<ConnectorCurrentTelemetry>
  ): ConnectorCurrentTelemetry {
    return {
      timestamp: timestamp,
      currentL1: randNumber({ min: 1, max: 80 }),
      currentL2: 0,
      currentL3: 0,
      ...data
    };
  }
}
