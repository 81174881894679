import useSWRInfinite from 'swr/infinite';

import { CHARGER_BY_ID_PATH } from '@api/paths';
import { buildSearchParams } from '@utils/build-search-parameters';
import { defaultPageSize, hasReachedEnd } from '@utils/infinite-loading-utils';

const useChargerEventLog = (
  chargerId: string,
  isPaginatedFetch = true,
  searchParams?: URLSearchParams,
  limit?: number
) => {
  const params = buildSearchParams(
    searchParams,
    isPaginatedFetch,
    limit?.toString()
  );

  const {
    data: responses,
    error,
    isLoading,
    size: pageSize,
    setSize: setPageSize,
    mutate
  } = useSWRInfinite(
    index => {
      if (params.get('pageOffset')) {
        params.set('pageOffset', index.toString());
      } else {
        params.append('pageOffset', index.toString());
      }
      return `${CHARGER_BY_ID_PATH(chargerId)}/events?${params.toString()}`;
    },
    { revalidateFirstPage: false }
  );

  return {
    logs: (responses ?? []).map(response => response.data).flat(),
    isLoading,
    isLoadingMore: responses ? responses.length < pageSize : false,
    hasReachedEnd: responses
      ? hasReachedEnd(responses, Number(defaultPageSize))
      : false,
    pageSize,
    setPageSize,
    isError: Boolean(error),
    mutate
  };
};

export default useChargerEventLog;
