import { TFunction } from 'i18next';

export const legendFormatter = (
  value: string,
  onClick: (dataKey: string) => void,
  t: TFunction
) => <LegendItem value={value} t={t} onClick={onClick} />;

const LegendItem = ({
  value,
  onClick,
  t
}: {
  value: string;
  onClick: (dataKey: string) => void;
  t: TFunction;
}) => {
  return (
    <span
      className="cursor-pointer pr-[33px] text-xs text-black hover:font-bold"
      onClick={() => onClick(value)}
      onKeyDown={() => onClick(value)}
    >
      {t(value)}
    </span>
  );
};
