// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomYAxisLabel = (props: any) => {
  return (
    <text
      fontSize="12"
      fontFamily="ABBvoice"
      x={props.viewBox.x + 16}
      y="2"
      fill="#9F9F9F"
    >
      <tspan x={props.viewBox.x + 16} dy="0.355em">
        {props.value}
      </tspan>
    </text>
  );
};
