import useSWR from 'swr';

import { SITE_BY_ID_PATH } from '@api/paths';

import { GridMeter } from '@omnis-pulse-types';

export default function useSiteGridMeter(siteId?: string) {
  const { data, isLoading, error, mutate } = useSWR<GridMeter[]>(
    siteId ? SITE_BY_ID_PATH(siteId) + '/grid-meters' : null
  );

  return {
    gridMeters: data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
