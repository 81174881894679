import useSWR from 'swr';

import { SITE_BY_ID_PATH } from '@api/paths';
import { fetcher } from '@utils/swr-utils';

import { SiteLoadManagement } from '@omnis-pulse-types';

export default function useSiteLoadManagement(siteId?: string) {
  const { data, isLoading, error, mutate } = useSWR<SiteLoadManagement>(
    siteId ? SITE_BY_ID_PATH(siteId) + '/load-management' : null,
    fetcher
  );

  return { data, isLoading, isError: Boolean(error), mutate };
}
