import React, { useLayoutEffect, useRef, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { randUuid } from '@ngneat/falso';

import { getSocketTypeIcons } from '@utils/socket-utils';

import { Tooltip, Typography } from '@destination/components';

import { ChargerDetails, SocketTypeEnum } from '@omnis-pulse-types';

export interface IChargerInformation {
  charger: ChargerDetails;
}

export const ChargerInformation = ({ charger }: IChargerInformation) => {
  const { t, t_generic } = usePortalTranslation('chargers');

  const socketTypes: string[] = [];

  charger.evseStatuses.forEach(eveStatus => {
    eveStatus.sockets.forEach(socket => {
      socketTypes.push(socket.type);
    });
  });

  return (
    <>
      <Typography variant="sectionTitle" className="mb-[9px]">
        {t_generic('labels.entity_information', {
          entity: t_generic('literals.charger'),
          titleize: true
        })}
      </Typography>
      <div
        className={
          'h-min-[280px] mb-12 flex flex-col gap-4 rounded-md bg-white p-4 shadow-tile'
        }
      >
        <PropertyRow
          label={t_generic('literals.name', { titleize: true })}
          value={charger.name}
          data-testId="charger-name"
        />
        <PropertyRow
          value={charger.serialNumber}
          label={t_generic('literals.serial_number', { titleize: true })}
          data-testid="charger-serialnumber"
        />
        <PropertyRow
          label={t_generic('literals.product_type', { titleize: true })}
          value={charger.productType}
          data-testid="charger-product-type"
        />
        <PropertyRow
          label={t('literals.max_power', { titleize: true })}
          value={`${charger.maxPower} kW`}
          data-testid="charger-max-power"
        />
        <PropertyRow
          label={t('literals.max_current', { titleize: true })}
          value={`${charger.maxCurrent} A`}
          data-testid="charger-max-current"
        />
        <SocketTypePropertyRow
          label={t('literals.outlet_type', { titleize: true })}
          value={socketTypes}
          data-testid="charger-outlet-type"
        />
        <PropertyRow
          label={t_generic('labels.number_of_entity', {
            entity: t_generic('literals.evse_other'),
            titleize: true
          })}
          value={`${charger.numberOfEvses}`}
          data-testid="charger-number-evses"
        />
        {charger.firmwareVersion && (
          <PropertyRow
            label="firmwareVersion"
            value={charger.firmwareVersion}
            data-testid="charger-firmware-version"
          />
        )}
      </div>
    </>
  );
};

const PropertyRow = ({ label, value }: { label: string; value: string }) => {
  const { containerRef, textRef, isTruncated } = useTruncatedText();

  return (
    <div className="mb-4 flex gap-4">
      <div className="min-w-[200px]">
        <Typography>{label}</Typography>
      </div>
      <div ref={containerRef} className="truncate">
        <Tooltip message={isTruncated ? value : ''}>
          <Typography className="truncate">
            <span className="font-bold" ref={textRef}>
              {value}
            </span>
          </Typography>
        </Tooltip>
      </div>
    </div>
  );
};

const SocketTypePropertyRow = ({
  label,
  value
}: {
  label: string;
  value: string[];
}) => {
  const { containerRef, textRef, isTruncated } = useTruncatedText();

  return (
    <div className="mb-4 flex gap-4">
      <div className="min-w-[200px]">
        <Typography>{label}</Typography>
      </div>
      <div ref={containerRef} className="truncate">
        <Tooltip message={isTruncated ?? ''}>
          <Typography className="truncate">
            <span className="flex font-bold" ref={textRef}>
              {value.map((socketType, index) => (
                <React.Fragment key={randUuid()}>
                  {getSocketTypeIcons(socketType)}{' '}
                  {socketType === SocketTypeEnum.UNUSED
                    ? SocketTypeEnum.UNKNOWN
                    : socketType}
                  {index < value.length - 1 && ', '}
                </React.Fragment>
              ))}
            </span>
          </Typography>
        </Tooltip>
      </div>
    </div>
  );
};

const useTruncatedText = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useLayoutEffect(() => {
    if (containerRef.current && textRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const textWidth = textRef.current.getBoundingClientRect().width;
      setIsTruncated(textWidth > containerWidth);
    }
  }, []);

  return { containerRef, textRef, isTruncated };
};
