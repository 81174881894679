import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useSiteDevices from '@api/sites/use-site-devices';
import { GatewayDetails } from '@components/gateways/GatewayDetails';
import { TabLabel } from '@components/TabLabel';
import GatewayIcon from '@icons/gateway_48.svg';

import {
  DataError,
  LoadingSkeleton,
  NothingConfigured,
  Typography
} from '@destination/components';

export const Gateways = () => {
  const { id, gatewayId } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site.devices.terra_gateway_pro'
  });

  const { devices, isLoading, isError, mutate } = useSiteDevices(id);

  useEffect(() => {
    if (!gatewayId && devices?.gateways?.length) {
      navigate(`${devices.gateways[0].id}`);
    }
  }, [devices?.gateways, gatewayId, navigate]);

  if (isError) {
    return (
      <div className="flex h-full w-full items-center">
        <DataError onRefresh={() => mutate(undefined, true)} />
      </div>
    );
  }

  if (isLoading) {
    return <LoadingSkeleton className="!h-full" height={0} />;
  }

  if (!devices || devices.gateways.length === 0) {
    return (
      <NothingConfigured
        title={t('no_configured_gateways')}
        className="my-auto"
        icon={<GatewayIcon />}
      >
        <Typography
          className="tw-max-w-[263px] tw-text-center"
          variant="description"
        >
          {t('no_configured_message')}
        </Typography>
      </NothingConfigured>
    );
  }

  const gatewayName =
    devices.gateways.find(gateway => gateway.id === gatewayId)?.name ??
    'Gateway';

  return (
    <>
      <TabLabel label={gatewayName} />
      <GatewayDetails gateways={devices.gateways} />
    </>
  );
};
