import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DeviceStatus } from '@components/devices/DeviceStatus';
import { getConnectionStatusIcon } from '@utils/charger-utils';
import { dateTimeFormat, formatDate } from '@utils/date-utils';

import { Gateway } from '@omnis-pulse-types';

export interface IGatewayStatus {
  gateway: Gateway;
  'data-testid'?: string;
}

export const GatewayStatus = ({
  gateway,
  'data-testid': dataTestId
}: IGatewayStatus) => {
  const { t } = usePortalTranslation();

  const { status, timestamp } = gateway.connectionStatus;
  const formattedTimestamp = formatDate(timestamp, dateTimeFormat);

  const label = t('labels.entity_service', {
    entity: 'ABB',
    titleize: true
  });

  const value = t('states.' + status.toLowerCase(), { titleize: true });

  return (
    <DeviceStatus
      data-testid={dataTestId ?? 'gateway-connection-status'}
      label={label}
      value={value}
      icon={getConnectionStatusIcon(status)}
      tooltip={`${t('literals.last_update', { titleize: true })} ${formattedTimestamp}`}
    />
  );
};
