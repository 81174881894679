export const formatDisjunctiveList = (
  value: string | string[],
  lng: string | undefined
) => {
  if (!Array.isArray(value)) return value;

  const formatter = new Intl.ListFormat(lng, { type: 'disjunction' });
  return formatter.format(value);
};

export const formatConjunctiveList = (
  value: string | string[],
  lng: string | undefined
) => {
  if (!Array.isArray(value)) return value;

  const formatter = new Intl.ListFormat(lng, { type: 'conjunction' });
  return formatter.format(value);
};

export const titleize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};
