import { useMemo } from 'react';

import AvailableIcon from '@icons/charger_available_48.svg';
import ChargingIcon from '@icons/charger_charging_48.svg';
import ErrorIcon from '@icons/charger_error_48.svg';
import OfflineIcon from '@icons/charger_offline_48.svg';

import { Grid } from '@destination/components';

import {
  ConnectionStatus,
  ConnectionStatusEnum,
  EvseStatus,
  EvseStatusEnum
} from '@omnis-pulse-types';

interface IChargerStatusTableCell {
  connectionStatus: ConnectionStatus;
  evseStatuses: EvseStatus[];
}

export const ChargerStatusTableCell = ({
  connectionStatus,
  evseStatuses
}: IChargerStatusTableCell) => {
  const { evseInError, evseCharging, statusBarClasses } = useMemo(() => {
    const baseStatusBarClasses = [
      'absolute -left-4 top-0 h-[calc(100%+24px)] -my-3 w-2 rounded-l-[10px]'
    ];

    if (connectionStatus?.status === ConnectionStatusEnum.NOT_CONNECTED) {
      baseStatusBarClasses.push('bg-[#BABABA]');
    }

    let evseInError = false;
    let evseCharging = false;

    evseStatuses.forEach(evse => {
      if (
        connectionStatus?.status === ConnectionStatusEnum.CONNECTED &&
        evse.status === EvseStatusEnum.ERROR
      ) {
        evseInError = true;
        baseStatusBarClasses.push('bg-[#F03040]');
      } else if (evse.status === EvseStatusEnum.CHARGING) {
        evseCharging = true;
      }
    });

    return {
      evseInError,
      evseCharging,
      statusBarClasses: baseStatusBarClasses.join(' ')
    };
  }, [connectionStatus?.status, evseStatuses]);

  let iconElement: React.ReactNode = null;
  if (connectionStatus?.status !== ConnectionStatusEnum.CONNECTED) {
    iconElement = <OfflineIcon data-testid="offline-icon" />;
  } else if (evseInError) {
    iconElement = <ErrorIcon data-testid="error-icon" />;
  } else if (evseCharging) {
    iconElement = <ChargingIcon data-testid="charging-icon" />;
  } else {
    iconElement = <AvailableIcon data-testid="available-icon" />;
  }

  return (
    <Grid.DataCell className="relative flex">
      <div data-testid="status-bar" className={statusBarClasses} />
      <div className="my-auto h-12 w-12">{iconElement}</div>
    </Grid.DataCell>
  );
};
