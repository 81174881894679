import { useMsal } from '@azure/msal-react';
import { usePortalTranslation } from '@hooks/use-portal-translation';

import AccountIcon from '@icons/account_16.svg';
import NotificationIcon from '@icons/notification_16.svg';
import SettingsIcon from '@icons/settings_16.svg';
import SignOutIcon from '@icons/sign_out_16.svg';

import {
  MobileNavigation as MobileNav,
  Sidebar
} from '@destination/components';

import { MobileNavigation, Navigation } from './Navigation';

interface MenuNavigation {
  isMobile: boolean;
}

export const FooterNavigation = ({ isMobile }: MenuNavigation) => {
  const { t } = usePortalTranslation();
  const { instance } = useMsal();

  const items = [
    {
      id: 'account',
      icon: <AccountIcon />,
      label: t('literals.account', { titleize: true }),
      url: '/account'
    },
    {
      id: 'notifications',
      icon: <NotificationIcon />,
      label: t('literals.notification_other', { titleize: true }),
      url: '/notifications'
    },
    {
      id: 'settings',
      icon: <SettingsIcon />,
      label: t('literals.settings_other', { titleize: true }),
      url: '/settings'
    }
  ];

  const handleLogout = async () => {
    await instance.logout();
  };

  if (isMobile) {
    return (
      <>
        <MobileNavigation items={items} />
        <button
          className="cursor-pointer whitespace-nowrap"
          onClick={handleLogout}
          data-testid="sign-out"
        >
          <MobileNav.MenuItem
            icon={<SignOutIcon />}
            isActive={false}
            label={t('literals.sign_out', { titleize: true })}
          />
        </button>
      </>
    );
  }

  return (
    <>
      <Navigation items={items} />
      <button
        className="cursor-pointer whitespace-nowrap"
        onClick={handleLogout}
        data-testid="sign-out"
      >
        <Sidebar.MenuItem
          icon={<SignOutIcon />}
          isActive={false}
          label={t('literals.sign_out', { titleize: true })}
        />
      </button>
    </>
  );
};
