import { useEffect } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useParams } from 'react-router-dom';

import useSiteDevices from '@api/sites/use-site-devices';
import { ChargerDetails } from '@components/charger/ChargerDetails';
import { TabLabel } from '@components/TabLabel';
import ChargerIcon from '@icons/charger_96.svg';

import {
  DataError,
  LoadingSkeleton,
  NothingConfigured,
  Typography
} from '@destination/components';

export const Chargers = () => {
  const { id, chargerId } = useParams();
  const navigate = useNavigate();

  const { t, t_generic } = usePortalTranslation('chargers');

  const { devices, isLoading, isError, mutate } = useSiteDevices(id);

  useEffect(() => {
    if (!chargerId && devices?.chargers.length) {
      navigate(`${devices.chargers[0].id}`);
    }
  }, [devices?.chargers, chargerId, navigate]);

  if (isError) {
    return (
      <div className="flex h-full items-center justify-center">
        <DataError onRefresh={() => mutate(undefined, true)} />
      </div>
    );
  }

  if (isLoading) {
    return <LoadingSkeleton className="!h-full" height={0} />;
  }

  if (!devices || devices.chargers.length === 0) {
    return (
      <NothingConfigured
        title={t_generic('labels.no_configured', {
          entity: t_generic('literals.charger_other')
        })}
        className="my-auto"
        icon={<ChargerIcon />}
      >
        <Typography
          className="tw-max-w-[263px] tw-text-center"
          variant="description"
        >
          {t('errors.no_configured_message')}
        </Typography>
      </NothingConfigured>
    );
  }

  const chargerName =
    devices.chargers.find(charger => charger.id === chargerId)?.name ??
    t_generic('literals.charger', { titleize: true });

  return (
    <>
      <TabLabel label={chargerName} />
      <ChargerDetails chargers={devices.chargers} />
    </>
  );
};
