import { usePortalTranslation } from '@hooks/use-portal-translation';

import { LegendItem } from '@components/charts/LegendItem';

const colors = [
  ['bg-black', 'bg-[#77A3FC]', 'bg-[#FF9F8C]'],
  ['bg-[#686868]', 'bg-[#7ED6C6]', 'bg-[#E64997]'],
  ['bg-[#D6C918]', 'bg-[#2A35FF]', 'bg-[#C27121]']
];

const phases = [1, 2, 3];

export const CurrentDeliveredLegend = () => {
  const { t } = usePortalTranslation();

  return (
    <div className="ml-[94px]">
      <div className="flex gap-6">
        <div>
          <LegendItem
            color="bg-[#00ACEC]"
            label={t('labels.entity_limit', {
              entity: t('literals.grid'),
              titleize: true
            })}
          />
        </div>
        {phases.map(phase => (
          <div className="flex flex-col gap-3" key={phase}>
            <LegendItem
              color={colors[0][phase - 1]}
              label={`${t('labels.entity_consumption', { entity: t('literals.charger_other'), titleize: true })} - ${t('literals.phase')} ${phase}`}
            />
            <LegendItem
              color={colors[1][phase - 1]}
              label={`${t('labels.entity_load', { entity: t('literals.residual'), titleize: true })} - ${t('literals.phase')} ${phase}`}
            />
            <LegendItem
              color={colors[2][phase - 1]}
              label={`${t('literals.sum', { titleize: true })} - ${t('literals.phase')} ${phase}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
