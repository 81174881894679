import { usePortalTranslation } from '@hooks/use-portal-translation';

import { LegendItem } from '@components/charts/LegendItem';

export const ChargerCurrentDeliveredLegend = () => {
  const { t_generic, t } = usePortalTranslation('chargers');

  return (
    <div className="ml-[45px] flex gap-6">
      <LegendItem
        color="bg-black"
        label={`${t_generic('labels.entity_consumption', { entity: t_generic('literals.charger_other'), titleize: true })} - ${t_generic('literals.phase')} 1`}
      />
      <LegendItem
        color="bg-[#77A3FC]"
        label={`${t_generic('labels.entity_consumption', { entity: t_generic('literals.charger_other'), titleize: true })} - ${t_generic('literals.phase')} 2`}
      />
      <LegendItem
        color="bg-[#FF9F8C]"
        label={`${t_generic('labels.entity_consumption', { entity: t_generic('literals.charger_other'), titleize: true })} - ${t_generic('literals.phase')} 3`}
      />
      <LegendItem
        color="bg-[#E64997]"
        label={t('literals.state_of_charge', { titleize: true })}
      />
    </div>
  );
};
