import { NavLink } from 'react-router-dom';

import ArrowRight from '@icons/forward_16.svg';

import { Button } from '@destination/components';

export interface INavigationButton {
  label: string;
  to: string;
  className?: string;
  onClick?: () => void;
}

export const NavigationButton = ({
  label,
  to,
  className,
  onClick
}: INavigationButton) => {
  return (
    <NavLink to={to} className={className} onClick={onClick}>
      <Button icon={<ArrowRight />} variant="discreet" iconPosition="end">
        {label}
      </Button>
    </NavLink>
  );
};
