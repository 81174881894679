import useSWR from 'swr';

import { SITE_BY_ID_PATH } from '@api/paths';

import { Site } from '@omnis-pulse-types';

const useSite = (siteId?: string) => {
  const { data, error, isLoading, mutate } = useSWR<Site>(
    siteId ? SITE_BY_ID_PATH(siteId) : null
  );
  return {
    site: data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
};

export default useSite;
