import { subject } from '@casl/ability';
import { useAbility } from '@casl/react';

import { Action, ResourceType } from '@constants/Permissions';
import { AbilityContext } from '@context/AbilityContext';

import {
  Charger,
  OrganizationDetails,
  Site,
  UserOrganizationDetails
} from '@omnis-pulse-types';

type Resource = Charger | Site | OrganizationDetails | UserOrganizationDetails;

// The usePermission hook is a custom React hook designed to handle permission checks within a frontend application.
// It encapsulates the logic for determining whether a user has permission to perform a specific action on a given resource,
// based on the user's details and the defined abilities.
export const usePermission = () => {
  const ability = useAbility(AbilityContext);

  const checkPermission = (
    action: Action,
    resourceType: ResourceType,
    resource?: Resource,
    field?: string
  ) => {
    if (resource) {
      switch (resourceType) {
        case ResourceType.SITE:
          return ability.can(
            action,
            subject(resourceType, {
              organizationId: (resource as Site).organizationId
            }),
            field
          );
        case ResourceType.USER:
          return ability.can(
            action,
            subject(resourceType, {
              organizationId: (resource as UserOrganizationDetails)
                .organizationOmnisId
            }),
            field
          );
        default:
          return ability.can(action, resourceType, field);
      }
    }
    return ability.can(action, resourceType, field);
  };

  return checkPermission;
};
