import { UploadProvider } from '@context/hmi/UploadContext';

import { Typography } from '@destination/components';

import { HMICustomizationApplyTo } from './HMICustomizationApplyTo';
import { HMICustomizationFileInput } from './HMICustomizationFileInput';
import { HMICustomizationUploadActions } from './HMICustomizationUploadActions';

interface IHMICustomizationUpload {
  pageTitle: string;
  pageDescription: string;
  cardTitle: string;
  errorNotification: string;
  successNotification: string;
}

export const HMICustomizationUpload = ({
  pageTitle,
  pageDescription,
  cardTitle,
  errorNotification,
  successNotification
}: IHMICustomizationUpload) => {
  return (
    <UploadProvider>
      <div className="mb-12 max-w-lg">
        <Typography variant="pageTitle" className="mb-2">
          {pageTitle}
        </Typography>
        <Typography className="mb-4">{pageDescription}</Typography>
        <div className="mb-6">
          <HMICustomizationFileInput cardTitle={cardTitle} />
        </div>
        <HMICustomizationApplyTo />
      </div>
      <HMICustomizationUploadActions
        errorNotification={errorNotification}
        successNotification={successNotification}
      />
    </UploadProvider>
  );
};
