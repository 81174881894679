import { Outlet, useNavigate, useParams } from 'react-router-dom';

import useGateway from '@api/gateways/use-gateway';

import { IOption, Select, Typography } from '@destination/components';

import { Device } from '@omnis-pulse-types';

import { GatewayStatus } from './GatewayStatus';

export interface IGatewayDetails {
  gateways: Device[];
}

export const GatewayDetails = ({ gateways }: IGatewayDetails) => {
  const { gatewayId } = useParams();
  const navigate = useNavigate();

  const { gateway } = useGateway(gatewayId);

  const options: IOption[] = gateways
    .filter(gateway => gateway.id && gateway.name)
    .map(gateway => ({
      value: String(gateway.id),
      label: String(gateway.name)
    }));

  let content = (
    <Typography variant="sectionTitle">Terra Gateway Pro</Typography>
  );

  if (options.length > 1) {
    const selectedOption = options.find(
      option => String(option.value) === gatewayId
    );

    content = (
      <Select
        options={options}
        selected={selectedOption ?? options[0]}
        onChange={option => navigate(`${option.value}`)}
      />
    );
  }

  return (
    <>
      <div className="mb-6 mt-2 flex flex-col justify-between gap-6 md:flex-row [&>div:first-child]:w-[250px]">
        {content}
        {gateway && (
          <GatewayStatus
            data-testid="gateway-abb-service-connection-status"
            gateway={gateway}
          />
        )}
      </div>
      {gateway && <Outlet context={gateway} />}
    </>
  );
};
