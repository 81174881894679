import { useTranslation } from 'react-i18next';

import { CreateSiteForm } from '@components/sites/CreateSiteForm';

import { Typography } from '@destination/components';

export const Create = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.create'
  });

  return (
    <>
      <Typography variant="pageTitle" className="mb-6">
        {t('page_title')}
      </Typography>
      <CreateSiteForm />
    </>
  );
};
