export function getCountryName(locale: string, countryCode?: string): string {
  if (countryCode) {
    const countryName = new Intl.DisplayNames([locale], {
      type: 'region'
    }).of(countryCode);
    return countryName ?? '';
  }
  return '';
}

export function roundNumber(number: number, decimals: number): number {
  const factor = Math.pow(10, decimals);
  return Math.round(number * factor) / factor;
}
