import { rand, randNumber, randUuid } from '@ngneat/falso';
import { http, HttpResponse, JsonBodyType } from 'msw';
import { setupWorker } from 'msw/browser';

import { basePath } from '@constants/apiConstants';
import { ChargerMockFactory as Charger } from '@mocks/chargerMockFactory';
import { ChargerSessionMockFactory as ChargerSession } from '@mocks/chargerSessionMockFactory';
import { ChargerStatusTelemetryMockFactory as ChargerStatusTelemetry } from '@mocks/chargerStatusTelemetryMockFactory';
import { DocumentMockFactory as Document } from '@mocks/documentMockFactory';
import { EventLogMockFactory as EventLog } from '@mocks/eventLogMockFactory';
import { GatewayMockFactory as Gateway } from '@mocks/gatewayMockFactory';
import { HardwareModelMockFactory as HardwareModel } from '@mocks/hardwareModelMockFactory';
import { LoadManagementMockFactory as LoadManagement } from '@mocks/loadManagementMockFactory';
import { OrganizationMockFactory as Organization } from '@mocks/organizationMockFactory';
import { SiteCurrentDeliveredTelemetryMockFactory as currentDeliveredTelemetry } from '@mocks/siteCurrentDeliveredTelemetryMockFactory';
import { SiteCurrentTelemetryMockFactory as CurrentTelemetry } from '@mocks/siteCurrentTelemetryMockFactory';
import { SiteGridMeterMockFactory as GridMeter } from '@mocks/siteGridMeterMockFactory';
import { SiteMockFactory as Site } from '@mocks/siteMockFactory';
import { UserAccountDetailsFactory as UserAccount } from '@mocks/userAccountDetailsFactory';

const chargers = Charger.createChargers(randNumber({ min: 1, max: 9 }));
const gridMeters = [
  GridMeter.createSerialGridMeter(),
  GridMeter.createTcpGridMeter()
];
const gateways = rand([Gateway.createGateways(2)]);

const devices = {
  chargers: chargers.map(charger => ({ id: charger.id, name: charger.name })),
  gridMeters: gridMeters.map((gridMeter, idx) => ({
    id: randUuid(),
    name: `Gateway ${idx + 1}`
  })),
  gateways: gateways.map((gateway, idx) => ({
    id: gateway.id,
    name: `Gateway ${idx + 1}`
  }))
};

const handler = <T>(path: string, data: T) =>
  http.get(basePath + path, () => HttpResponse.json(data as JsonBodyType));

const putHandler = (path: string) =>
  http.put(basePath + path, () => new HttpResponse(null, { status: 204 }));

const patchHandler = (path: string) =>
  http.patch(basePath + path, () => new HttpResponse(null, { status: 204 }));

const postHandler = (path: string) =>
  http.post(basePath + path, () => new HttpResponse(null, { status: 204 }));

const deleteHandler = (path: string) =>
  http.delete(basePath + path, () => new HttpResponse(null, { status: 204 }));

// prettier-ignore
const siteHandlers = [
    handler('/sites', {data: Site.createSites(randNumber({min: 1, max: 9}))}),
    handler('/sites/:id', Site.createSite()),
    handler('/sites/:id/devices',devices),
    handler('/sites/:id/chargers', {data: chargers}),
    handler('/sites/:id/load-management', LoadManagement.createSiteLoadManagement()),
    handler('/sites/:id/telemetry/current', [{
        charger: Charger.createCharger().id,
        telemetry: CurrentTelemetry.createCurrentTelemetry()
    }]),
    handler('/sites/:id/telemetry/current-delivered', currentDeliveredTelemetry.createSiteCurrentDeliveredTelemetry()),
    handler('/sites/:id/grid-meters', gridMeters ),
    handler('/sites/:id/grid-meters/:id', rand(gridMeters)),
    handler('/sites/:id/gateways', gateways),
    // handler('/sites/:id/gateways', rand([[], Gateway.createGateways(1), Gateway.createGateways(2)])),
    handler('/sites/:id/grid-meter/telemetry/current', CurrentTelemetry.createCurrentTelemetry()),
    handler('/sites/:id/events', {data: EventLog.createEventLog(5)}),
    putHandler('/sites/:id'),
    deleteHandler('/sites/:id')
];

// prettier-ignore
const chargerHandlers = [
    handler('/chargers', {data: Charger.createChargers(randNumber({min: 1, max: 9}))}),
    handler('/chargers/:id', Charger.createChargerDetails()),
    handler('/chargers/:id/sessions/active-sessions', [ChargerSession.createActiveChargerSession()]),
    handler('/chargers/:id/sessions/finished-sessions', {
        data: ChargerSession.createFinishedChargerSessions(randNumber({
            min: 1,
            max: 9
        }))
    }),
    handler('/chargers/:id/connection-status', Charger.createCharger().connectionStatus),
    handler('/chargers/:id/events', {data: EventLog.createEventLog(randNumber({min: 1, max: 9}))}),
    handler('/chargers/:id/telemetry/status', ChargerStatusTelemetry.createChargerStatusTelemetry()),
    postHandler('/chargers/:id/actions'),
];

// prettier-ignore
const userAccountHandlers = [
    handler('/user-account/me', UserAccount.createUserAccountDetails()),
    handler('/user-account/organization', Organization.createOrganization()),
    handler('/user-account/organization/users', {
        data: Organization.createOrganizationUsers(randNumber({
            min: 1,
            max: 9
        }))
    }),
    patchHandler('/user-account/organization/users/:id'),
];

// prettier-ignore
const gatewayHandlers = [
  handler('/gateways/:id', Gateway.createGateway()),
  handler('/gateways/:id/chargers', {data: Charger.createChargers(randNumber({min: 1, max: 9}))}),
];

// prettier-ignore
const gridMeterHandlers = [
  handler('/grid-meters/:id', rand(gridMeters)),
];

// prettier-ignore
const documentHandles = [
  http.get(basePath + '/documents', ({ request }) => {
    // Construct a URL instance out of the intercepted request.
    const url = new URL(request.url)
    const documentType = url.searchParams.get('type');
    const pageSize = Number(url.searchParams.get('pageSize') ?? randNumber({ min: 5, max: 20 }));
    const pageOffset = Number(url.searchParams.get('pageOffset'));
 
    if (documentType === 'InstanceGraph') {
      return HttpResponse.json({ data: Document.createDocuments(pageSize, { documentType: 'InstanceGraph' }), pageSize, pageOffset: pageOffset })
    } else if (documentType === 'CustomerPreferences') {
      return HttpResponse.json({ data: Document.createDocuments(pageSize, { documentType: 'CustomerPreferences' }), pageSize, pageOffset: pageOffset })
    } else if (documentType === 'PropertyOverrides') {
      return HttpResponse.json({ data: Document.createDocuments(pageSize, { documentType: 'PropertyOverrides' }), pageSize, pageOffset: pageOffset })
    }

    return new HttpResponse(null, { status: 404 });
  })
];

// prettier-ignore
const otherHandlers = [
    handler('/hardware-models', HardwareModel.createHardwareModels(randNumber({min: 1, max: 9})))
];

export const mswWorker = setupWorker(
  ...siteHandlers,
  ...chargerHandlers,
  ...userAccountHandlers,
  ...gatewayHandlers,
  ...gridMeterHandlers,
  ...documentHandles,
  ...otherHandlers
);
