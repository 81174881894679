import { useYAxisTickContext } from '@context/YAxisTickContext';
import { formatDate, timeFormat } from '@utils/date-utils';

import { TooltipItem } from './CustomTooltipItem';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomTooltip = (props: any) => {
  const { getNearestDataPoint } = useYAxisTickContext();

  if (!props.active || !props.payload?.length) {
    return null;
  }

  const nearest = getNearestDataPoint({
    y: props.coordinate.y,
    dataPoints: props.payload
  });

  if (!nearest) {
    return null;
  }

  const transformValue = nearest[0].y - props.coordinate.y;

  return (
    <div
      className="relative max-w-[180px] rounded-[4px] bg-white p-2 drop-shadow-[0_0_15px_rgba(0,0,0,0.2)]"
      style={{
        transform: `translate(12px, calc(${transformValue}px - 50% - 0.5px))`
      }}
    >
      <div className="absolute left-0 top-1/2 h-2.5 w-2.5 -translate-x-1/2 -translate-y-1/2 rotate-45 rounded-bl-sm bg-white" />
      <div className="flex flex-col gap-2">
        <TooltipItem
          label="Time stamp"
          value={formatDate(props.label, timeFormat)}
        />
        {nearest.map(item => (
          <TooltipItem
            key={item.dataKey}
            label={item.name}
            value={item.value}
            stroke={item.stroke}
            unit={item.unit}
          />
        ))}
      </div>
    </div>
  );
};
