import useSWR from 'swr';

import { USER_ORGANIZATIONS_PATH } from '@api/paths';

import { UserOrganizationDetails } from '@omnis-pulse-types';

export default function useUserOrganizations() {
  const { data, error, isLoading } = useSWR<UserOrganizationDetails[]>(
    USER_ORGANIZATIONS_PATH
  );

  return { organizations: data, isLoading, isError: Boolean(error) };
}
