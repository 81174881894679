import { useLocation, useParams } from 'react-router-dom';

import useSite from '@api/sites/use-site';
import { BreadcrumbComponent } from '@components/breadcrumbs/BreadcrumbComponent';

export const SiteBreadcrumbs = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const { site, isLoading } = useSite(id);

  return (
    <BreadcrumbComponent
      basePath={'/sites'}
      isLoading={isLoading}
      pathname={pathname}
      siteName={site?.label}
    />
  );
};
