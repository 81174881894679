import { Link } from 'react-router-dom';

import DeleteIcon from '@icons/delete_16.svg';
import DownloadIcon from '@icons/download_16.svg';
import EditIcon from '@icons/edit_16.svg';
import { dateTimeFormat, formatDate } from '@utils/date-utils';

import { Grid, IconButton } from '@destination/components';

import { HmiDocument } from '@omnis-pulse-types';

export interface IHMICustomizationTableRow {
  index: number;
  document: HmiDocument;
  onDelete: () => void;
  onDownload: () => void;
  onEdit: () => void;
}

export const HMICustomizationTableRow = ({
  index,
  document,
  onDelete,
  onDownload,
  onEdit
}: IHMICustomizationTableRow) => {
  // todo: Make sure the documentId and createdAt are strings
  const {
    documentId = '',
    name,
    filename,
    createdAt = '',
    chargers = []
  } = document;

  return (
    <Grid.TableRow
      alternateRowColor={index % 2 === 0}
      data-testid={`customization-table-row-${index}`}
    >
      <Grid.DataCell data-testid={`customization-table-row-${index}-name`}>
        <Link
          to={documentId}
          className="decoration-2 before:invisible before:block before:h-0 before:overflow-hidden before:font-bold before:content-[attr(data-text)] hover:font-bold hover:underline"
          data-text={name}
        >
          {name}
        </Link>
      </Grid.DataCell>
      <Grid.DataCell data-testid={`customization-table-row-${index}-filename`}>
        {filename}
      </Grid.DataCell>
      <Grid.DataCell
        className="text-nowrap"
        data-testid={`customization-table-row-${index}-createdAt`}
      >
        {formatDate(createdAt, dateTimeFormat)}
      </Grid.DataCell>
      {/* <Grid.DataCell>{''}</Grid.DataCell> */}
      <Grid.DataCell data-testid={`customization-table-row-${index}-chargers`}>
        {chargers.length}
      </Grid.DataCell>
      <Grid.DataCell data-testid={`customization-table-row-${index}-actions`}>
        <Actions onDelete={onDelete} onDownload={onDownload} onEdit={onEdit} />
      </Grid.DataCell>
    </Grid.TableRow>
  );
};

interface IActions {
  onDelete: () => void;
  onDownload: () => void;
  onEdit: () => void;
}

const Actions = ({ onDelete, onDownload, onEdit }: IActions) => {
  return (
    <div className="-my-[7px] flex gap-2">
      <IconButton data-testid="delete-icon" onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
      <IconButton data-testid="download-icon" onClick={onDownload}>
        <DownloadIcon />
      </IconButton>
      <IconButton data-testid="edit-icon" onClick={onEdit}>
        <EditIcon />
      </IconButton>
    </div>
  );
};
