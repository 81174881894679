import { useTranslation } from 'react-i18next';

import OfflineIcon from '@icons/offline_16.svg';
import OnlineIcon from '@icons/online_16.svg';
import { dateTimeFormat, formatDate } from '@utils/date-utils';

import { Tooltip } from '@destination/components';

import { ConnectionStatus, ConnectionStatusEnum } from '@omnis-pulse-types';

interface IConnectionStatusOverview {
  connectionStatus: ConnectionStatus;
}

export const ConnectionStatusOverview = ({
  connectionStatus
}: IConnectionStatusOverview) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site.devices.charger.connection_status'
  });

  let StatusIcon;
  let statusText;
  let statusClass = '';

  switch (connectionStatus.status) {
    case ConnectionStatusEnum.CONNECTED:
      StatusIcon = OnlineIcon;
      statusText = t('connected');
      break;
    case ConnectionStatusEnum.NOT_CONNECTED:
      StatusIcon = OfflineIcon;
      statusText = t('not_connected');
      break;
    default:
      StatusIcon = OfflineIcon;
      statusText = t('unknown');
      statusClass = 'font-bold';
  }

  return (
    <span data-testid="connection-status" className="flex items-center gap-1">
      <div
        className={`h-4 w-4 ${connectionStatus.status === ConnectionStatusEnum.UNKNOWN && 'opacity-20'}`}
      >
        <StatusIcon />
      </div>
      <Tooltip
        message={`${t('last_update')} ${formatDate(connectionStatus.timestamp, dateTimeFormat)}`}
      >
        <span className={statusClass}>{statusText}</span>
      </Tooltip>
    </span>
  );
};
