import { useSiteLoadManagementContext } from '@hooks/use-site-load-management-context';

import { DetailLineWrapper } from '@components/utils/DetailLineWrapper';

import { ActiveLoadManagementMode } from '@omnis-pulse-types';

import { FallbackChargingBudgetLimit } from './FallbackChargingBudgetLimit';
import { GridConnectionLimit } from './GridConnectionLimit';

export const DynamicLoadManagement = () => {
  const { data, isUpdating, update } = useSiteLoadManagementContext();

  if (!data) return null;

  const isDisabled =
    isUpdating || data.activeMode === ActiveLoadManagementMode.STATIC;

  return (
    <DetailLineWrapper className="mt-2">
      <GridConnectionLimit
        value={data.dynamicLoadManagement.gridConnectionLimit}
        activeMode={ActiveLoadManagementMode.DYNAMIC}
        isDisabled={isDisabled}
        onSave={update}
      />
      <FallbackChargingBudgetLimit
        value={data.dynamicLoadManagement.fallbackChargingBudgetLimit}
        maxValue={data.dynamicLoadManagement.gridConnectionLimit}
        isDisabled={isDisabled}
        onSave={update}
      />
    </DetailLineWrapper>
  );
};
