import { roundNumber } from '@utils/utils';

import { Typography } from '@destination/components';

interface ITooltipItem {
  label: string;
  value: number | string;
  stroke?: string;
  unit?: string;
}

export const TooltipItem = ({ label, value, stroke, unit }: ITooltipItem) => {
  const rounded = isNaN(Number(value)) ? value : roundNumber(Number(value), 1);

  return (
    <div className="flex">
      <div
        className="mr-[4px] mt-[4.5px] h-1.5 w-1.5 shrink-0 rounded-full"
        style={{ backgroundColor: stroke }}
      />
      <div className="flex w-full justify-between gap-2 pr-2.5">
        <Typography variant="smallText">{label}</Typography>
        <Typography
          className="self-end text-nowrap font-bold"
          variant="smallText"
        >
          {unit ? `${rounded} ${unit}` : rounded}
        </Typography>
      </div>
    </div>
  );
};
