import { Typography } from '@destination/components';

interface ILegendItem {
  color?: string;
  label: string;
  style?: object;
}

export const LegendItem = ({ color, label, style }: ILegendItem) => (
  <div className="flex items-center gap-1">
    <div
      data-testid="legend-color"
      className={`h-1.5 w-1.5 rounded-full ${color}`}
      style={style}
    ></div>
    <Typography variant="smallText">{label}</Typography>
  </div>
);
