import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DetailLine } from '@components/DetailsLine';

import { Card, Typography } from '@destination/components';

import { Gateway } from '@omnis-pulse-types';

export const GatewayProperties = ({ gateway }: { gateway: Gateway }) => {
  const { t } = usePortalTranslation();

  const title = t('labels.entity_properties', {
    entity: t('literals.gateway'),
    titleize: true
  });

  const firmwareLabel = t('labels.entity_version', {
    entity: t('literals.firmware'),
    titleize: true
  });

  return (
    <>
      <Typography variant="smallTitle" className="mb-[23px] mt-[7px]">
        {title}
      </Typography>
      <Card className="flex max-w-[512px] flex-col gap-4">
        <DetailLine
          label={t('literals.serial_number', { titleize: true })}
          data-testid="terra-gateway-pro-settings-line-serial-number"
        >
          <Typography variant="description" className="font-bold">
            {gateway.serialNumber}
          </Typography>
        </DetailLine>
        <DetailLine
          label={firmwareLabel}
          data-testid="terra-gateway-pro-settings-line-firmware-version"
        >
          <Typography variant="description" className="font-bold">
            v.{gateway.firmwareVersion}
          </Typography>
        </DetailLine>
      </Card>
    </>
  );
};
