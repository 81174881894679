import { TOptionsBase } from 'i18next';
import { $Dictionary } from 'i18next/typescript/helpers';
import { useTranslation } from 'react-i18next';

import { titleize } from '@utils/i18n-utils';

export type TOverloadedFunction = (
  key: string | TemplateStringsArray | (string | TemplateStringsArray)[],
  options?: TOptionsBase &
    $Dictionary & {
      titleize?: boolean;
    }
) => string;

export const usePortalTranslation = (
  namespace?: string
): { t: TOverloadedFunction; t_generic: TOverloadedFunction } => {
  const { t: t_generic } = useTranslation('generic');
  const { t } = useTranslation(namespace ?? 'generic');

  const overloadTFunction = (
    tFunction: (
      key: string | TemplateStringsArray | (string | TemplateStringsArray)[],
      options?: TOptionsBase & $Dictionary & { titleize?: boolean }
    ) => string
  ) => {
    return (
      key: string | TemplateStringsArray | (string | TemplateStringsArray)[],
      options?: TOptionsBase & $Dictionary & { titleize?: boolean }
    ) => {
      if (options?.titleize) {
        return titleize(tFunction(key, options));
      }

      return tFunction(key, options);
    };
  };

  return {
    t_generic: overloadTFunction(t_generic),
    t: overloadTFunction(t)
  };
};
