import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import useGridMeter from '@api/grid-meters/use-grid-meter';

import { IOption, Select, Typography } from '@destination/components';

import { Device } from '@omnis-pulse-types';

import { GridMeterStatus } from './GridMeterStatus';

export interface IGridMeterDetails {
  gridMeters: Device[];
}

export const GridMeterDetails = ({ gridMeters }: IGridMeterDetails) => {
  const { gridMeterId } = useParams();
  const navigate = useNavigate();

  const { t } = usePortalTranslation();

  const { gridMeter } = useGridMeter(gridMeterId);

  const options: IOption[] = gridMeters
    .filter(gridMeter => gridMeter.id && gridMeter.name)
    .map(gridMeter => ({
      value: String(gridMeter.id),
      label: String(gridMeter.name)
    }));

  let content = (
    <Typography variant="sectionTitle">
      {t('literals.grid_meter', { titleize: true })}
    </Typography>
  );

  if (options.length > 1) {
    const selectedOption = options.find(
      option => String(option.value) === gridMeterId
    );

    content = (
      <Select
        options={options}
        selected={selectedOption ?? options[0]}
        onChange={option => navigate(`${option.value}`)}
      />
    );
  }

  return (
    <>
      <div className="mb-[17px] mt-2 flex flex-col justify-between gap-6 md:flex-row [&>div:first-child]:w-[250px]">
        {content}
        {gridMeter && <GridMeterStatus gridMeter={gridMeter} />}
      </div>
      {gridMeter && <Outlet context={gridMeter} />}
    </>
  );
};
