import { Dot, Rectangle } from 'recharts';

interface ICustomCursor {
  points?: { x: number; y: number }[];
  height?: number;
}
export const CustomCursor = (props: ICustomCursor) => {
  if (!props.points) {
    return null;
  }
  const { x, y } = props.points[0];

  if (x === 0) {
    return null;
  }

  return (
    <>
      <Rectangle
        x={x}
        y={y - 6}
        stroke="#000000"
        width={0.1}
        height={(props.height ?? 0) + 6}
      />
      <Dot cx={x} cy={(props.height ?? 0) + 6} r={3} stroke="#000000" />
    </>
  );
};
