import { useSiteLoadManagementContext } from '@hooks/use-site-load-management-context';

import { DetailLineWrapper } from '@components/utils/DetailLineWrapper';

import { ActiveLoadManagementMode } from '@omnis-pulse-types';

import { ChargingBudgetLimit } from './ChargingBudgetLimit';
import { GridConnectionLimit } from './GridConnectionLimit';
import { SiteAuxiliaryCurrent } from './SiteAuxiliaryCurrent';

export const StaticLoadManagement = () => {
  const { data, isUpdating, update } = useSiteLoadManagementContext();

  if (!data) return null;

  const isDisabled =
    isUpdating || data.activeMode === ActiveLoadManagementMode.DYNAMIC;

  return (
    <DetailLineWrapper className="mt-2">
      <GridConnectionLimit
        value={data.staticLoadManagement.gridConnectionLimit}
        activeMode={ActiveLoadManagementMode.STATIC}
        isDisabled={isDisabled}
        onSave={update}
      />
      <SiteAuxiliaryCurrent
        value={data.staticLoadManagement.siteAuxiliaryCurrent}
        maxValue={data.staticLoadManagement.gridConnectionLimit}
        isDisabled={isDisabled}
        onSave={update}
      />
      <ChargingBudgetLimit
        value={data.staticLoadManagement.chargingBudgetLimit}
        isDisabled={isDisabled}
      />
    </DetailLineWrapper>
  );
};
