import useSWR from 'swr';

export interface ConfigData {
  omnis_UserManagementURL: string;
  'client-id': string;
  scope: string;
  AI_instrumentationKey: string;
}

export function useConfigData() {
  const { data, isLoading, error } = useSWR(
    '/config.json',
    async (url: string) => {
      const response = await fetch(url);
      const data: ConfigData = await response.json();
      return data;
    }
  );
  return { data, isLoading, error };
}
