import { Children, isValidElement, ReactElement, ReactNode } from 'react';

import { DetailLine, IDetailLine } from '@components/DetailsLine';

export interface IDetailLineWrapper {
  children: ReactNode;
  className?: string;
}

export const DetailLineWrapper = ({
  className,
  children
}: IDetailLineWrapper) => {
  const classes = `flex min-h-[56px] rounded-s px-3 py-2 ${className ?? ''}`;

  return (
    <>
      {Children.map(children, (child, idx) => {
        if (!isDetailLine(child))
          throw new Error(
            'DetailLineWrapper only accepts DetailLine components as children'
          );

        return (
          <div className={`${classes} ${idx % 2 === 0 && 'bg-[#FAFAFA]'}`}>
            {child}
          </div>
        );
      })}
    </>
  );
};

function isDetailLine(node: ReactNode): node is ReactElement<IDetailLine> {
  // if the node is a DetailLine component, return true
  if (isValidElement(node) && node.type === DetailLine) return true;

  // if the node is not a DetailLine component, check if it has a child
  const firstChild = Children.toArray(node)[0];
  if (!isValidElement(firstChild)) return false;

  // create a new instance of the first child and check if it is a DetailLine component
  const firstChildType = firstChild.type as React.FunctionComponent;
  const detailLine = Children.toArray(firstChildType(firstChild.props))[0];

  return isValidElement(detailLine) && detailLine.type === DetailLine;
}
