import { useConfigData } from './use-config-data';

export function useOmnisRedirectUrl() {
  const { data, isLoading, error } = useConfigData();

  return {
    data: data ? data['omnis_UserManagementURL'] : '',
    isLoading,
    error
  };
}
