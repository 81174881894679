import useSWR from 'swr';

import { SITE_BY_ID_PATH } from '@api/paths';

import { Gateway } from '@omnis-pulse-types';

const useSiteGateways = (siteId?: string) => {
  const { data, error, isLoading, mutate } = useSWR<Gateway[]>(
    siteId ? `${SITE_BY_ID_PATH(siteId)}/gateways` : null
  );
  return {
    gateways: data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
};

export default useSiteGateways;
