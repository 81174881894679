import { ReactNode } from 'react';

import useFixMissingScroll from '@hooks/use-fix-missing-scroll';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import EllipseIcon from '@icons/ellipse_16.svg';
import { defaultPageSize } from '@utils/infinite-loading-utils';

import { Typography } from '@destination/components';

export const InfiniteScrollTable = ({
  dataLength,
  onNextPage,
  isLoadingMore,
  hasReachedEnd,
  children
}: {
  dataLength: number;
  onNextPage: () => void;
  isLoadingMore: boolean;
  hasReachedEnd: boolean;
  children: ReactNode;
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infinite_scroll' });

  useFixMissingScroll(!hasReachedEnd, dataLength, isLoadingMore, onNextPage);

  return (
    <InfiniteScroll
      style={{ overflow: 'unset' }}
      dataLength={dataLength}
      next={onNextPage}
      hasMore={!hasReachedEnd}
      loader={
        isLoadingMore && (
          <div className="mb-4 mt-8 flex justify-center gap-2">
            <EllipseIcon className="animate-spin" />
            <Typography variant="description">{t('loading_more')}</Typography>
          </div>
        )
      }
      scrollableTarget="main-content"
      endMessage={
        <div
          className={`mt-4 justify-center ${
            dataLength > Number(defaultPageSize) ? 'flex' : 'hidden'
          }`}
        >
          <Typography
            variant="description"
            className="max-w-[211px] text-center"
          >
            {t('end_reached')}
          </Typography>
        </div>
      }
    >
      {children}
    </InfiniteScroll>
  );
};
