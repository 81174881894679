import useSWR from 'swr';

import { UserNotificationSettings } from '@omnis-pulse-types';

import { USER_NOTIFICATION_SETTINGS_PATH } from '../paths';

export default function useUserNotificationSettings() {
  const { data, error, isLoading, mutate } = useSWR<UserNotificationSettings>(
    USER_NOTIFICATION_SETTINGS_PATH
  );

  return {
    userNotificationSettings: data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
