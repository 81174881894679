import { AuthorizationSource } from '@omnis-pulse-types';

export const authSourceMapper = (authSource: AuthorizationSource): string => {
  switch (authSource) {
    case AuthorizationSource.RFID:
      return 'RFID Card';
    case AuthorizationSource.FREE_VENDING:
      return 'Free Vending';
    case AuthorizationSource.ABB_MOBILE_APP:
      return 'ChargerSync app';
    case AuthorizationSource.ABB_CSMS:
      return 'CSMS Portal';
    case AuthorizationSource.NFC:
      return 'NFC';
    case AuthorizationSource.PLUG_AND_CHARGE:
      return 'Plug & Charge';
    default:
      return 'Unknown';
  }
};
