import { useEffect } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useParams } from 'react-router-dom';

import useSiteDevices from '@api/sites/use-site-devices';
import { GridMeterDetails } from '@components/grid-meters/GridMeterDetails';
import { TabLabel } from '@components/TabLabel';

import {
  DataError,
  LoadingSkeleton,
  NothingConfigured,
  Typography
} from '@destination/components';

export const GridMeters = () => {
  const { id, gridMeterId } = useParams();
  const navigate = useNavigate();
  const { t, t_generic } = usePortalTranslation('gridMeters');

  const { devices, isLoading, isError, mutate } = useSiteDevices(id);

  useEffect(() => {
    if (!gridMeterId && devices?.gridMeters?.length) {
      navigate(`${devices.gridMeters[0].id}`);
    }
  }, [devices?.gridMeters, gridMeterId, navigate]);

  if (isError) {
    return (
      <div className="flex h-full w-full items-center">
        <DataError onRefresh={() => mutate(undefined, true)} />
      </div>
    );
  }

  if (isLoading) {
    return <LoadingSkeleton className="!h-full" height={0} />;
  }

  if (!devices || devices.gridMeters.length === 0) {
    return (
      <NothingConfigured
        title={t_generic('labels.no_configured', {
          entity: t_generic('literals.grid_meter_other')
        })}
        className="my-auto"
        icon={null}
      >
        <Typography
          className="tw-max-w-[263px] tw-text-center"
          variant="description"
        >
          {t('errors.no_configured_message')}
        </Typography>
      </NothingConfigured>
    );
  }

  const gridMeterName =
    devices.gridMeters.find(gridMeter => gridMeter.id === gridMeterId)?.name ??
    t_generic('literals.grid_meter', { titleize: true });

  return (
    <>
      <TabLabel label={gridMeterName} />
      <GridMeterDetails gridMeters={devices.gridMeters} />
    </>
  );
};
