import { useMemo } from 'react';

import { useFilter } from '@hooks/use-filter';
import { usePortalTranslation } from '@hooks/use-portal-translation';

import { FilterButton, IOption } from '@destination/components';

import { UserState } from '@omnis-pulse-types';

export const UsersFilterButtons = () => {
  const { t } = usePortalTranslation();

  const statusOptions = useMemo(() => {
    return Object.values(UserState).map(
      (status): IOption => ({
        value: status,
        label: t(`states.${UserState[status].toLowerCase()}`, {
          titleize: true
        })
      })
    );
  }, [t]);

  const {
    selectedOptions: selectedStatusOptions,
    handleFilter: handleStatusFilter
  } = useFilter('status', statusOptions);

  return (
    <div>
      <FilterButton
        options={statusOptions}
        onSelect={handleStatusFilter}
        selected={selectedStatusOptions}
        singleFilter={true}
      >
        {t(
          selectedStatusOptions.length > 0
            ? `states.${selectedStatusOptions[0].value.toString().toLowerCase()}`
            : 'literals.status',
          { titleize: true }
        )}
      </FilterButton>
    </div>
  );
};
