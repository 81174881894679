import CCSIcon from '@icons/ccs_16.svg';
import ChademoIcon from '@icons/chademo_16.svg';
import ACIcon from '@icons/type2_16.svg';

import { SocketTypeEnum } from '@omnis-pulse-types';

export const getSocketTypeIcons = (type: string) => {
  switch (type) {
    case SocketTypeEnum.CCS:
      return <CCSIcon data-testid="ccs-icon" />;
    case SocketTypeEnum.CHADEMO:
      return <ChademoIcon data-testid="chademo-icon" />;
    case SocketTypeEnum.AC:
      return <ACIcon data-testid="ac-icon" />;
    default:
      return <></>;
  }
};
