import useSWR from 'swr';

import { CHARGER_BY_ID_PATH } from '@api/paths';

import { ChargerActiveSession } from '@omnis-pulse-types';

export default function useChargerActiveSessions(chargerId?: string) {
  const { data, error, isLoading, mutate } = useSWR<ChargerActiveSession[]>(
    chargerId
      ? `${CHARGER_BY_ID_PATH(chargerId)}/sessions/active-sessions`
      : null
  );

  return {
    sessions: data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
