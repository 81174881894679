import useSWR from 'swr';

import { CHARGER_BY_ID_PATH } from '@api/paths';

import { PaginatedChargerFinishedSession } from '@omnis-pulse-types';

export default function useChargerFinishedSessions(
  chargerId: string,
  cursor?: string,
  pageSize = 20
) {
  const params = new URLSearchParams({ pageSize: pageSize.toString() });

  if (cursor) {
    params.append('pagesState', cursor);
  }

  const url = `${CHARGER_BY_ID_PATH(
    chargerId
  )}/sessions/finished-sessions?${params.toString()}`;

  const { data, error, isLoading, mutate } =
    useSWR<PaginatedChargerFinishedSession>(url);

  return {
    sessions: data?.data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
