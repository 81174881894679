import { rand } from '@ngneat/falso';
import { addHours, startOfHour } from 'date-fns';

import {
  ChargerStatusTelemetry,
  ConnectionStatusEnum,
  EvseStatusEnum,
  EvseStatusTelemetry
} from '@omnis-pulse-types';

export class ChargerStatusTelemetryMockFactory {
  static createChargerStatusTelemetry(
    data?: Partial<ChargerStatusTelemetry>
  ): ChargerStatusTelemetry {
    return {
      evseStatuses: this.generateEvseStatusDataForPast24Hours(),
      connectionStatus: this.generateConnectionStatusDataForPast24Hours(),
      ...data
    };
  }

  static generateEvseStatusDataForPast24Hours(
    numberOfEvsesArg?: 1 | 2
  ): EvseStatusTelemetry[] {
    const data: EvseStatusTelemetry[] = [];

    const numberOfEvses = numberOfEvsesArg ?? rand([1, 2]);
    let date = startOfHour(addHours(new Date(), -23));
    let status1 = rand(Object.values(EvseStatusEnum));
    let status2 = rand(Object.values(EvseStatusEnum));

    while (date <= new Date()) {
      const evses = [];
      for (let i = 1; i <= numberOfEvses; i++) {
        const status = i === 1 ? status1 : status2;
        evses.push({
          id: i,
          status,
          sockets: [],
          timestamp: date.toISOString()
        });
      }

      data.push({
        timestamp: date.toISOString(),
        statuses: evses
      });

      status1 = rand(Object.values(EvseStatusEnum).filter(s => s !== status1));
      status2 = rand(Object.values(EvseStatusEnum).filter(s => s !== status2));
      date = addHours(date, 1);
    }

    return data;
  }

  static generateConnectionStatusDataForPast24Hours() {
    const data = [];

    let date = startOfHour(addHours(new Date(), -23));
    let status = rand(Object.values(ConnectionStatusEnum));

    while (date <= new Date()) {
      data.push({
        timestamp: date.toISOString(),
        status
      });

      status = rand(
        Object.values(ConnectionStatusEnum).filter(s => s !== status)
      );
      date = addHours(date, 1);
    }

    return data;
  }
}
