import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowIcon from '@icons/arrow_16.svg';
import DeleteIcon from '@icons/delete_16.svg';
import EditIcon from '@icons/edit_16.svg';

import {
  Button,
  Drawer,
  IconButton,
  Typography
} from '@destination/components';

interface IHMICustomizationDrawer {
  closeDrawerLink: string;
}

export const HMICustomizationDrawer = ({
  closeDrawerLink
}: IHMICustomizationDrawer) => {
  const { customizationId } = useParams();
  const navigate = useNavigate();

  const { t } = usePortalTranslation();

  const handleClose = () => {
    navigate(closeDrawerLink);
  };

  const description = t('labels.entity_preferences', {
    titleize: true,
    entity: t('literals.customer')
  });

  return (
    <Drawer
      title="My custom theme"
      isOpen={customizationId !== undefined}
      onClose={handleClose}
    >
      <div className="-mt-[23px]">
        <Typography className="mb-6">{description}</Typography>
        <Actions onDelete={() => null} onEdit={() => null} />
        <hr className="mb-4 mt-6 h-px bg-[#EBEBEB]" />
        <ExpandableSite />
        <hr className="my-4 h-px bg-[#EBEBEB]" />
        <ExpandableSite />
        <hr className="my-4 h-px bg-[#EBEBEB]" />
        <ExpandableSite />
      </div>
    </Drawer>
  );
};

interface IActions {
  onDelete: () => void;
  onEdit: () => void;
}

const Actions = ({ onDelete, onEdit }: IActions) => {
  const { t } = usePortalTranslation();

  const deleteLabel = t('buttons.delete', {
    entity: '',
    titleize: true
  });
  const editLabel = t('buttons.edit', {
    entity: '',
    titleize: true
  });

  return (
    <div className="flex justify-center gap-6">
      <Button
        variant="ghost"
        icon={<DeleteIcon />}
        size="big"
        onClick={onDelete}
      >
        {deleteLabel}
      </Button>
      <Button variant="ghost" icon={<EditIcon />} size="big" onClick={onEdit}>
        {editLabel}
      </Button>
    </div>
  );
};

const ExpandableSite = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <div className="flex items-center justify-between">
        <Typography
          variant="smallTitle"
          className="cursor-pointer"
          onClick={() => setIsExpanded(previousValue => !previousValue)}
        >
          Site 1
        </Typography>
        <IconButton
          onClick={() => setIsExpanded(previousValue => !previousValue)}
        >
          <ArrowIcon
            className={`transition-all duration-300 ${isExpanded ? '' : 'rotate-180'}`}
          />
        </IconButton>
      </div>
      {isExpanded ? (
        <div className="mt-2 flex flex-col gap-3">
          <Typography>Charger 1</Typography>
          <Typography>Charger 2</Typography>
          <Typography>Charger 3</Typography>
          <Typography>Charger 4</Typography>
          <Typography>Charger 5</Typography>
          <Typography>Charger 6</Typography>
        </div>
      ) : (
        <Typography>20 chargers</Typography>
      )}
    </>
  );
};
