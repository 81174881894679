import { usePortalTranslation } from '@hooks/use-portal-translation';

import { useUploadContext } from '@context/hmi/UploadContext';
import { validateJSONFile } from '@utils/file-utils';

import {
  Card,
  FileUploadDropzone,
  Input,
  Typography
} from '@destination/components';

import { HMICustomizationFileInputCurrentFile } from './HMICustomizationFileInputCurrentFile';

interface IHMICustomizationFileInput {
  cardTitle: string;
}

export const HMICustomizationFileInput = ({
  cardTitle
}: IHMICustomizationFileInput) => {
  const { t, t_generic } = usePortalTranslation('hmi');
  const { file, setFile, isFileValid, setIsFileValid, setName, isProcessing } =
    useUploadContext();

  const handleUpload = async (file: File) => {
    setIsFileValid(await validateJSONFile(file));
    setFile(file);
  };

  return (
    <Card>
      <div className="my-2">
        <Typography variant="smallTitle" className="mb-1.5">
          {cardTitle}
        </Typography>
        <Typography variant="smallText" className="mb-[35px]">
          {t('labels.only_one_file')}
        </Typography>
        <div className="mb-6 max-w-[342px]">
          <Input
            data-testid="name"
            id="name"
            label={t_generic('literals.name', { titleize: true })}
            disabled={isProcessing}
            onChange={event => setName(event.target.value)}
          />
        </div>
        <FileUploadDropzone
          onUpload={handleUpload}
          buttonLabel={t_generic('buttons.select', {
            titleize: true,
            entity: t_generic('literals.file')
          })}
          title={t_generic('file_upload.drag_and_drop')}
          description={t_generic('file_upload.or_select_from_files')}
          inputProps={{ accept: 'application/JSON,application/json' }}
          disabled={isProcessing}
        />
        {file && (
          <HMICustomizationFileInputCurrentFile
            name={file.name}
            size={file.size}
            isError={isFileValid === false}
            disabled={isProcessing}
            onDelete={() => setFile(undefined)}
          />
        )}
      </div>
    </Card>
  );
};
