import { randNumber } from '@ngneat/falso';

import { enumValueFaker, makeArray } from '@utils/faker-utils';

import {
  SocketStatus,
  SocketStatusEnum,
  SocketTypeEnum
} from '@omnis-pulse-types';

export class ChargerSocketStatusMockFactory {
  static createMockChargerSocketStatus(
    data?: Partial<SocketStatus>
  ): SocketStatus {
    return {
      status: enumValueFaker(SocketStatusEnum),
      id: randNumber(),
      type: enumValueFaker(SocketTypeEnum),
      ...data
    };
  }

  static createMockChargerSocketStatuses(
    numberOfSockets: number,
    data?: Partial<SocketStatus>
  ): SocketStatus[] {
    return makeArray(numberOfSockets, () =>
      this.createMockChargerSocketStatus(data)
    );
  }
}
