import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react';

import { useDebounce } from 'use-debounce';

export interface UploadContextType {
  selected: Set<string>;
  setSelected: Dispatch<SetStateAction<Set<string>>>;
  file: File | undefined;
  setFile: Dispatch<SetStateAction<File | undefined>>;
  isFileValid: boolean;
  setIsFileValid: Dispatch<SetStateAction<boolean>>;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  isProcessing: boolean;
  setIsProcessing: Dispatch<SetStateAction<boolean>>;
}

const UploadContext = createContext<UploadContextType | null>(null);

export const UploadProvider = ({ children }: { children: ReactNode }) => {
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [file, setFile] = useState<File>();
  const [isFileValid, setIsFileValid] = useState(true);
  const [name, setName] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const [debouncedName] = useDebounce(name, 500);

  const contextValue = useMemo(() => {
    return {
      selected,
      setSelected,
      file,
      setFile,
      isFileValid,
      setIsFileValid,
      name: debouncedName,
      setName,
      isProcessing,
      setIsProcessing
    };
  }, [selected, file, isFileValid, debouncedName, isProcessing]);

  return (
    <UploadContext.Provider value={contextValue}>
      {children}
    </UploadContext.Provider>
  );
};

export const useUploadContext = () => {
  const context = useContext(UploadContext);

  if (!context) {
    throw new Error('useUploadContext must be used within a UploadProvider');
  }

  return context;
};
