import useSWR from 'swr';

import { CHARGER_BY_ID_PATH } from '@api/paths';

import { ChargerDetails } from '@omnis-pulse-types';

export default function useCharger(chargerId?: string) {
  const { data, error, isLoading, mutate } = useSWR<ChargerDetails>(
    chargerId ? CHARGER_BY_ID_PATH(chargerId) : null
  );
  return { charger: data, isLoading, isError: Boolean(error), mutate };
}
