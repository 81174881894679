import { Checkbox, TableDataCell } from '@destination/components';

export interface IHMICustomizationApplyToTableRow {
  name: string;
  serial: string;
  model: string;
  checked: boolean;
  disabled: boolean;
  onToggle: () => void;
}

export const HMICustomizationApplyToTableRow = ({
  name,
  serial,
  model,
  checked,
  disabled,
  onToggle
}: IHMICustomizationApplyToTableRow) => {
  return (
    <>
      <TableDataCell
        className={`border-b-[1px] border-[#DBDBDB] !pl-3 ${checked && 'font-bold'} flex items-center gap-2`}
        title={name}
      >
        <div className="inline-flex items-center">
          <Checkbox
            label={undefined}
            checked={checked}
            disabled={disabled}
            onChange={onToggle}
          />
        </div>
        <button className="truncate" disabled={disabled} onClick={onToggle}>
          {name}
        </button>
      </TableDataCell>
      <TableDataCell
        className="truncate border-b-[1px] border-[#DBDBDB]"
        title={serial}
      >
        {serial}
      </TableDataCell>
      <TableDataCell
        className="truncate border-b-[1px] border-[#DBDBDB]"
        title={model}
      >
        {model}
      </TableDataCell>
    </>
  );
};
