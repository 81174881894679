import {
  eachHourOfInterval,
  getHours,
  getMinutes,
  getTime,
  setHours,
  setMinutes,
  startOfDay
} from 'date-fns';

export const getTicksForInterval = (domain: [number, number]) => {
  const [start, end] = domain;
  const ticks = eachHourOfInterval({ start, end })
    .filter((_, index) => index % 2 === 0)
    .map(dateObject => getTime(dateObject));

  // If there are less than 2 ticks, return the start and end
  if (ticks.length < 2) {
    return [start, end];
  }

  // Otherwise, set the first and last tick to the start and end
  ticks[0] = start;
  ticks[ticks.length - 1] = end;

  return ticks;
};

export const setStartAndEndTimes = (
  baseDate: Date,
  timeRange: [Date, Date]
): [Date, Date] => {
  let start = startOfDay(baseDate);
  let end = startOfDay(baseDate);

  start = setHours(start, getHours(timeRange[0]));
  start = setMinutes(start, getMinutes(timeRange[0]));

  end = setHours(end, getHours(timeRange[1]));
  end = setMinutes(end, getMinutes(timeRange[1]));

  return [start, end];
};
