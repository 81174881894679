import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DetailLine } from '@components/DetailsLine';

import { Typography } from '@destination/components';

import { Variant } from '@omnis-pulse-types';

export const GridMeterConfiguration = ({
  type,
  variant
}: {
  type: string;
  variant: Variant;
}) => {
  const { t } = usePortalTranslation();
  return (
    <>
      <Typography variant="sectionTitle" className="mb-4">
        {t('literals.configuration', { titleize: true })}
      </Typography>
      <div className="mb-4 flex flex-col [&>div:nth-child(even)]:bg-[#FAFAFA]">
        <DetailLine
          label={t('literals.type', { titleize: true })}
          className="flex min-h-[50px] max-w-[588px] items-start px-3"
          data-testid="grid-meter-settings-line-type"
        >
          <Typography variant="smallTitle">{type}</Typography>
        </DetailLine>
        <DetailLine
          label={t('literals.variant', { titleize: true })}
          className="flex min-h-[50px] max-w-[588px] items-start px-3"
          data-testid="grid-meter-settings-line-variant"
        >
          <Typography variant="smallTitle">{variant}</Typography>
        </DetailLine>
      </div>
    </>
  );
};
