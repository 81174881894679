import { ReactElement } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import ErrorIcon from '@icons/red_error_16.svg';
import WarningIcon from '@icons/warning_16.svg';
import { dateTimeFormat, formatDate } from '@utils/date-utils';

import {
  DataError,
  Grid,
  TableLoading,
  Typography
} from '@destination/components';

import { EventLogRecord } from '@omnis-pulse-types';

export interface ILogsTable {
  logs: EventLogRecord[];
  isLoading: boolean;
  isError: boolean;
  displayChargerIdentification?: boolean;
}

export const LogsTable = ({
  logs,
  isLoading,
  isError,
  displayChargerIdentification
}: ILogsTable) => {
  const { t } = usePortalTranslation();

  if (isError === true) {
    return <DataError />;
  }

  if (isLoading === false && isError === false && logs.length === 0) {
    return (
      <div className="flex justify-center text-center">
        <Typography className="max-w-[211px]">
          {t('labels.entity_not_found', { entity: t('literals.log_other') })}
        </Typography>
      </div>
    );
  }

  let className = 'grid-cols-[auto_auto_auto_auto_auto_auto_auto]';
  if (!displayChargerIdentification) {
    className =
      'grid-cols-[minmax(175px,auto)_minmax(175px,auto)_minmax(175px,auto)_minmax(175px,auto)_1fr]';
  }

  return (
    <>
      <Grid data-testid="logs-table" className={className}>
        <Header displayChargerIdentification={displayChargerIdentification} />
        {isLoading === false && (
          <Content
            logs={logs}
            displayChargerIdentification={displayChargerIdentification}
          />
        )}
      </Grid>
      {isLoading && <TableLoading className="mt-3" />}
    </>
  );
};

const Header = ({
  displayChargerIdentification
}: {
  displayChargerIdentification?: boolean;
}) => {
  const { t } = usePortalTranslation();

  return (
    <>
      <Grid.HeadCell>{t('literals.timestamp')}</Grid.HeadCell>
      <Grid.HeadCell>{t('literals.level')}</Grid.HeadCell>
      <Grid.HeadCell>{t('literals.code')}</Grid.HeadCell>
      <Grid.HeadCell>{t('literals.source')}</Grid.HeadCell>
      {displayChargerIdentification && (
        <>
          <Grid.HeadCell>
            {t('labels.entity_serial_number', {
              entity: t('literals.charger')
            })}
          </Grid.HeadCell>
          <Grid.HeadCell>{t('literals.instance_path')}</Grid.HeadCell>
        </>
      )}
      <Grid.HeadCell>{t('literals.message')}</Grid.HeadCell>
    </>
  );
};

const LevelLabel = ({ level }: { level: string }) => {
  const { t } = usePortalTranslation();
  const levelIcons: { [key: string]: ReactElement } = {
    info: <div className="w-[18px]"></div>,
    error: <ErrorIcon className="text-[#BABABA]" />,
    warn: <WarningIcon className="text-[#BABABA]" />
  };

  return (
    <div className="flex items-center gap-[4px]">
      {levelIcons[level.toLowerCase()]}
      {t(`states.${level.toLowerCase()}`, { titleize: true })}
    </div>
  );
};

const Content = ({
  logs,
  displayChargerIdentification
}: {
  logs: EventLogRecord[];
  displayChargerIdentification?: boolean;
}) => {
  return (
    <>
      {logs.map(
        (
          {
            timestamp,
            source,
            code,
            level,
            message,
            chargerSerialNumber,
            instancePath
          },
          index
        ) => (
          <Grid.TableRow
            key={`log-${index}-${timestamp}`}
            data-testid="log-table-row"
            alternateRowColor={index % 2 !== 0}
          >
            <Grid.DataCell>
              {timestamp && formatDate(timestamp, dateTimeFormat)}
            </Grid.DataCell>
            <Grid.DataCell>
              {level && <LevelLabel level={level} />}
            </Grid.DataCell>
            <Grid.DataCell>{code}</Grid.DataCell>
            <Grid.DataCell>{source}</Grid.DataCell>
            {displayChargerIdentification && (
              <>
                <Grid.DataCell>{chargerSerialNumber}</Grid.DataCell>
                <Grid.DataCell>{instancePath}</Grid.DataCell>
              </>
            )}
            <Grid.DataCell>{message}</Grid.DataCell>
          </Grid.TableRow>
        )
      )}
    </>
  );
};
