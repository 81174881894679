import { useTranslation } from 'react-i18next';

import SadIcon from '@icons/sad_face_96.svg';

import { Typography } from '@destination/components';

export const PageNotFound = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'general' });
  return (
    <div className="mt-[198px] h-full w-full text-center">
      <div className="mb-8 flex justify-center" data-testid="sad-icon">
        <SadIcon />
      </div>
      <Typography variant="sectionTitle">{t('page_not_found')}</Typography>
    </div>
  );
};
