import { usePortalTranslation } from '@hooks/use-portal-translation';

import { HMICustomizationDrawer } from '@components/hmi/drawer/HMICustomizationDrawer';
import { HMICustomization } from '@components/hmi/HMICustomization';

export const ChargerCustomization = () => {
  const { t } = usePortalTranslation('hmi');

  const title1 = t('customizations.customer.title');
  const description1 = t('customizations.customer.description');
  const title2 = t('customizations.technical.title');
  const description2 = t('customizations.technical.description');
  const title3 = t('customizations.language.title');
  const description3 = t('customizations.language.description');

  return (
    <div className="flex flex-col gap-[60px]">
      <HMICustomization
        documentType="CustomerPreferences"
        title={title1}
        description={description1}
        uploadLink="customer/upload"
        seeAllLink="customer"
        data-testid="customer-settings"
      />
      <HMICustomization
        documentType="InstanceGraph"
        title={title2}
        description={description2}
        uploadLink="technical/upload"
        seeAllLink="technical"
        data-testid="technical-settings"
      />
      <HMICustomization
        documentType="PropertyOverrides"
        title={title3}
        description={description3}
        uploadLink="language/upload"
        seeAllLink="language"
        data-testid="language-settings"
      />
      <HMICustomizationDrawer closeDrawerLink="/hmi-settings/charger-customization" />
    </div>
  );
};
