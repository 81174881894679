import React from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate } from 'react-router-dom';

import useChargerActiveSessions from '@api/chargers/use-charger-active-session';
import { EvseActiveSessionDurationCell } from '@components/sites/site/insights/EvseActiveSessionDurationCell';
import ErrorIcon from '@icons/error_16.svg';
import LoadingIcon from '@icons/loading_16.svg';
import RefreshIcon from '@icons/refresh_16.svg';
import { formatDate, timeFormat } from '@utils/date-utils';

import {
  Grid,
  HighlightedText,
  IconButton,
  Typography
} from '@destination/components';

import {
  Charger,
  ChargerActiveSession,
  ConnectionStatusEnum,
  EvseStatus,
  EvseStatusEnum
} from '@omnis-pulse-types';

import { EvseStatusOverview } from '../evse/EvseStatusOverview';
import { ChargerStatusTableCell } from './ChargerStatusTableCell';
import { ConnectionStatusOverview } from './ConnectionStatusOverview';
import { SocketTypeCell } from './SocketTypeCell';

export interface IChargerOverviewRow {
  charger: Charger;
  highlighted: string;
}

const VerticalDivider = () => (
  <div className={`absolute -left-2 h-full w-[1px] bg-[#EBEBEB]`} />
);

const HorizontalDivider = ({ className }: { className: string }) => (
  <div
    data-testid="separator"
    className={`my-2 h-[1px] bg-[#EBEBEB] ${className}`}
  />
);

const DataCellContent = ({
  evseStatuses,
  render,
  sessions,
  isFirstCell = false,
  isLastCell = false,
  connectionStatus
}: {
  evseStatuses: EvseStatus[];
  render: (
    evse: EvseStatus,
    session: ChargerActiveSession | undefined
  ) => React.ReactNode;
  sessions?: ChargerActiveSession[];
  isFirstCell?: boolean;
  isLastCell?: boolean;
  connectionStatus: ConnectionStatusEnum;
}) => {
  if (connectionStatus === ConnectionStatusEnum.UNKNOWN) {
    return <Typography variant="smallText">--</Typography>;
  }

  return (
    <div className="relative flex flex-col">
      {evseStatuses.map((evse, index) => {
        const session = sessions?.find(session =>
          evse.sockets.some(socket => socket.id === session.connectorId)
        );
        return (
          <React.Fragment key={evse.id}>
            {index > 0 && (
              <HorizontalDivider
                className={`${!isFirstCell && '-ml-8'} ${isLastCell ? 'w-[calc(100% + 16px)]' : 'w-[calc(100% + 32px)]'}`}
              />
            )}
            {render(evse, session)}
          </React.Fragment>
        );
      })}
      {isFirstCell && <VerticalDivider />}
    </div>
  );
};

export const ChargerOverviewRow = ({
  charger,
  highlighted
}: IChargerOverviewRow) => {
  const navigate = useNavigate();
  const { isError, isLoading, sessions, mutate } = useChargerActiveSessions(
    charger.id
  );

  const { t_generic, t } = usePortalTranslation('chargers');

  const { connectionStatus, evseStatuses } = charger;

  const handleNavigate = (chargerId: string) => {
    navigate(`devices/chargers/${chargerId}`);
  };

  const hasPossibleActiveSession = (evse: EvseStatus) =>
    (evse.status === EvseStatusEnum.CHARGING ||
      evse.status === EvseStatusEnum.SUSPENDED) &&
    !isLoading &&
    !isError;

  return (
    <Grid.TableRow
      data-testid={charger.name}
      variant="card"
      onClick={() => handleNavigate(charger.id)}
      className="cursor-pointer"
    >
      <ChargerStatusTableCell
        connectionStatus={connectionStatus}
        evseStatuses={evseStatuses}
      />
      <Grid.DataCell className="content-center break-all">
        <div className="mb-1 truncate font-bold">
          <HighlightedText text={charger.name} highlighted={highlighted} />
        </div>
        <div>
          <HighlightedText
            text={charger.serialNumber}
            highlighted={highlighted}
          />
        </div>
      </Grid.DataCell>
      <Grid.DataCell className="content-center">
        <ConnectionStatusOverview connectionStatus={connectionStatus} />
      </Grid.DataCell>
      <Grid.DataCell className="content-center">
        <DataCellContent
          connectionStatus={connectionStatus.status}
          evseStatuses={evseStatuses}
          render={evseStatus => <EvseStatusOverview status={evseStatus} />}
          isFirstCell
        />
      </Grid.DataCell>
      <Grid.DataCell className="content-center">
        <DataCellContent
          connectionStatus={connectionStatus.status}
          evseStatuses={evseStatuses}
          render={evse => <SocketTypeCell sockets={evse.sockets} />}
        />
      </Grid.DataCell>
      <Grid.DataCell className="grid content-center">
        {isError ? (
          <div className="z-10 flex items-center gap-1 pl-12">
            <ErrorIcon data-testid="error-icon" className="text-[#BABABA]" />
            <Typography variant="smallText" className="w-[260px]">
              {t_generic('labels.entity_could_not_load', {
                entity: t_generic('literals.data'),
                titleize: true
              })}
            </Typography>
            <IconButton
              data-testid="refresh-icon-button"
              onClick={event => {
                event.stopPropagation();
                mutate(undefined, true);
              }}
            >
              <RefreshIcon />
            </IconButton>
          </div>
        ) : (
          <DataCellContent
            connectionStatus={connectionStatus.status}
            evseStatuses={evseStatuses}
            sessions={sessions}
            render={(evse, session) =>
              hasPossibleActiveSession(evse) && session ? (
                <Typography>{`${Math.round(session.power / 1000)} kW`}</Typography>
              ) : isLoading ? (
                <LoadingIcon
                  data-testid="loading-icon"
                  className="animate-spin opacity-25"
                />
              ) : (
                <Typography className="truncate">
                  {t('not_charging', { titleize: true })}
                </Typography>
              )
            }
          />
        )}
      </Grid.DataCell>
      <Grid.DataCell className="content-center">
        {!isError && (
          <DataCellContent
            connectionStatus={connectionStatus.status}
            evseStatuses={evseStatuses}
            sessions={sessions}
            render={(evse, session) =>
              hasPossibleActiveSession(evse) &&
              session?.soc !== undefined &&
              session?.soc !== null ? (
                <Typography variant="smallText">{`${session.soc}%`}</Typography>
              ) : (
                <div className="min-h-4" />
              )
            }
          />
        )}
      </Grid.DataCell>
      <Grid.DataCell className="content-center">
        {!isError && (
          <DataCellContent
            connectionStatus={connectionStatus.status}
            evseStatuses={evseStatuses}
            sessions={sessions}
            render={(evse, session) =>
              hasPossibleActiveSession(evse) && session ? (
                <Typography variant="smallText">
                  {formatDate(session.startTime, timeFormat)}
                </Typography>
              ) : (
                <div className="min-h-4" />
              )
            }
          />
        )}
      </Grid.DataCell>

      <Grid.DataCell className="content-center">
        {!isError && (
          <DataCellContent
            connectionStatus={connectionStatus.status}
            evseStatuses={evseStatuses}
            sessions={sessions}
            isLastCell
            render={(evse, session) =>
              hasPossibleActiveSession(evse) && session ? (
                <EvseActiveSessionDurationCell
                  charger={charger}
                  evse={evse}
                  session={session}
                />
              ) : (
                <div className="min-h-4" />
              )
            }
          />
        )}
      </Grid.DataCell>
      <Grid.DataCell className="content-center">
        <div className="relative min-h-full">
          <VerticalDivider />
        </div>
      </Grid.DataCell>
    </Grid.TableRow>
  );
};
