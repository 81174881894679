import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import useUserOrganizations from '@api/user-account/use-user-organizations';
import DropdownIcon from '@icons/dropdown_24.svg';

import {
  LoadingSkeleton,
  Typography,
  useClickOutside
} from '@destination/components';

import { UserOrganizationDetails } from '@omnis-pulse-types';

import { OrganizationRow } from './OrganizationRow';

export const OrganizationSelect = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'general.organization_select'
  });
  const { organizations, isLoading, isError } = useUserOrganizations();
  const [isExpanded, setIsExpanded] = useState(false);

  let sortedOrganizations: UserOrganizationDetails[] = [];

  if (Array.isArray(organizations)) {
    sortedOrganizations = organizations.toSorted((a, b) => {
      if (a.isActive === b.isActive) {
        return 0;
      }
      return a.isActive ? -1 : 1;
    });
  }

  const organizationSelectRef = useRef<HTMLDivElement>(null);
  useClickOutside(organizationSelectRef, () => {
    setIsExpanded(false);
  });

  const toggleExpanded = () => {
    setIsExpanded(previousExpanded => !previousExpanded);
  };

  if (isError) {
    return (
      <Typography variant="smallText" className="mt-auto">
        {t('could_not_load_organizations')}
      </Typography>
    );
  }

  if (isLoading)
    return <LoadingSkeleton height={16} className="w-[120px] !bg-[#E6E6E6]" />;

  return (
    <>
      {Array.isArray(organizations) && (
        <div ref={organizationSelectRef}>
          <div className="flex w-full items-center justify-end text-sm">
            {sortedOrganizations.find(org => org.isActive)?.name}
            <EndInputAdornment
              isExpanded={isExpanded}
              onClick={toggleExpanded}
            />
          </div>
          {isExpanded && (
            <div className="top-offset-[2px] absolute right-[140px] top-12 z-10 flex max-h-[250px] w-[342px] flex-col rounded-[4px] bg-white !py-[7px] px-2 shadow-smallTile">
              {// sort organizations so that the active organization is displayed first
              sortedOrganizations?.map((org, index) => (
                <OrganizationRow
                  key={org.organizationOmnisId}
                  organization={org}
                  isLast={index === organizations.length - 1}
                  handleClose={() => setIsExpanded(false)}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const EndInputAdornment = ({
  isExpanded,
  onClick
}: {
  isExpanded: boolean;
  onClick: () => void;
}) => {
  let classes =
    'ml-[6px] hover:bg-[#00000014] rounded-[50%] flex items-center justify-center p-0 ';

  if (isExpanded) {
    classes += 'cursor-pointer';
  } else {
    classes += 'rotate-180 cursor-pointer';
  }

  return (
    <button
      data-testid="dropdown-button"
      className={classes}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <DropdownIcon width={16} height={16} />
    </button>
  );
};
