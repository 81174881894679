import useSWR from 'swr';

import { DEVICES_BY_SITE_ID_PATH } from '@api/paths';

import { Devices } from '@omnis-pulse-types';

export default function useSiteDevices(siteId?: string) {
  const { data, error, isLoading, mutate } = useSWR<Devices>(
    siteId ? DEVICES_BY_SITE_ID_PATH(siteId) : null
  );

  return { devices: data, isLoading, isError: Boolean(error), mutate };
}
